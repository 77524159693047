import { useState } from 'react';
import {
  Box, Grid, Pagination, Typography,
} from '@mui/material';
import Table from '../../../../../components/Table';
import { userFormConfig } from './config';
import Form from '../../../../../components/Form';
import Modal from '../../../../../components/Modal';
import Layout from '../../../../../components/CardLayout';
import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/Inputs/TextInput';
import useMembersListApi from './api';
import AppLoader from '../../../../../components/AppLoader';
import AddBonusModal from './components/AddBonusModal';
import RemoveBonusModal from './components/RemoveBonusModal';
import CustomAutocomplete from '../../../../../components/Inputs/CustomAutocomplete';
import Select from '../../../../../components/Inputs/Select';
import { teamVolumes } from '../../../../../components/GenealogyTeamCards/config';

export default function List() {
  const {
    columns,
    rows,
    searchClick,
    setSearchText,
    searchText,
    paginationData,
    getUsers,
    loading,
    sponsorFilter,
    setSponsorFilter,
    ranksFilter,
    setRanksFilter,
    setSortModel,
    sortModel,
  } = useMembersListApi();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  return (
    <>
      {loading && <AppLoader overpage />}
      <Layout className="members-list" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
        <Grid container spacing={4} padding={0}>
          <Grid item xl={4.2} lg={5} md={5} sm={12} xs={12}>
            <TextInput
              className="search-input"
              size="xsm"
              placeholder="What would you like to search for?"
              value={searchText}
              onChange={(e) => {
                setSearchText(e);
              }}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <Button onClick={searchClick} className="py-auto my-auto mr-5" bordered size="sm">
                  <span className="no-wrap">Search</span>
                </Button>
              )}
            />
          </Grid>

          <Grid
            item
            xl={4.8}
            lg={4.8}
            md={4.8}
            sx={{
              display: 'flex', alignItems: 'center', width: '100%', gap: 2,
            }}
          >
            <Box sx={{ width: '35%' }}>
              <CustomAutocomplete
                endPoint="/users"
                name="users"
                mapper={(item) => ({ id: item.SID, label: item.Username })}
                value={sponsorFilter}
                onChange={(v) => setSponsorFilter(v)}
                disabled={false}
                error={false}
                size="sm"
                placeholder="Sponsor"
              />
            </Box>

            <Box sx={{ width: '35%' }}>
              <Select
                name="ranks"
                value={ranksFilter}
                onChange={(v) => setRanksFilter(v)}
                disabled={false}
                error={false}
                size="sm"
                options={[{ label: 'All Ranks', value: 'all' }, ...Object.keys(teamVolumes).map((i) => ({ label: i, value: i }))]}
              />
            </Box>

          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Table
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel);
              }}
              sortingOrder={['desc', 'asc']}
              sortingMode="server"
              disableColumnFilter={false}
              columns={columns}
              rows={rows}
              className="table"
              hideFooterPagination
              hideFooter
            />
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalUsers} users`}
                </Typography>
                <Pagination
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    getUsers(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>

    </>
  );
}
