import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import Modal from '../../../../components/Modal';
import { AdminTaxContext } from '../context';
import Button from '../../../../components/Button';

const TaxFormCompleteAllUsersModal = () => {
  const {
    t,
    openCompleteAllUsersModal,
    handleCompleteAllUsersModalSubmit,
    handleCompleteAllUsersModalClose,
  } = useContext(AdminTaxContext);

  return (
    <Modal
      title="Complete All Forms"
      open={openCompleteAllUsersModal}
      handleClose={handleCompleteAllUsersModalClose}
      width="500px"
    >
      {openCompleteAllUsersModal && (
        <>
          <Typography
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
          >
            <span>{t('Mark all forms as completed?')}</span>
          </Typography>

          <Typography
            fontSize="16px"
            textAlign="center"
            margin="24px 0"
          >
            <span className="primary-text-gradient">
              {t('They are going to be processed next Friday.')}
            </span>
          </Typography>

          <Button onClick={handleCompleteAllUsersModalSubmit}>
            {t('Submit')}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default TaxFormCompleteAllUsersModal;
