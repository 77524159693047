import { Grid, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AppLoader from '../../../../../../components/AppLoader';
import Price from '../../../../../../components/Price';
import DatePicker from '../../../../../../components/Inputs/DatePicker';
import Button from '../../../../../../components/Button';
import { useMyCoinPicks } from '../../../context/hooks';

export default function GridView() {
  const [curretDate, setCurrentDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { userId } = useParams();
  const myCoinPicks = useMyCoinPicks({
    page: currentPage,
    startDate: curretDate,
    userId,
  });

  const handleClear = () => {
    setCurrentDate(null);
  };

  return (
    <>
      {myCoinPicks.isFetching && <AppLoader overpage />}

      <Grid className="my-coin-picks-list-view" container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            marginBottom: '1.5rem',
            flexDirection: 'column',
          }}
          className="wallet-transfer-filters"
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            sx={{ marginBottom: { xs: '10px', sm: 0 } }}
          >
            Filter by Date:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              size="sm"
              sx={{ minWidth: '200px', flex: 1 }}
              value={curretDate}
              currentTimeZone="UTC"
              onChange={(v) => {
                setCurrentDate(dayjs(v).toISOString());
              }}
            />
            {curretDate && (
            <Button variant="outlined" onClick={handleClear} size="sm">
              Clear Date
            </Button>
            )}
          </div>
        </Grid>
        <Grid container spacing={4} padding={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {myCoinPicks.data && myCoinPicks.data.data.map((row) => (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={row.id}
                className="my-coin-picks-list-item"
              >
                <Grid item xs={2} className="coin-image">
                  <img src={row?.image} alt={row?.choosenCoin} />
                </Grid>

                <Grid item xs={5} className="list-items">
                  <Grid item className="game-price">
                    <Typography>
                      {`${row?.price} MG Coins Game`}
                    </Typography>
                  </Grid>
                  <Grid item className="coin-name">
                    <Typography>{row?.choosenCoin}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={5} className="list-items-right">
                  <Grid item className="coin-price">
                    <Typography>
                      <span style={{ fontSize: '11px', color: '#fff' }}>{'Entry: '}</span>
                      <Price value={row?.entryPrice} dollarSign />
                    </Typography>
                  </Grid>
                  <Grid item className="coin-pick-time">
                    <Typography>
                      {`${dayjs(row?.lastVotedAt).utc().format('MMM DD, YYYY HH:mm')} GMT`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {myCoinPicks.data && (
            <div className="flex justify-end mt-4">
              <Typography sx={{ mt: 0.5 }}>
                {`Total of: ${myCoinPicks.data.total} votes`}
              </Typography>
              <Pagination
                size="small"
                siblingCount={0}
                count={myCoinPicks.data.total}
                page={currentPage}
                onChange={(val, page) => {
                  setCurrentPage(page);
                }}
              />
            </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
