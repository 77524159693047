import dayjs from 'dayjs';

export const USA_FEDERAL_HOLIDAYS = [
  { date: '2025-01-01', description: "New Year's Day" },
  { date: '2025-01-20', description: 'Martin Luther King Jr. Day' },
  { date: '2025-02-17', description: "Presidents' Day" },
  { date: '2025-05-26', description: 'Memorial Day' },
  { date: '2025-06-19', description: 'Juneteenth National Independence Day' },
  { date: '2025-07-04', description: 'Independence Day' },
  { date: '2025-09-01', description: 'Labor Day' },
  { date: '2025-10-13', description: 'Columbus Day' },
  { date: '2025-11-11', description: 'Veterans Day' },
  { date: '2025-11-27', description: 'Thanksgiving Day' },
  { date: '2025-12-25', description: 'Christmas Day' },
];

// 0 is Sunday and 6 is Saturday
const isBusinessDayIndex = (weekDay) => weekDay > 0 && weekDay < 6;

export const dateAfterBusinessDays = (days, formatted = true) => {
  let date = dayjs().tz('GMT');
  let businessDays = days;

  // The initial date must always be a business day
  // If the date is Sunday, move 1 day
  if (date.day() === 0) {
    date = date.add(1, 'day');
  }

  // If the date is Saturday, move 2 days
  if (date.day() === 6) {
    date = date.add(2, 'day');
  }

  while (businessDays > 0) {
    date = date.add(1, 'day');

    if (isBusinessDayIndex(date.day())) {
      businessDays -= 1;
    }
  }

  if (formatted) return date.format('MMM DD, YYYY');

  return date.toDate();
};

// Check if the date is a Business Day
export const dateIsBusinessDay = (date = dayjs().tz('GMT').toDate()) => isBusinessDayIndex(dayjs(date).tz('GMT').day());

export const getNextWeekFriday = (startDate = dayjs().tz('GMT').toDate(), formatted = true) => {
  let date = dayjs(startDate).tz('GMT');

  // Get the days to the next Sunday (0)
  const currentDayIndex = date.day();
  const daysToNextMonday = 7 - currentDayIndex;

  // Add the days to the next Sunday + (5) to the next Friday
  date = date.add(daysToNextMonday + 5, 'day');

  if (formatted) return date.format('MMM DD, YYYY');

  return date.toDate();
};

export const isValidTime = ({ days = [0, 1, 2, 3, 4, 5, 6], startHour = 10, endHour = 16 }) => {
  const now = dayjs().tz('EST');
  const day = now.day();
  const hour = now.hour();

  // Check if the current day is in the specified days array
  if (!days.includes(day)) return false;

  // Check if the current time is within the specified hours
  if (hour >= startHour && hour <= endHour) { return true; }
  return false;
};

export const dateIsHoliday = (today = dayjs().tz('GMT').format('YYYY-MM-DD')) => {
  const holiday = USA_FEDERAL_HOLIDAYS.find((day) => day.date === today);

  return holiday ? holiday.description : false;
};
