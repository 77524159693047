import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { completedTaxFormsColumnsConfig, defaultTaxFormsColumnsConfig, processingTaxFormsColumnsConfig } from '../config';

const useAdminTaxApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const {
    authGet, authPut, authDelete, authPost,
  } = useApi();

  const [taxFormsData, setTaxFormsData] = useState([]);
  const [taxFormsProcessingData, setTaxFormsProcessingData] = useState([]);
  const [taxFormsCompletedData, setTaxFormsCompletedData] = useState([]);
  const [taxFormsDataLoaded, setTaxFormsDataLoaded] = useState(false);
  const [taxFormReviewData, setTaxFormReviewData] = useState(null);
  const [taxFormDeleteData, setTaxFormDeleteData] = useState(null);
  const [canWithdrawCount, setCanWithdrawCount] = useState(0);

  const [openCompleteAllUsersModal, setOpenCompleteAllUsersModal] = useState(false);

  const fetchAllTaxFormsData = useCallback(
    async () => {
      try {
        setTaxFormsDataLoaded(false);

        const response = await authGet('/tax-forms');

        setTaxFormsData(response.data.pending || []);
        setTaxFormsProcessingData(response.data.processing || []);
        setTaxFormsCompletedData(response.data.completed || []);
        setCanWithdrawCount(response.data.canWithdrawCount || 0);
      } catch (err) {
        console.log(err);
        toast.error(t('Failed to fetch tax forms data'));
      } finally {
        setTaxFormsDataLoaded(true);
      }
    },
    [authGet],
  );

  const handleReviewModalSubmit = useCallback(
    async (taxFormId) => {
      try {
        await authPut(`/tax-forms/${taxFormId}/review`);
        fetchAllTaxFormsData();
        toast.success(t('Tax form reviewed successfully'));
      } catch (err) {
        console.log(err);
        toast.error(t('Failed to review tax form'));
      } finally {
        setTaxFormReviewData(null);
      }
    },
    [authPut, setTaxFormReviewData],
  );

  const handleReviewModalClose = useCallback(
    async () => setTaxFormReviewData(null),
    [setTaxFormReviewData],
  );

  const handleDeleteModalSubmit = useCallback(
    async (taxFormId) => {
      try {
        await authDelete(`/tax-forms/${taxFormId}/delete`);
        fetchAllTaxFormsData();
        toast.success(t('Tax form deleted successfully'));
      } catch (err) {
        console.log(err);
        toast.error(t('Failed to delete tax form'));
      } finally {
        setTaxFormDeleteData(null);
      }
    },
    [authDelete, setTaxFormDeleteData],
  );

  const handleDeleteModalClose = useCallback(
    async () => setTaxFormDeleteData(null),
    [setTaxFormDeleteData],
  );

  const taxFormsColumnsConfig = useMemo(
    () => defaultTaxFormsColumnsConfig(t, setTaxFormReviewData, setTaxFormDeleteData, taxFormsData.length),
    [t, setTaxFormReviewData, setTaxFormDeleteData, taxFormsData],
  );

  const processingTaxFormsColumnsConfiguration = useMemo(
    () => processingTaxFormsColumnsConfig(t, taxFormsProcessingData.length),
    [t, taxFormsProcessingData],
  );

  const completedTaxFormsColumnsConfiguration = useMemo(
    () => completedTaxFormsColumnsConfig(t, taxFormsCompletedData.length),
    [t, taxFormsCompletedData],
  );

  const handleCompleteAllUsersModalOpen = useCallback(() => setOpenCompleteAllUsersModal(true), [setOpenCompleteAllUsersModal]);
  const handleCompleteAllUsersModalClose = useCallback(() => setOpenCompleteAllUsersModal(false), [setOpenCompleteAllUsersModal]);

  const handleCompleteAllUsersModalSubmit = useCallback(
    async () => {
      try {
        await authPost('/tax-forms/review');
        fetchAllTaxFormsData();
        toast.success('All users completed. They are going to be processed next friday');
      } catch (err) {
        toast.error(t('Error during process. Please try again later.'));
      } finally {
        setOpenCompleteAllUsersModal(false);
      }
    },
    [authPost, setOpenCompleteAllUsersModal],
  );

  useEffect(() => {
    fetchAllTaxFormsData();
  }, []);

  return {
    t,
    user,

    taxFormsData,
    taxFormsProcessingData,
    taxFormsCompletedData,
    taxFormsDataLoaded,
    taxFormsColumnsConfig,
    processingTaxFormsColumnsConfiguration,
    completedTaxFormsColumnsConfiguration,
    canWithdrawCount,
    
    taxFormReviewData,
    handleReviewModalSubmit,
    handleReviewModalClose,
    
    taxFormDeleteData,
    handleDeleteModalSubmit,
    handleDeleteModalClose,

    openCompleteAllUsersModal,
    handleCompleteAllUsersModalOpen,
    handleCompleteAllUsersModalSubmit,
    handleCompleteAllUsersModalClose,
  };
};

export default useAdminTaxApi;
