import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Welcome from './components/Welcome';
import Layout from '../../components/CardLayout';
import AccountInfo from './components/AccountInfo';
import ChangePassword from './components/ChangePassword';
import useProfile from './components/AccountInfo/hook';
import ItemData from '../../components/ItemData';
import { formatPhoneNumber } from '../../utils/string';
import OptOutChallenge from './components/OptOutChallenge';

export default function Profile() {
  const { t } = useTranslation();
  const {
    userData,
  } = useProfile();
  const [sponsor, setSponsor] = useState();

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchSponsor = async () => {
      if (userData) {
        if (isMounted) {
          setSponsor(userData.sponsor);
        }
      }
    };

    fetchSponsor();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [userData]);

  return (
    <Grid container className="profile">
      <div className="col left-col">
        <Layout size="sm" maxWidth="xl" className="centered-card">
          <Welcome />
        </Layout>

        <div>
          <Layout size="sm" maxWidth="xl">
            <ChangePassword />
          </Layout>
        </div>
      </div>

      <div className="col left-col">
        {sponsor && (
          <Layout size="sm" maxWidth="xl">
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '26px',
                fontWeight: 600,
                mb: 2,
                width: '100%',
              }}
            >
              {t('Sponsor')}
            </Typography>
            {sponsor.Name || sponsor?.Surname ? (
              <ItemData label="Full name" value={`${sponsor?.Name} ${sponsor?.Surname}`} />
            ) : null}

            {sponsor.Mobile ? (
              <ItemData label="Phone" value={formatPhoneNumber(sponsor.Mobile)} />
            ) : null}

            {sponsor.Email ? (
              <ItemData label="Email" value={sponsor.Email} />
            ) : null}

          </Layout>
        )}

        <Layout size="sm" maxWidth="xl">
          <AccountInfo />
        </Layout>

        <Layout size="sm" maxWidth="xl">
          <OptOutChallenge />
        </Layout>
      </div>
    </Grid>
  );
}
