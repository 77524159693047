import { useContext, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { PendingWithdrawalsContext } from '../context';
import { renderNumber } from '../../../../utils/string';
import TextInput from '../../../../components/Inputs/TextInput';

const CancelWithdrawModal = () => {
  const [notification, setNotification] = useState('');
  const { t } = useTranslation();
  const {
    submittingCancelWithdrawModal,
    withdrawCancelDataModal,
    handleCancelWithdrawSubmitModal,
    handleCancelWithdrawCloseModal,
  } = useContext(PendingWithdrawalsContext);

  useEffect(() => {
    setNotification('');
  }, [withdrawCancelDataModal]);

  return (
    <Modal
      title="Mark as Canceled"
      width="650px"
      open={!!withdrawCancelDataModal}
      handleClose={handleCancelWithdrawCloseModal}
    >
      <h2 style={{
        marginTop: '-20px', textAlign: 'center', paddingBottom: '10px', paddingTop: 0, fontWeight: 'bold',
      }}
      >
        <span className="primary-text-gradient" style={{ fontSize: '18px', fontWeight: 'bold' }}>{withdrawCancelDataModal?.user}</span>
      </h2>
      <div style={{ overflowX: 'auto', marginBottom: '20px' }}> 
        <table className="single_table borderless">
          <tbody>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Amount : </th>
              <td>
                $
                {renderNumber(withdrawCancelDataModal?.amount)}
              </td>
            </tr>
            {withdrawCancelDataModal?.destination !== '' && (
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Wallet : </th>
                <td>
                  {withdrawCancelDataModal?.destination}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TextInput
        type="textarea"
        value={notification}
        onChange={(e) => {
          setNotification(e);
        }}
        placeholder="Notification"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingCancelWithdrawModal && !!notification) {
            handleCancelWithdrawSubmitModal(notification);
          }
        }}
        className="mb-5"
        disabled={submittingCancelWithdrawModal || !notification}
      >
        <span className="no-wrap">Cancel Withdrawal</span>
      </Button>
    </Modal>
  );
};

export default CancelWithdrawModal;
