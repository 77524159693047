import { useContext } from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import AddIcon from '@mui/icons-material/Add';
import { Context } from '../context';
import Table from '../../../components/Table';
import ResultsBox from '../../AdminReports/components/ResultsBox';

const EarningsHistory = () => {
  const {
    walletEarningsData,
    walletEarningsLoaded,
    walletEarningsColumnsConfiguration,
    walletYesterdayEarningsData,
    walletCurrentMonthEarnings,
    walletLastEarnings,
  } = useContext(Context);

  return (
    <div className="flex" style={{ flexDirection: 'column' }}>
      <div className="wallet-earnings-results">
        <div className="result">
          <ResultsBox
            title="Yesterday"
            result={walletYesterdayEarningsData}
            money
          />
          <AddIcon className="icon" />
        </div>
        <div className="result">
          <ResultsBox
            title="Current Month"
            result={walletCurrentMonthEarnings}
            money
          />
          <AddIcon className="icon" />
        </div>
        <div className="result">
          <ResultsBox
            title="Previous"
            result={walletLastEarnings}
            money
          />
          <PauseIcon className="icon icon--equal" />
        </div>
        <div className="result">
          <ResultsBox
            title="Total Bonuses"
            result={walletYesterdayEarningsData + walletCurrentMonthEarnings + walletLastEarnings}
            money
          />
        </div>
      </div>
      {walletEarningsData.length > 0 && (
        <Table
          loading={!walletEarningsLoaded}
          columns={walletEarningsColumnsConfiguration}
          rows={walletEarningsData}
          getRowId={(row) => `${row.year}-${row.month}`}
          className="table mb-4"
          hideFooterPagination={false}
        />
      )}
    </div>
  );
};

export default EarningsHistory;
