/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { HelioCheckout } from '@heliofi/checkout-react';
import PassTypes from '../../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../../components/Modal';
import useApi from '../../../../../../utils/api';
import NumberInput from '../../../../../../components/Inputs/NumberInput';
import useSolanaPay from '../../../../../../utils/hooks/useSolanaPay';
import ApplyPassToggle from '../../../../../../components/Inputs/ApplyPassToggle';
// import StripePay from '../../../../../Authentication/PayNow/components/StripePay';
// import { useAuthentication } from '../../../../../../utils/store';

export default function PurchasePassTypeModal({
  data,
  selectedPassType,
  setPurchasePassesData,
  fetchPassTypes,
  fetchPasses,
}) {
  const { passType } = data;
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const { authPost, authDelete } = useApi();
  const [amount, setAmount] = useState(1);
  const [error, setError] = useState();
  const formRef = useRef(null);
  const [theData, setTheData] = useState(data);
  const [processingApply, setProcessingApply] = useState(false);
  const helioCCEnabled = process.env.REACT_APP_SOLANA_PAY_CREDIT_CARD === 'true';

  const handleBuyModalClose = async (canceled = true) => {
    const _solana = sessionStorage.getItem('_solana_pay_transaction_id');
    if (canceled && !_solana) {
      setLoading(true);
      const _puid = sessionStorage.getItem('_puid');
      if (_puid) {
        try {
          await authDelete(`/payments/passes/remove/${_puid}`);
        } catch (err) {
          toast.error(t(err));
        }
        sessionStorage.removeItem('_puid');
      }
      setLoading(false);
      setPurchasePassesData();
    } else {
      fetchPassTypes();
      fetchPasses();
      setPurchasePassesData();
      sessionStorage.removeItem('_solana_pay_transaction_id');
    }
  };

  const [solanaConfig, setSolanaConfig] = useState(
    useSolanaPay({ data, handleModalClose: handleBuyModalClose }),
  );

  const [payWithCardConfig, setPayWithCardConfig] = useState(
    useSolanaPay({ data, handleModalClose: handleBuyModalClose, payWithCard: true }),
  );

  const onBuy = async () => {
    if (!amount) {
      setError('The amount of packs should not be empty or 0');
    } else {
      setLoading(true);
      if (data.priceTotal === 0) {
        try {
          const res = await authPost('/payments/passes/free', {
            data: { ...data, units: amount },
          });
          if (res) {
            fetchPassTypes();
            fetchPasses();
          }
          toast.success(t('You have succefully activated your membership!'));
          setLoading(false);
          handleBuyModalClose();
        } catch (err) {
          toast.error(t(err));
        }
      } else {
        const form = formRef.current;
        form.submit();
      }
    }
  };

  const onPurchase = async () => {
    onBuy(); // crypto payment
  };

  useEffect(() => {
    const newTotal = theData.price * amount;
    setTheData((currentData) => ({
      ...currentData,
      units: amount,
      priceTotal: newTotal,
    }));

    setSolanaConfig((currentConfig) => ({
      ...currentConfig,
      amount: newTotal.toString(),
    }));

    setPayWithCardConfig((currentConfig) => ({
      ...currentConfig,
      amount: newTotal.toString(),
    }));
  }, [amount]);

  useEffect(() => {
    setLoading(processingApply);
  }, [processingApply]);

  const btcIsEnabled = process.env.REACT_APP_BTC_ENABLED === 'true';

  return (
    <Modal
      title={t('Purchase Coin Pack')}
      width="550px"
      open={!!data}
      handleClose={handleBuyModalClose}
    >
      {/* {
        step === 1 ? ( */}
      <Grid container spacing={3}>
        <PassTypes
          packages={[passType]}
          loading={loading}
          formDescription="You don't have enough coin packs available to transfer. Do you want to buy some?"
          setSelectedPackage={() => {}}
          selectedPackage={selectedPassType}
        />
        <Grid container item>
          <NumberInput
            size="sm"
            style={{ minWidth: '100%' }}
            className="full-width"
            name="amountOfPasses"
            value={String(amount) || ''}
            placeholder="Enter the amount of passes"
            onBlur={() => {}}
            onFocus={() => {}}
            onChange={(v) => {
              const val = v > 0 ? v : 1;
              setError();
              setAmount(val);
            }}
            error={error}
          />
        </Grid>

        <Grid item style={{ paddingTop: 16 }}>
          <ApplyPassToggle
            setIsProcessing={setProcessingApply}
            paymentId={theData.id}
          />
        </Grid>

        {btcIsEnabled && (
          <Grid container item>
            <form
              method="POST"
              onSubmit={() => console.log('submitted')}
              ref={formRef}
              action={process.env.REACT_APP_BTC_PAY}
            >
              <input type="hidden" name="orderId" value={theData.PUID} />
              <input
                type="hidden"
                name="storeId"
                value={process.env.REACT_APP_BTC_STORE_ID}
              />
              <input
                type="hidden"
                name="checkoutDesc"
                value={theData.Description}
              />
              <input
                type="hidden"
                name="serverIpn"
                value={theData?.serverIpn}
              />
              <input
                type="hidden"
                name="browserRedirect"
                value={theData?.redirect}
              />
              <input type="hidden" name="price" value={theData?.priceTotal} />
              <input type="hidden" name="currency" value="USD" />
            </form>
          </Grid>
        )}

        {(btcIsEnabled || theData?.price === 0) && (
          <BuyButton
            title="Buy Now"
            disabled={loading}
            type="submit"
            onSubmitBitcoin={onPurchase}
          />
        )}
      </Grid>

      {solanaConfig.helioIsEnabled && theData?.priceTotal > 0 && (
        <>
          <div className="solana-button">
            <HelioCheckout config={solanaConfig.helioConfig} />
          </div>
          {helioCCEnabled && (
            <div className="solana-button">
              <HelioCheckout config={payWithCardConfig.helioConfig} />
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
