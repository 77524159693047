export const simulatorRulesFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    placeholder: 'Title',
    required: true,
  },
  {
    name: 'content',
    type: 'editor',
    formGroupType: 'editor-group',
    label: 'Content',
    placeholder: 'Body',
    required: true,
    rows: 30,
  },
];
