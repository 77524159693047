/* eslint-disable react/no-danger */
import { useContext, useState } from 'react';
import Button from '../../../../components/Button';
import { GlobalNotifyContext } from '../context';
import Modal from '../../../../components/Modal';
import { notifyFormConfig } from '../config';
import Form from '../../../../components/Form';

const AddEditGlobalNotifyModal = () => {
  const {
    t,
    singleNotifyData,
    formSubmitting,
    formErrors,
    setFormErrors,
    handleFormSubmitModal,
    handleOpenModalToAdd,
    handleCloseModal,
  } = useContext(GlobalNotifyContext);
  const [dangerHtml, setDangerHtml] = useState(null);

  return (
    <>
      <Button size="sm" onClick={handleOpenModalToAdd}>
        <span>Add Message</span>
      </Button>
      {!!singleNotifyData && (
        <Modal
          title={t(`${singleNotifyData?.id?.length > 0 ? 'Edit' : 'Add'} Notify Message`)}
          width="100%"
          open={!!singleNotifyData}
          handleClose={handleCloseModal}
          modalStyle={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <Form
            size="sm"
            defaultFormValue={singleNotifyData}
            config={notifyFormConfig}
            onSubmit={handleFormSubmitModal}
            setCurrentData={({ message }) => setDangerHtml(message)}
            formLoading={formSubmitting}
            errors={formErrors}
            setErrors={setFormErrors}
            buttonType="button"
            buttonStyle={{ width: '100%', marginTop: 0 }}
            submitLabel={t('Save')}
          />

          {dangerHtml && (
            <div className="mt-4">
              <span dangerouslySetInnerHTML={{ __html: dangerHtml }} />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddEditGlobalNotifyModal;
