import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import PhoneInput from '../../../components/Inputs/PhoneInput';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column';
    }
    return '';
  },
};

export const webinarLeadsReportColumnsConfig = (t) => [
  {
    field: 'name',
    headerName: t('Name'),
    flex: 1,
    minWidth: 120,
    ...defaultColConfig,
  },
  {
    field: 'email',
    headerName: t('Email'),
    minWidth: 300,
    ...defaultColConfig,
  },
  {
    field: 'phone',
    headerName: t('Phone'),
    minWidth: 220,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <Typography variant="span" className="mobile-phone">
        <PhoneInput value={value} disabled />
      </Typography>
    ),
  },
  {
    field: 'createdAt',
    headerName: t('Date'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
];

export default {};
