import { useContext } from 'react';
import { AdminTransactionsContext } from '../../context';
import Form from '../../../../../components/Form';
import { gamerTransactionFormConfig, gamerTransactionFormDefaultValues } from '../../config';

const GamerTransactionForm = () => {
  const {
    t,
    handleGamerTransactionFormSubmit,
    gamerTransactionFormLoading,
    gamerTransactionFormErrors,
    setGamerTransactionFormErrors,
  } = useContext(AdminTransactionsContext);

  return (
    <Form
      size="sm"
      defaultFormValue={gamerTransactionFormDefaultValues}
      config={gamerTransactionFormConfig}
      onSubmit={handleGamerTransactionFormSubmit}
      errors={gamerTransactionFormErrors}
      onErrors={setGamerTransactionFormErrors}
      buttonType="button"
      buttonStyle={{ width: '100%', marginTop: 0 }}
      submitLabel={t('Submit')}
      formLoading={gamerTransactionFormLoading}
    />
  );
};

export default GamerTransactionForm;
