import {
  Grid, CircularProgress, Pagination,
} from '@mui/material';
import {
  useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table from '../../../../components/Table';
import Select from '../../../../components/Inputs/Select';

import {
  gamercolumnsConfig,
} from '../../../Wallets/config';
import { useHistory } from '../context/hooks';

export default function GamerHistoryContent() {
  const { t } = useTranslation();
  const [filterTransactionType, setFilterTransactionType] = useState('default');
  const [filterDirection, setFilterDirection] = useState('default');
  const [historyPageSize, setHistoryPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  
  const { userId } = useParams();
  
  const history = useHistory(true, {
    userId,
    perPage: historyPageSize,
    page: currentPage,
    type: filterTransactionType === 'default' ? null : filterTransactionType,
    direction: filterDirection === 'default' ? null : filterDirection,
  });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          marginBottom: '1.5rem',
        }}
        className="wallet-transfer-filters"
      >
        <div className="flex" style={{ gap: '1.5rem' }}>
          <Select
            className="transfers-filter-select"
            placeholder="Any type"
            size="sm"
            value={filterTransactionType}
            onChange={(e) => setFilterTransactionType(e)}
            options={[
              { value: 'Coins', label: 'MG Coins' },
              { value: 'Tokens', label: 'MG Tokens' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
          <Select
            className="transfers-filter-select"
            placeholder="Any direction"
            size="sm"
            value={filterDirection}
            onChange={(e) => setFilterDirection(e)}
            options={[
              { value: 'receive', label: 'All Credits' },
              { value: 'deducted', label: 'All Debits' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </div>
        <div className="flex">
          <Select
            className="transfers-filter-select"
            placeholder="Pagination"
            size="sm"
            value={`${historyPageSize}`}
            onChange={(e) => setHistoryPageSize(Number(e) > 0 ? Number(e) : 10)}
            options={[
              { value: '10', label: '10 per Page' },
              { value: '20', label: '20 per Page' },
              { value: '30', label: '30 per Page' },
              { value: '40', label: '40 per Page' },
              { value: '50', label: '50 per Page' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </div>
      </Grid>
      <Grid item container xs>
        {history.isFetching ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <Grid item container xs rowSpacing={2} className="wallet-transactions-history">
            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Table
                loading={history.isFetching}
                columns={gamercolumnsConfig(t)}
                rows={history.data.data.map((i) => ({
                  ...i, value: i?.token || i?.mgCoins || i?.balance || i?.coins || 0,
                }))}
                className="table mb-4"
              />
            </Grid>
            {Math.ceil(history.data.total / historyPageSize) > 1 ? (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                className="custom-pagination"
              >
                <Pagination
                  count={Math.ceil(history.data.total / historyPageSize)}
                  page={currentPage}
                  onChange={(val, page) => {
                    setCurrentPage(page);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>
 
    </Grid>
  );
}
