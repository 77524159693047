/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import clsx from 'clsx';
import {
  Grid, Switch, Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { Navigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import 'moment-timezone';
import Button from '../Button';
import Select from '../Inputs/Select';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DatePicker';
import AjaxSelect from '../Inputs/AjaxSelect';
import RadioGroup from '../Inputs/RadioGroup';
import AjaxRadioGroup from '../Inputs/AjaxRadioGroup';
import CustomCheckbox from '../Inputs/Checkbox';
import MoneyInput from '../Inputs/MoneyInput';
import NumberInput from '../Inputs/NumberInput';
import PhoneInput from '../Inputs/PhoneInput';
import PasswordInput from '../Inputs/Password';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import InfoBubbleModal from '../InfoBubbleModal';
import CreateLinkModal from '../CreateLinkModal';

export default function Form({
  config = [],
  size = 'md',
  className = '',
  onSubmit = () => { },
  defaultFormValue,
  submitLabel = 'Submit',
  submitClassName = '',
  setActions = () => { },
  errors,
  setErrors,
  formLoading = false,
  buttonType,
  buttonStyle,
  onChangeSelect = () => { },
  onClickSelectOption = () => { },
  setCurrentData = () => { },
  clearAfterSubmit = false,
}) {
  const { t } = useTranslation();
  const [focusedInputs, setFocusedInputs] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [imagePreviewList, setImagePreviewList] = useState([]);

  useEffect(() => {
    if (typeof setActions === 'function') {
      setActions({ setFormValue });
    }
  }, []);

  useEffect(() => {
    setCurrentData(formValue);
  }, [formValue, setCurrentData]);

  useEffect(() => {
    const tmp = { ...defaultFormValue };
    setFormValue(tmp || {});
  }, [defaultFormValue]);

  const onChangeFormItem = useCallback(
    async (val, key, item) => {
      // setFormValue((prev) => ({ ...prev, [key]: val }));
      const _val = item?.format ? item.format(val) : val;
      const newState = _.cloneDeep(formValue);
      const tmp = _.set(newState, key, _val);
      setFormValue(tmp);
      onChangeSelect(tmp);
      if (setErrors) {
        setErrors((prev) => ({ ...prev, [key]: null }));
      }
    },
    [formValue],
  );

  const onInputFocus = useCallback((key) => {
    setFocusedInputs((prev) => [...prev, key]);
  }, []);

  const onInputBlur = useCallback((key) => {
    setFocusedInputs((prev) => prev.filter((v) => v !== key));
  }, []);

  const generatePreviewImages = useCallback(async (fileList, name) => {
    Array.from(fileList).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const tmpParent = [...imagePreviewList] || [];
        const tmp = tmpParent[name] || [];
        tmp.push(e.target.result);
        tmpParent[name] = tmp;
        setImagePreviewList(tmpParent);
      };
      reader.readAsDataURL(file);
    });
  }, [imagePreviewList]);

  const renderPreviewImages = useCallback((fileList, name) => {
    generatePreviewImages(fileList, name);
    return imagePreviewList[name] ? (
      <div
        className="flex"
        style={{
          flexWrap: 'wrap', paddingTop: '10px', alignItems: 'center', justifyContent: 'flex-start',
        }}
      >
        {imagePreviewList[name].map((src, index) => (
          <img key={index} src={src} alt={`preview-${index}`} style={{ maxWidth: '100px', margin: '5px', borderRadius: '6px' }} />
        ))}
      </div>
    ) : null;
  }, [generatePreviewImages, imagePreviewList]);

  const renderInput = (item) => {
    switch (item.type) {
      case 'text':
      case 'email':
        return (
          <TextInput
            size={size}
            name={item.name}
            type={item.type}
            canCopy={item.canCopy}
            qrIcon={item.qrIcon}
            value={String(_.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name, item)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            autocomplete={item.autocomplete}
          />
        );
      case 'textarea':
        return (
          <TextInput
            size={size}
            name={item.name}
            type={item.type}
            rows={item.rows || 3}
            canCopy={item.canCopy}
            qrIcon={item.qrIcon}
            value={String(_.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name, item)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            autocomplete={item.autocomplete}
          />
        );
      case 'password':
        return (
          <PasswordInput
            size={size}
            name={item.name}
            type={item.type}
            value={String(_.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name, item)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'phone':
        return (
          <PhoneInput
            size={size}
            name={item.name}
            type={item.type}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => {
              onChangeFormItem(v, item.name);
            }}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'number':
        return (
          <NumberInput
            size={size}
            name={item.name}
            withButtons={item.withButtons}
            type={item.type}
            className={item.className}
            endAdornment={item.endAdornment}
            startAdornment={item.startAdornment}
            value={String(_.get(formValue, item.name) === 0 ? 0 : _.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            allowDecimals={item.allowDecimals}
          />
        );
      case 'money':
        return (
          <MoneyInput
            size={size}
            name={item.name}
            value={String(_.get(formValue, item.name)) || 0}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            allowDecimals={item.allowDecimals}
          />
        );
      case 'date':
        return (
          <DateInput
            size={size}
            value={_.get(formValue, item.name)}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            defaultValue={_.get(formValue, item.name) || moment().tz('GMT').startOf('day')}
          />
        );
      case 'ajaxSelect':
        return (
          <AjaxSelect
            endPoint={item.endPoint}
            name={item.name}
            mapper={item.mapper}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'autocomplete':
        return (
          <CustomAutocomplete
            endPoint={item.endPoint}
            name={item.name}
            mapper={item.mapper}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            qs={item.qs}
          />
        );
      case 'select':
        return (
          <Select
            name={item.name}
            options={item.options}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            onClickSelectOption={onClickSelectOption}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'ajaxRadioGroup':
        return (
          <AjaxRadioGroup
            endPoint={item.endPoint}
            name={item.name}
            mapper={item.mapper}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            setErrors={setErrors}
          />
        );
      case 'radioGroup':
        return (
          <RadioGroup
            name={item.name}
            options={item.options}
            template={item.template}
            value={_.get(formValue, item.name)}
            defaultValue={item?.defaultValue}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            setErrors={setErrors}
          />
        );
      case 'checkbox':
        return (
          <CustomCheckbox
            name={item.name}
            options={item.options}
            value={_.get(formValue, item.name) || false}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'switch': return (
        <FormControlLabel
          control={(
            <Switch
              name={item.name}
              disabled={item.disabled}
              checked={_.get(formValue, item.name) || false}
              defaultChecked={item.defaultValue}
              color="primary"
              onChange={(v) => onChangeFormItem(v.target.checked, item.name)}

            />
          )}
          label={item.description}
        />
      );
      case 'muiCheckbox':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                sx={{ color: 'white', fontSize: '12px !important' }}
                checked={item?.active || !!_.get(formValue, item.name)}
                aria-label={item?.label}
                // defaultChecked={_.get(formValue, item.name) || !!item?.checked}
                onChange={(e) => {
                  setFormValue({ ...formValue, [item.name]: e.target.checked });
                  // onChangeFormItem(v, item.name)
                }}
              />
            )}
            sx={{
              color: 'white', fontSize: '12px !important', alignItems: 'flex-start',
            }}
            label={(
              <div className="flex">
                <Typography sx={{
                  fontSize: '12px', fontWeight: 500, letterSpacing: '1.2px', mt: '11px',
                }}
                >
                  {t(item?.label)}
                </Typography>
              </div>
            )}
            required={!!item?.required}
            disabled={!!item?.disabled || formLoading}
          />

        );

      case 'boolean':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!formValue[item.name]}
                aria-label={item?.label}
                onChange={(e) => {
                  setFormValue({ ...formValue, [item.name]: e.target.checked });
                  // onChangeFormItem(v, item.name)
                }}
              />
            )}
            label={item?.label}
            required={!!item?.required}
            disabled={!!item?.disabled || formLoading}
          />

        );

      case 'file':
        return (
          <>
            <input
              type="file"
              accept={item?.accept}
              onChange={(e) => {
                setFormValue({ ...formValue, [item.name]: e.target.files });
              }}
            />
            {errors && errors[item?.name] && (
              <div className="form-input-error">{errors[item?.name]}</div>
            )}
            {item?.preview ? _.get(formValue, item.name) ? renderPreviewImages(_.get(formValue, item.name), item.name) : null : null}

          </>
        );

      case 'editor':
        return (
          <>
            <Editor
              tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={String(_.get(formValue, item.name)) || ''}
              onChange={(a, editor) => onChangeFormItem(editor.getContent(), `${item.name}_content`, item)}
              init={{
                content_style: `
                  body {
                    background: #141414;
                    color: white;
                  }
                `,
                height: 700,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                ],
                toolbar: 'undo redo | blocks | '
                  + 'bold italic link image media | alignleft aligncenter '
                  + 'alignright alignjustify | bullist numlist outdent indent | '
                  + 'help',
              }}
            />
            {(errors && errors[item?.name]) && <div className="form-input-error">{errors[item?.name]}</div>}
          </>
        );

      default:
        return null;
    }
  };

  const renderItem = (item) => {
    if (item.isText) {
      return (
        <div key={item.name} style={item.style || {}} className={clsx('form-group form-group-text mb-3')}>
          <Typography component="span" variant={item.type} sx={{ fontWeight: 600 }}>
            {t(item.label)}
          </Typography>
          <Typography component="span" variant={item.type} sx={{ fontWeight: 600 }}>
            {t(item?.subLabel || '')}
          </Typography>
        </div>
      );
    }

    if (item.type === 'link') {
      return (
        <div
          key={item.name}
          className={clsx(
            'form-group',
            focusedInputs.includes(item.name) && 'focused',
            item.formGroupType || 'default',
          )}
        >

          <Typography
            onClick={() => { window.location.href = item.path; }}
            color="primary"
            sx={{
              display: 'flex', justifyContent: 'center', ':hover': { fontWeight: 600 }, cursor: 'pointer',
            }}
          >
            {item.label}
            {' '}
          </Typography>
        </div>
      );
    }

    if (item.type === 'label') {
      return (
        <div key={item.name}>
          <Typography
            color="primary"
            sx={{
              display: 'flex',
              fontSize: '14px',
            }}
          >
            {item.label}
          </Typography>
        </div>
      );
    }

    const hidden = (item.showOnCondition && !formValue[item.showOnConditionField])
      || (!item.showOnCondition && formValue[item.showOnConditionField]) ? 'hidden' : '';

    return (
      <div
        key={item.name}
        className={clsx(
          'form-group',
          hidden,
          focusedInputs.includes(item.name) && 'focused',
          item.formGroupType || 'default',
          item.bubbleHelp && 'help',
        )}
      >
        {(item.type !== 'muiCheckbox' && item.type !== 'boolean') && (
          <div className={`label flex-row justify-between ${item.labelClassName} ${item?.subLabel ? 'no-margin' : 'yes-margin'}`}>
            <label htmlFor={item.name}>{item.label}</label>
            {!!item.header && <div>{item.header}</div>}
          </div>
        )}
        {
          item.subLabel && <label className="sublabel" htmlFor={item.subLabel}>{item.subLabel}</label>
        }
        {
          item.addLinkIcon && (
            <span style={{ position: 'absolute', right: '8px', top: '5px' }}>
              <CreateLinkModal />
            </span>
          )
        }

        {item.type && renderInput(item)}
        {
          item.underLabel && <label style={item.underLabelStyle} className="underlabel" htmlFor={item.underLabel}>{item.underLabel}</label>
        }
        {
          item.bubbleHelp && (
            <span style={{ position: 'absolute', right: '-20px', bottom: '6px' }}>
              <InfoBubbleModal>{item.bubbleHelp}</InfoBubbleModal>
            </span>
          )
        }
      </div>
    );
  };

  return (
    <div
      className={clsx('form', className, size)}
      style={{ pointerEvents: formLoading ? 'none' : 'all', position: 'relative' }}
    >
      {formValue ? config?.map(renderItem) : config?.map(renderItem)}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Button
          type={buttonType}
          size="md"
          className={`${submitClassName}`}
          disabled={formLoading}
          onClick={() => {
            if (clearAfterSubmit) {
              setFormValue(defaultFormValue);
            }
            onSubmit(formValue);
          }}
          style={buttonStyle}
        >
          {t(submitLabel)}
          {formLoading && <ClipLoader sx={{ ml: 2 }} size={13} color="black" />}
        </Button>
      </Grid>

    </div>
  );
}
