import { Grid, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import Layout from '../../../../components/CardLayout';
import AppLoader from '../../../../components/AppLoader';
import useMyCoinPicks from '../../context/hook';
import Price from '../../../../components/Price';
import DatePicker from '../../../../components/Inputs/DatePicker';
import Button from '../../../../components/Button';

export default function GridView() {
  const {
    loading, rows, paginationData, getMyPicks,
  } = useMyCoinPicks();

  const [curretDate, setCurrentDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClear = () => {
    setCurrentDate(null);
  };

  useEffect(() => {
    getMyPicks(curretDate, currentPage);
  }, [currentPage, curretDate]);

  return (
    <>
      {loading && <AppLoader overpage />}
      <h1
        style={{
          fontWeight: 600,
          fontSize: '28px',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        <span className="primary-text-gradient">My Coin Picks</span>
      </h1>

      <Layout className="my-coin-picks-list-view" mobileClassName="my-coin-picks-list-view" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
        <Grid
          size={12}
          sx={{
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'flex-end',
            marginBottom: { xs: '20px', sm: '20px' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            sx={{ marginBottom: { xs: '10px', sm: 0 } }}
          >
            Filter by Date:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              size="sm"
              sx={{ minWidth: '200px', flex: 1 }}
              value={curretDate}
              currentTimeZone="UTC"
              onChange={(v) => {
                setCurrentDate(dayjs(v).toISOString());
              }}
            />
            {curretDate && (
              <Button variant="outlined" onClick={handleClear} size="sm">
                Clear Date
              </Button>
            )}
          </div>
        </Grid>
        <Grid container spacing={4} padding={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {rows && rows.map((row) => (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={row.id}
                className="my-coin-picks-list-item"
              >
                <Grid item xs={2} className="coin-image">
                  <img src={row?.image} alt={row?.choosenCoin} />
                </Grid>

                <Grid item xs={5} className="list-items">
                  <Grid item className="game-price">
                    <Typography>
                      {`${row?.price} MG Coins Game`}
                    </Typography>
                  </Grid>
                  <Grid item className="coin-name">
                    <Typography>{row?.choosenCoin}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={5} className="list-items-right">
                  <Grid item className="coin-price">
                    <Typography>
                      <span style={{ fontSize: '11px', color: '#fff' }}>{'Entry: '}</span>
                      <Price value={row?.entryPrice} dollarSign />
                    </Typography>
                  </Grid>
                  <Grid item className="coin-pick-time">
                    <Typography>
                      {`${dayjs(row?.lastVotedAt).utc().format('MMM DD, YYYY HH:mm')} GMT`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalItems} votes`}
                </Typography>
                <Pagination
                  size="small"
                  siblingCount={0}
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
}
