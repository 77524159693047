import { useState } from 'react';
import CointListItem from './CointListItem';

export default function CurrentCoinsList({ coins, setCurrentCoin, MGTokens }) {
  const [activeCoins, setActiveCoins] = useState(null);
  return (
    <div
      className="current-coins-list"
      
    >
      {coins.length > 0 ? coins.map((item, index) => {
        const {
          image, name, price, apiId, 
        } = item;
        
        return (
          <CointListItem 
            image={image}
            coinName={name}
            price={price}
            startPrice={item.startPrice}
            priceChange={item.priceChange}
            active={activeCoins === null && index === 0 ? true : activeCoins === apiId}
            onClick={() => { 
              setCurrentCoin(item); 
              setActiveCoins(apiId); 
            }}
            id={apiId}
            key={apiId}
            MGTokens={MGTokens}
            chart={item.chart}
            marketCap={item.marketCap}
            totalVolume={item.totalVolume}
          />
        ); 
      }) : null}
     
    </div>
    
  );
}
