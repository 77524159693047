export const simulatorSettingsFormConfig = [
  {
    name: 'mgTokenPrice',
    type: 'number',
    label: 'MG Token Price',
    placeholder: '0.10',
    required: true,
    allowDecimals: true,
  },
  {
    name: 'minMGTokenToRedeem',
    type: 'number',
    label: 'Min MG Tokens To Redeem/Simulate',
    placeholder: '1',
    required: true,
    allowDecimals: true,
  },
  {
    name: 'feePercentage',
    type: 'number',
    label: 'Fee %',
    placeholder: '0.1',
    required: true,
    allowDecimals: true,
  },
  {
    name: 'feeMinCharge',
    type: 'number',
    label: 'Fee Min Charge (MG Tokens)',
    placeholder: '1',
    required: true,
    allowDecimals: true,
  },
];

export default {};
