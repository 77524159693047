// import { useState } from 'react';
import { Grid, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import Table from '../../../../components/Table';
import { columns } from './config';
import Layout from '../../../../components/CardLayout';
import AppLoader from '../../../../components/AppLoader';
import useMyCoinPicks from '../../context/hook';
import DatePicker from '../../../../components/Inputs/DatePicker';
import Button from '../../../../components/Button';

export default function GridView() {
  const {
    loading, rows, paginationData, getMyPicks, setSortModel, sortModel,
  } = useMyCoinPicks();

  const [curretDate, setCurrentDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleClear = () => {
    setCurrentDate(null);
  };

  useEffect(() => {
    getMyPicks(curretDate, currentPage);
  }, [currentPage, curretDate]);

  return (
    <>
      {loading && <AppLoader overpage />}
      <h1
        style={{
          fontWeight: 600,
          fontSize: '32px',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        <span className="primary-text-gradient">My Coin Picks</span>
      </h1>

      <Layout className="my-coin-picks-grid-view" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
        <Grid
          size={{ xs: 12, sm: 8 }}
          sx={{
            alignItems: 'center', gap: '10px', justifyContent: 'flex-end', marginBottom: { xs: '20px', sm: '20px' }, display: { sm: 'flex' },
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            sx={{ marginBottom: { xs: '10px', sm: 0 } }}
          >
            Filter by Date:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              size="sm"
              sx={{ minWidth: '200px' }}
              value={curretDate}
              currentTimeZone="UTC"
              onChange={(v) => {
                setCurrentDate(dayjs(v).toISOString());
              }}
            />
            {curretDate && (
              <Button variant="outlined" onClick={handleClear}>
                Clear Date
              </Button>
            )}
          </div>
        </Grid>
        <Grid container spacing={4} padding={0}>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Table
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel);
              }}
              sortingOrder={['desc', 'asc']}
              sortingMode="server"
              columns={columns}
              rows={rows}
              className="table"
              hideFooterPagination
              hideFooter
              getRowId={(row) => row.id}
            />
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalItems} votes`}
                </Typography>
                <Pagination
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
}
