import {
  useMemo, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress, Pagination,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { set } from 'lodash';
import { columnsConfig } from './config';
import Table from '../../../../../../components/Table';
import Select from '../../../../../../components/Inputs/Select';
import TablePagination from '../../../../../../components/Table/Pagination';
import TableMobile from '../../../../../../components/Table/TableMobile';
import { useGetPassess, PAGE_SIZE } from '../../../context/hooks';

export default function CoinPacks() {
  const { t } = useTranslation();

  const { userId } = useParams();
  
  const [currentPage, setPage] = useState(1);
  const [used, setUsed] = useState(undefined);
  const [paid, setPaid] = useState(undefined);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const columns = useMemo(() => (
    columnsConfig(t)
  ), [t]);

  const getPassess = useGetPassess(
    {
      id: userId,
      page: currentPage,
      used,
      paid,
      perPage,
    },
  );
  
  const [usedPaid, setUsedPaid] = useState(undefined);
    
  useEffect(() => {
    if (used) {
      setUsedPaid(used);
    } else if (paid === false || paid === true) {
      setUsedPaid(paid === true ? 'ready' : '50/50');
    }
  }, [paid, used]);

  return ( 
    <div className="coin-packs-card" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

      <div className="flex packs-filters">
        <div className="flex flex-1">
          <Select
            className="transfers-filter-select"
            placeholder="Status"
            variant="outlined"
            size="sm"
            value={usedPaid || 'default'}
            onChange={(v) => {
              if (v === 'ready' || v === '50/50') {
                setPaid(v === 'ready');
                setUsed(undefined);
              } else {
                setUsed(v === 'all' ? undefined : v);
                setPaid(undefined);
                setPage(1);
              }
            }}
            options={[
              { value: 'all', label: 'All' },
              { value: 'ready', label: 'Ready' },
              { value: '50/50', label: '50/50' },
              { value: 'true', label: 'Used' },
              { value: 'false', label: 'Not Used' },
            ]}
          />
          <Select
            className="transfers-filter-select"
            placeholder="Per page"
            size="sm"
            value={perPage ? perPage.toString() : perPage || 'default'}
            onChange={(v) => {
              setPerPage(v === 'default' ? undefined : v);
              setPage(1);
            }}
            options={[
              { value: '5', label: '5 per page' },
              { value: '10', label: '10 per page' }, 
              { value: '20', label: '20 per page' }, 
              { value: '50', label: '50 per page' },
              { value: '100', label: '100 per page' },
            ]}
          />

        </div>
 
      </div>

      <div className="table-cont">
        {getPassess.isFetching && !getPassess.data ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="desktop">
              <Table loading={getPassess.isFetching} columns={columns} rows={getPassess.data?.Items.map((v) => ({ ...v, id: v._id }))} className="table mb-4" />
            </div>
            <div className="mobile">
              <TableMobile columns={columns} data={getPassess.data?.Items.map((v) => ({ ...v, id: v._id }))} className="mb-6" />
             
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}> 
        <Pagination
          count={getPassess.data && getPassess.data.Total ? Math.ceil(getPassess.data.Total / (perPage || PAGE_SIZE)) : 0}
          page={currentPage}
          onChange={(val, _page) => {
            setPage(_page);
          }}
        />
      </div>
    </div>
  );
}
