import { useContext, useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import BeatLoader from 'react-spinners/BeatLoader';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';

export default function AskAIModal() {
  const {
    askAIModal, handleAskAIModalClose, aiQuestion, 
    setAiQuestion, askAiSubmit, aiConversation, t,
  } = useContext(Context);

  const conversationRef = useRef();

  useEffect(() => {
    if (askAIModal) {
      if (aiQuestion.length > 0) {
        askAiSubmit();
      }
    }
  }, [askAIModal]);

  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef?.current?.scrollHeight;
    }
  }, [aiConversation, conversationRef]);
  return (
    <Modal
      title="Ask a Guru"
      open={askAIModal}
      handleClose={handleAskAIModalClose}
      width=""
    >
      <Grid container spacing={3} className="chatgpt-modal">

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="chatgpt-aq">
          <Container
            sx={{
              minHeight: '200px',
              borderRadius: '10px',
              position: 'relative',
              p: 2,
              fontSize: '14px',
              maxHeight: '600px',
              overflow: 'auto',
            }}
            className="conversation"
            id="chat-container"
            ref={conversationRef}
          >

            <Grid container>
        
              {
                aiConversation.map((c, i) => (
                  <Grid key={i} item xl={12} lg={12} sm={12} md={12}>

                    <div className="question-container">
                      <PersonOutlineIcon className="user-icon" />

                      <div className="message">
                        <Typography className="time">
                          {dayjs(c?.qTime).tz('GMT').format('HH:mm')}
                        </Typography>

                        <Typography className="question">
                          {c.q}
                        </Typography>
                      </div>

                    </div>
                    <div className="answer-container">
                      <img src="/assets/images/johndoe.png" alt="john" />

                      <div className="message">
                        <Typography className="time">
                          {dayjs(c?.aTime).tz('GMT').format('HH:mm')}
                        </Typography>
                        {
                          c.state === 'loading' && (
                          <Typography sx={{ display: 'flex' }}>  
                            {' '}
                            <span style={{ fontSize: '14px', opacity: '50%', mr: 1 }}>{t('Working on your answer')}</span> 
                            {' '}
                            <BeatLoader color="#2B2D31" />
                            {' '}
                          </Typography>
                          )
                        }

                        {
                          c.state === 'done' && <Markdown className="answer" remarkPlugins={[remarkGfm]}>{c.a}</Markdown>

                        }
                      </div>

                    </div>
                  </Grid>
                ))
              }

            </Grid>
          </Container>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
          <Grid container rowSpacing={2}>

            <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
              <TextInput
                onChange={(e) => {
                  setAiQuestion(e);
                }}
                value={aiQuestion}
                rows="auto"
                style={{ flexBasis: '75%' }}
                type="textarea"
                placeholder="Type a question here ..."
              />

            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: {
                  xl: 'end', lg: 'end', md: 'center', sm: 'center', xs: 'enter', 
                },
                alignItems: 'center', 
              }}
            >
              <Button onClick={() => {
                askAiSubmit(); 
              }}
              >
                Ask
              </Button>
            </Grid>

          </Grid>

        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

          <Typography sx={{ fontSize: '12px', color: 'gray' }}>
            *
            {t('Answers are generated with artificial intelligence and should not be relied on in connection with investment decisions.')}
          </Typography>

        </Grid>
   
      </Grid>
    </Modal>

  );
}
