import clsx from 'clsx';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Button from '../../Button';
import QRCodeModal from '../../QRCodeModal';

export default function TextInput({
  type,
  name = '',
  canCopy,
  qrIcon,
  className,
  value = '',
  focused = false,
  placeholder = '',
  size = 'md',
  endAdornment = '',
  startAdornment = '',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
  style = {},
  inputStyle = {},
  rows = 3,
  autocomplete,
}) {
  return (
    <div style={style} className="form-input-group">
      <div
        className={clsx(
          'form-input text-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
        )}
      >
        {startAdornment}
        {type === 'textarea' ? (
          <textarea
            id={name}
            type={type}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            style={inputStyle}
            className={clsx(
              focused && 'focused',
              size,
              type,
              disabled && 'disabled',
              error && 'error',
            )}
            onChange={(e) => {
              const val = e.target.value;
              onChange(val);
            }}
            readOnly={readOnly}
            disabled={disabled}
            rows={rows}
          />
        ) : (
          <input
            id={name}
            type={type}
            value={value}
            onBlur={onBlur}
            onFocus={(...e) => {
              if (type === 'number') {
                e[0].target.select();
              }

              onFocus(e);
            }}
            placeholder={placeholder}
            className={clsx(focused && 'focused', size, disabled && 'disabled', error && 'error')}
            onChange={(e) => {
              const val = e.target.value;
              if (type === 'number') {
                onChange(Number(val || 0));
                return;
              }
              onChange(val);
            }}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            disabled={disabled}
            autoComplete={autocomplete}
          />
        )}

        {endAdornment}

        {canCopy && (
          <div className="flex flex-column">
            <Button
              onClick={() => {
                toast.success('Link Copied!');
                copy(value);
              }}
              className="mr-2"
              style={{ marginTop: 0 }}
              bordered
              size="sm"
            >
              <span className="no-wrap">COPY LINK</span>
            </Button>
          </div>
        )}

        {qrIcon && (
          <div className="flex flex-column pr-1" style={{ position: 'relative', zIndex: '9999', opacity: 1 }}>
            <QRCodeModal url={value} downloadText="affiliate_link" />
          </div>
        )}
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
