import { useState } from 'react';
import {
  Box, Container, Tab, Tabs, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/CardLayout';
import ResultsBox from '../../AdminReports/components/ResultsBox';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';
import { useSupportUser } from './context/hooks';
import TransactionHistory from './components/TransactionHistory';
import GamerHistory from './components/GamerHistoryContent';
import MyCoinPicks from './components/MyCoinPicks';
import CoinPackHistory from './components/CoinPackHistory';
import GameHistory from './components/GameHistory';
import { formatPhoneNumber } from '../../../utils/string';

export default function UserDetails() {
  const { t } = useTranslation();
  const [listTabsValue, setListTabsValue] = useState(0);
  const { userId } = useParams();
  const supportUser = useSupportUser(userId);
  const handleListTabChange = (_, newValue) => {
    setListTabsValue(newValue);
  };

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 4, padding: { xs: 0, lg: '24px' } }}>
      {supportUser.data ? (
        <>
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '20px',
          }}
          >

            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: { xs: '26px', lg: '32px' },
                fontWeight: 600,
                mb: 4,
                textAlign: 'center',
              }}
            >
              {`${supportUser.data.Name} ${supportUser.data.Surname}  (${supportUser.data.Username} - ${supportUser.data.SID})`}
            </Typography>

            {supportUser.data.isProduct ? (
              <div className="result">
                <ResultsBox
                  title="Subscription Information"
                  result={(
                    <>
                      <Typography sx={{ fontWeight: '700', fontSize: '20px', mx: 'auto' }}>
                        {supportUser.data.Package}
                      </Typography>
                      {!supportUser.data.isExpired ? (
                        <>
                          <Typography sx={{ fontSize: '15px', mx: 'auto' }}>
                            {`Your subscription expires in ${dayjs(supportUser.data.DateExpiring).diff(dayjs().tz('GMT'), 'day')} days`}
                          </Typography>
                          <Typography sx={{ fontSize: '15px', mx: 'auto', mb: 0 }}>
                            {`Expiration Date: ${dayjs(supportUser.data.DateExpiring).tz('GMT').format('MMM DD, YYYY')}`}
                          </Typography>
                        </>
                      ) : (
                        <Typography sx={{
                          fontSize: '16px', fontWeight: '700', mx: 'auto', mb: 0, color: '#d13939',
                        }}
                        >
                          {t('Your subscription has expired on:')}
                          {' '}
                          {dayjs(supportUser.data.DateExpiring).tz('GMT').format('MMM DD, YYYY')}
                        </Typography>
                      )}
                    </>
                  )}
                  isHTML
                />

              </div>
            ) : null}

            <div style={{
              width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', justifyContent: 'center',
            }}
            >

              {supportUser.data.wallet ? (
                <div className="result">
                  <ResultsBox
                    title="Member since"
                    result={dayjs(supportUser.data.DateJoined).tz('GMT').format('MMM DD, YYYY')}
                    text
                  />

                </div>
              ) : null}

              {supportUser.data.gamerWallet ? (
                <div className="result">
                  <ResultsBox
                    title="Activation Date"
                    result={dayjs(supportUser.data.DateLastRenewed).tz('GMT').format('MMMM DD, YYYY')}
                    text
                  />

                </div>
              ) : null}
              {supportUser.data.wallet ? (
                <div className="result">
                  <ResultsBox
                    title="Ambassador wallet"
                    result={`${supportUser.data.wallet.currency} $${supportUser.data.wallet.balance.toLocaleString('en-us')}`}
                    text
                  />

                </div>
              ) : null}

              {supportUser.data.gamerWallet ? (
                <div className="result">
                  <ResultsBox
                    title="Gamer wallet"
                    result={`${supportUser.data.gamerWallet.coins && supportUser.data.gamerWallet.coins.toLocaleString('en-us')} MG Coins |  ${supportUser.data.gamerWallet.token && supportUser.data.gamerWallet.token.toLocaleString('en-us')} MG Tokens`}
                    text
                  />

                </div>
              ) : null}

            </div>

          </div>
          <div style={{
            width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', alignItems: 'center',
          }}
          >
            {supportUser.data.canWithdraw ? (
              <div className="result">
                <ResultsBox
                  title="Can Withdraw"
                  result={`${supportUser.data.canWithdraw === true ? 'Yes' : 'No'}`}
                  text
                />

              </div>
            ) : null}
            {supportUser.data.solanaWallet ? (
              <div className="result" style={{ maxWidth: '500px', margin: '20px 0' }}>
                <ResultsBox
                  title="Solana wallet"
                  result={`${supportUser.data.solanaWallet.publicKey ? supportUser.data.solanaWallet.publicKey : 'Solana wallet not connected'} `}
                  text
                />
              </div>
            ) : null}
          </div>

          {supportUser.data.sponsor ? (

            <div className="result" style={{ maxWidth: '800px', margin: '20px auto' }}>
              <ResultsBox
                title="Sponsor"
                result={`${supportUser.data.sponsor.Name ? `Username: ${supportUser.data.sponsor.Name} ${supportUser.data.sponsor?.Surname}` : ''}, ${supportUser.data.sponsor.Email ? `Email: ${supportUser.data.sponsor?.Email}` : ''}, ${supportUser.data.sponsor.Mobile ? `Phone: ${formatPhoneNumber(supportUser.data.sponsor?.Mobile.replace('+', ''))}` : ''}`}
                text
              />
            </div>
          ) : null}
        </>

      ) : null}

      <Container
        className="ambassador-tabs noPadding"
        style={{ paddingLeft: '0px !imporatant', paddingRight: '0px !imporatant' }}
        maxWidth="xl"
      >
        <Box>
          <Tabs
            value={listTabsValue}
            onChange={handleListTabChange}
            aria-label="History Section"
            className="ambassador-tabs-header"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Ambassador Transaction History" {...a11yProps(0)} />
            <Tab label="Gamer Wallet History" {...a11yProps(1)} />
            <Tab label="Coin Pick history" {...a11yProps(2)} />
            <Tab label="Coin Pack History" {...a11yProps(3)} />
            <Tab label="Game History" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={listTabsValue}
          index={0}
          className="ambassador-tabs-transaction"
        >

          <Typography
            className="flex-1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '18px', md: '26px' },
              fontWeight: 600,
              mb: 2,
            }}
          >
            Transaction History
          </Typography>

          <TransactionHistory />
        </CustomTabPanel>
        <CustomTabPanel
          value={listTabsValue}
          index={1}
          className="ambassador-tabs-earnings"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '21px', lg: '26px' },
              fontWeight: 600,
              mb: 2,
              textAlign: 'center',
            }}
          >
            Wallet History
          </Typography>
          <GamerHistory />
        </CustomTabPanel>
        <CustomTabPanel
          value={listTabsValue}
          index={2}
          className="ambassador-tabs-earnings"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '21px', lg: '26px' },
              fontWeight: 600,
              mb: 2,
              textAlign: 'center',
            }}
          >
            Coin Pick History
          </Typography>
          <MyCoinPicks />
        </CustomTabPanel>

        <CustomTabPanel
          value={listTabsValue}
          index={3}
          className="ambassador-tabs-earnings"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '21px', lg: '26px' },
              fontWeight: 600,
              mb: 2,
              textAlign: 'center',
            }}
          >
            Coin Pack History
          </Typography>
          <CoinPackHistory />
        </CustomTabPanel>
        <CustomTabPanel
          value={listTabsValue}
          index={4}
          className="ambassador-tabs-earnings"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '21px', lg: '26px' },
              fontWeight: 600,
              mb: 2,
              textAlign: 'center',
            }}
          >
            Game History
          </Typography>
          <GameHistory />
        </CustomTabPanel>

      </Container>
    </Layout>
  );
}
