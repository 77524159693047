import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import WebinarForm from './components/WebinarForm';
import PlayPodcast from '../../components/PlayPodcast';
import useAuthenticationApi from '../Authentication/api';
import { getFromSession } from '../../utils/session';

const GamificationWebinarPage = () => {
  const navigate = useNavigate();
  const [openWebinarModal, setOpenWebinarModal] = useState(false);
  const { getParent } = useAuthenticationApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);
  const excludedSubs = [
    'app',
    'staging',
    'onboarding',
    'www',
  ];

  useEffect(() => {
    const host = window.location.href;
    const uri = new URL(host);

    if (!signupParent && uri.hostname.split('.').length > 2) {
      const [_u] = uri.hostname.split('.');

      if (_u && !excludedSubs.includes(_u)) {
        const _fetch = async () => {
          try {
            const res = await getParent(_u);
            sessionStorage.setItem('signupParent', JSON.stringify(res));
            navigate('/');
          } catch (err) {
            if (err === 'Not Found') {
              navigate('/dashboard/registration/new');
            } else {
              console.log('err--', err);
              toast.error(err);
            }
          }
        };

        _fetch();
      }
    }
  }, [signupParent]);

  const onSignIn = () => {
    navigate('/signin');
  };

  const onSignup = () => {
    const host = window.location.href;
    const uri = new URL(host);
    const { hostname } = uri;

    const hostParts = hostname.split('.');
    if (hostParts.length > 2) {
      const [_u] = hostParts;
      if (_u && excludedSubs.includes(_u)) {
        if (signupParent) {
          navigate(`/dashboard/signin?sponsor=${signupParent?.SponsorName}`);
        } else {
          navigate('/dashboard/signin');
        }
      } else {
        const url = `${process.env.REACT_APP_HOST}/dashboard/signin?sponsor=${signupParent?.SponsorName}`;
        window.location.href = url;
      }
    } else if (hostParts.length <= 2) {
      if (signupParent) {
        navigate(`/dashboard/signin?sponsor=${signupParent?.SponsorName}`);
      } else {
        navigate('/dashboard/signin');
      }
    }
  };

  const handleCloseModal = () => {
    setOpenWebinarModal(false);
  };

  return (
    <div className="gamification-webinar">
      <div className="nav-cont">
        <div className="img-cont">
          <img src="/assets/MemeGames-logo-sol.png" alt="memegames-logo" />
        </div>

        <div className="button-cont flex-1 flex">
          {signupParent ? (
            <Button
              onClick={onSignup}
              icon={<Icon name="SignUpAs" width={17} height={17} />}
              size="sm"
            >
              <Typography sx={{ fontWeight: '500', alignSelf: 'center' }}>
                <span style={{ color: 'white', fontWeight: 400 }}>
                  Sign Up for Free with:
                </span>
                {signupParent?.firstName && signupParent?.lastName ? ` ${signupParent?.firstName || ''} ${signupParent?.lastName || ''} ` : ` ${signupParent.username}`}
              </Typography>
            </Button>
          ) : null}

          <Button
            onClick={onSignIn}
            bordered
            icon={<Icon name="UserIcon" width={17} height={17} />}
            size="sm"
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '500',
                alignSelf: 'center',
              }}
            >
              Login
            </Typography>
          </Button>
        </div>
      </div>

      <div className="section-container">
        <div className="text-container">
          <Typography className="subtitle primary-text-gradient">MEMEGAMES.AI</Typography>

          <Typography className="title">
            Just Launching
          </Typography>

          <Typography className="content">
            {`Be a part of the gamification of Memecoins! Daily 
              competitions for gamers. Daily Payouts for Affiliates. 
              Utilizing AI Tech Marketing & Support! FREE Sign Up!`}
          </Typography>

          <Typography className="content">
            Register now to Listen to the Podcast:
          </Typography>

          <Button size="sm" style={{ width: 'fit-content' }} onClick={() => { setOpenWebinarModal(true); }}>
            <Typography sx={{ mx: '16px' }}>Interested?</Typography>
          </Button>

          <PlayPodcast />

          <Modal width="550px" title="Register for MemeGames" open={openWebinarModal} handleClose={handleCloseModal}>
            <WebinarForm handleCloseModal={handleCloseModal} />
          </Modal>
        </div>

        <div className="image-container">
          <img src="/images/smile.png" alt="smile" />
          <div className="img-shadow" />
        </div>
      </div>

      <footer className="footer-container flex flex-column">
        <div className="flex justify-center">
          <img src="/images/white-logo.png" alt="white-logo" />
        </div>
        <div className="flex justify-center">
          <span>
            ©
            {dayjs().tz('GMT').toDate().getFullYear()}
            {' '}
            MemeGames.ai. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default GamificationWebinarPage;
