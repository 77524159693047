import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCallback, useContext, useState } from 'react';
import useApi from '../../../../utils/api';
import { AuthenticationContext } from '../../../../utils/store/authentication';

const PAGE_SIZE = 9;

const usePublicBlogApi = () => {
  const { authGet } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);

  const [blogPostsData, setBlogPostsData] = useState([]);
  const [blogPostsDataLoaded, setBlogPostsDataLoaded] = useState(false);
  const [blogPaginationData, setBlogPaginationData] = useState(null);
  const [postSingle, setPostSingle] = useState(null);
  const [postSingleLoaded, setPostSingleLoaded] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  // All blog tags
  const getAllTags = useCallback(
    async () => {
      try {
        setTagsLoaded(false);
        const response = await authGet('/blog/tags');

        const tagList = response?.data?.map((tag) => ({
          label: tag.title,
          value: tag.id,
        }));

        setTags(tagList);

        setTagsLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Tags');
      }
    },
    [authGet],
  );

  // All blog categories
  const getAllCategories = useCallback(
    async () => {
      try {
        setCategoriesLoaded(false);
        const response = await authGet('/blog/categories');

        const categoriesList = response?.data?.map((tag) => ({
          label: tag.title,
          value: tag.id,
        }));

        setCategories(categoriesList);

        setCategoriesLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Tags');
      }
    },
    [authGet],
  );

  // All blog posts
  const fetchPublicBlogData = useCallback(
    async ({
      page = 1, category = undefined, tag = undefined, newest = true,
    }) => {
      try {
        setBlogPostsDataLoaded(false);
        const params = { page, perPage: PAGE_SIZE, newest };
        if (category) {
          Object.assign(params, { categoryId: category });
        }
        if (tag) {
          Object.assign(params, { tagId: tag });
        }

        const response = await authGet('/blog', {
          params,
        });

        setBlogPostsData(response.data || []);

        setBlogPaginationData({
          totalPosts: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
        toast.error('Error fetching posts Data');
      } finally {
        setBlogPostsDataLoaded(true);
      }
    },
    [authGet],
  );

  const fetchPostSingleData = useCallback(
    async (slug) => {
      try {
        setPostSingleLoaded(false);
        const response = await authGet(`/blog/post/${slug}`);
        setPostSingle(response.data || null);
        setPostSingleLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error(`Error fetchng post ${slug}`);
      }
    },
    [authGet],
  );

  // Single Post

  return {
    t,
    user,
    fetchPostSingleData,
    postSingle,
    postSingleLoaded,
    fetchPublicBlogData,
    blogPostsData,
    blogPostsDataLoaded,
    blogPaginationData,
    getAllTags,
    tags,
    tagsLoaded,
    getAllCategories,
    categories,
    categoriesLoaded,
  };
};

export default usePublicBlogApi;
