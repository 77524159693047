import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PasswordInput({
  type,
  name = '',
  className,
  value = '',
  focused = false,
  placeholder = '',
  size = 'md',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="form-input-group">
      <div
        className={clsx(
          'form-input text-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
        )}
      >
        <input
          id={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onBlur={onBlur}
          onFocus={(...e) => {
            if (type === 'number') {
              e[0].target.select();
            }
            onFocus(e);
          }}
          placeholder={placeholder}
          className={clsx(focused && 'focused', size, disabled && 'disabled', error && 'error')}
          onChange={(e) => {
            const val = e.target.value;
            if (type === 'number') {
              onChange(Number(val || 0));
              return;
            }
            onChange(val);
          }}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
        />

        <div style={{
          display: 'flex', justifyContent: 'end', alignItems: 'center', marginRight: '5px', marginLeft: '5px', background: 'transparent',
        }}
        >
          {
            showPassword ? <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(false)} color="primary" /> : <VisibilityOffIcon sx={{ cursor: 'pointer', color: 'gray' }} onClick={() => setShowPassword(true)} />
          }
        </div>
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
