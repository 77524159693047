/* eslint-disable no-unused-expressions */
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useApi from '../../../../utils/api';

export const useSimulatorRules = () => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator-rules'],
    queryFn: async () => {
      const response = await authGet('/simulator/rules');
      const data = response.data === null ? { title: null, content: '' } : response.data;
      return { data };
    },
    staleTime: 1000 * 60 * 0,
  });
};

export const useSimulatorUpdateRules = ({ onSuccess }) => {
  const { authPost } = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await authPost('simulator/rules', { data });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['simulator-rules'] });
      if (onSuccess) {
        onSuccess(data);
      }
      toast.success('Rules successful updated');
    },
    onError: (error) => {
      toast.error(typeof error === 'string' ? error : error?.response?.data?.message);
    },
  });
};
