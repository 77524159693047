/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Price from '../../../../components/Price';

// import { Grid, Tooltip, Typography } from '@mui/material';

export const columns = [
  {
    field: 'price',
    headerName: 'Game',
    width: 150,
    cellClassName: () => 'first-column ',
    renderCell: ({ value }) => (`${value} MG Coins`),
  },
  {
    field: 'choosenCoin',
    headerName: 'Choosen Coin',
    width: 350,
    renderCell: ({ row, value }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <>
            <span className="coin-image" style={{ float: 'left' }}>
              <img src={row?.image} alt={value} />
            </span>
            <Typography className="coin-name">
              {value}
            </Typography>
          </>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'entryPrice',
    headerName: 'Entry Price',
    width: 250,
    renderCell: ({ value }) => (
      <Price className="coin-price" value={value} dollarSign />
    ),
  },
  {
    field: 'lastVotedAt',
    headerName: 'Entry Time',
    width: 250,
    renderCell: ({ value }) => (`${dayjs(value).utc().format('MMM DD, YYYY HH:mm:ss')} GMT`),
  },
];

export default {};
