import CurrentCoins from './components/CurrentCoins';
import MyCointsList from './components/MyCointsList';

export default function Simulator() {
  return (
    <>
      <CurrentCoins />
      <MyCointsList />
    </>
    
  );
}
