/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import {
  Container, Grid, Slider, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { useBreakpoint } from '../../../utils/store'; 
import {
 useSimulatorRedeemMGTokens, convertUSDToMgTokens, getMarks, increment, 
 useSimulatorSettings, calcMinCoinsToBeMgToken,
} from '../context/hook';
import ComponentLoader from '../../../components/ComponentLoader';
import { renderNumber } from '../../../utils/number';
import TextInput from '../../../components/Inputs/TextInput';

export default function SimulatorGetMGTokensModal({
  isOpen, handleClose, coin, price, balance,
}) {
  const simulatorSettings = useSimulatorSettings();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoint();
  const [confirmRedeem, setConfirmRedeem] = useState(false);
  const handlerConfirmRedeem = () => setConfirmRedeem(!confirmRedeem);
  const [minAmount, setMinAmount] = useState(0);

  const [withdrawAmount, setWithdrawAmount] = useState(minAmount);
  const [totalCoins, setTotalCoins] = useState(0);
  const [fee, setFee] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [porcentage, setPorcentage] = useState(0.01);
  const [mgToken, setMgToken] = useState(1);

  useEffect(() => {
    if (simulatorSettings.data) {
      const porc = simulatorSettings.data.feePercentage ? simulatorSettings.data.feePercentage / 100 : 0.01;
      setPorcentage(porc);
      setMgToken(simulatorSettings.data.mgTokenPrice);
    }
  }, [simulatorSettings.data]);
  
  useEffect(() => {
      const min = calcMinCoinsToBeMgToken({ coinPrice: 1, mgToken });
      setMinAmount(min);
      setWithdrawAmount(min);
  }, [balance, mgToken]);
  
  useEffect(() => {
    const coinValue = convertUSDToMgTokens({ mgTokensAmount: withdrawAmount, mgToken });
    setTotalCoins(coinValue);
    return () => {};
  }, [withdrawAmount, mgToken]); 
  
  useEffect(() => {
    if (simulatorSettings.data && simulatorSettings.data.feeMinCharge) {
       const f = Math.ceil(porcentage * totalCoins);
       const currentFee = f > simulatorSettings.data?.feeMinCharge ? f : simulatorSettings.data.feeMinCharge;
      setFee(currentFee);
    }
  }, [totalCoins, simulatorSettings.data]);
  
  useEffect(() => {
    const generalFee = Math.ceil(porcentage * balance);
    setMaxValue(Math.floor(balance - generalFee));
  }, []);

  const simulatorRedeemMGTokens = useSimulatorRedeemMGTokens({
    onSuccess: () => {
        setWithdrawAmount(minAmount + 1);
        setTotalCoins(0);
        handleClose();
      }, 
  });

 return (
   <Modal
     width="700px"
     title={`Simulate ${coin}`}
     open={isOpen}
     handleClose={handleClose}
   >
     { balance < 1 ? (
       <div style={{ maxWidth: '500px', margin: '0 auto', marginBottom: '20px' }}>
         <Typography style={{
     opacity: '.7', fontSize: '20px', textAlign: 'center', marginBottom: '30px', 
    }}
         > 
           Sorry, you don’t have enough USD to complete this transaction.
         </Typography>
         <div style={{ display: 'flex', justifyContent: 'center' }}> 
           <Button
             size="md"
             style={{ width: '200px' }}
             type="button"
             onClick={async () => {
                               handleClose();      
                           }}
           >
             <span style={{ whiteSpace: 'nowrap' }}>{t('Ok')}</span>
           </Button>
         </div>
       </div>
    ) : (
      <div>
        <Grid
          container
          size={12}
          gap={3}
          style={{
          maxWidth: '500px', margin: '0 auto', marginBottom: '30px', alignItems: 'center', 
        }}
        >
          <Grid
            item
          >
            <img 
              style={{
              width: isMobile || isTablet ? '80px' : '94px',
              height: isMobile || isTablet ? '80px' : '94px', 
              borderRadius: '90px', 
            }}
              src="./assets/mgtokens.png"
              alt={coin}
            />
         
          </Grid>
          <Grid
            item
            sx={{ flex: 1 }}
          >
    
            <Typography
              fontSize="14px"
              fontWeight={500}
              marginBottom="0px"
              marginRight="auto"
              sx={{ marginTop: '0px' }}
            >
              {`$${parseFloat(price).toFixed(2)}`}
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight={500}
              marginBottom="0px"
              marginRight="auto"
              sx={{ marginTop: '0px', opacity: '0.5', marginBottom: '10px' }}
            >
              {t('Current price:')}
            </Typography>
        
          </Grid>
          <Grid
            item
            sx={{ flex: 1 }}
          >
            <div style={{ position: 'relative' }}>
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                color="#fff"
                sx={{ opacity: '0.5', marginBottom: '20px' }}
              >
                {t(`MG Tokens have a value of  $${simulatorSettings.data ? simulatorSettings.data.mgTokenPrice.toFixed(2) : 1.00} each`)}
          
              </Typography>
              <img
                alt="logo-white"
                src="/icons/question.svg"
                width="16px"
                height="16px"
                style={{ position: 'absolute', top: '-10px', right: '-5px' }}
              />
            </div>
         
            <div style={{
            border: '1px solid rgb(255 255 255 / 14%)',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center', 
          }}
            >
              <Typography
                fontSize="30px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="10px"
              >
                <span className="primary-text-gradient">{t('=')}</span>
            
              </Typography>
              <div style={{ flex: 1 }}>
                <Typography
                  fontSize="14px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                >
                  <span className="primary-text-gradient">{ renderNumber(totalCoins.toFixed(2)) }</span>
                  <span className="primary-text-gradient" style={{ fontSize: '10px' }}>  MG Tokens</span>
                </Typography>
                {/* <Typography
                  fontSize="12px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                  sx={{ marginTop: '-5px', opacity: '0.5' }}
                >
                  {t('Subtotal')}
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                >
                  <span className="primary-text-gradient">{ renderNumber(fee) }</span>
                  <span className="primary-text-gradient" style={{ fontSize: '10px' }}>  MG Tokens</span>
                </Typography>
                <Typography
                  fontSize="12px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                  sx={{ marginTop: '-5px', opacity: '0.5' }}
                >
                  {t('Fee')}
                </Typography>
                <Typography
                  fontSize="24px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span className="primary-text-gradient">{`${renderNumber(totalCoins - fee)} ` } </span> 
                  <span className="primary-text-gradient" style={{ fontSize: '10px' }}>  MG Tokens</span>
                </Typography> */}
                <Typography
                  fontSize="12px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="auto"
                  sx={{ marginTop: '-5px', opacity: '0.5' }}
                >
                  {t('Total')}
                </Typography>
              </div>
           
            </div>
          </Grid>
        </Grid>
   
        <Container style={{ maxWidth: '550px', margin: '0 auto' }}>
          <Typography style={{ opacity: '.7', fontSize: '12px' }}>
            Available USD Balance
          </Typography>
          {maxValue > 0 && minAmount > 0 && simulatorSettings.data ? (
            <Slider
              min={minAmount}
              max={Math.floor(maxValue)}
              value={withdrawAmount}
              step={increment}
              getAriaValueText={() => `$${withdrawAmount}`}
              valueLabelDisplay="auto"
              onChange={(e, v) => {
            setWithdrawAmount(v);
        }}
              disabled={simulatorRedeemMGTokens.isPending}
              marks={getMarks({ max: Math.floor(maxValue), min: minAmount })}
            />
        ) : null}
       
          <div style={{
          display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', marginTop: '10px', alignItems: 'center',
        }}
          >
            <Typography
              fontSize="14px"
              fontWeight={500}
              marginBottom="0px"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
             
              <span className="primary-text-gradient" style={{ fontSize: '14px', textAlign: 'center' }}>USD</span>
            </Typography>
            <TextInput
              size="md"
              name="amount"
              type="number"
              value={withdrawAmount.toString()}
              placeholder="Amount"
              onChange={(v) => {
            const value = parseFloat(v);
            if (value > balance) {
              setWithdrawAmount(balance);
              toast.error('You do not have enough balance');
              return;
            }
            
            if (value < minAmount) {
              setWithdrawAmount(minAmount);
              toast.error('You must redeem at least 1 USD');
              return;
            }
            setWithdrawAmount(parseFloat(v));
          }}
            />
         
          </div>
  
        </Container>
     
        <Grid
          container
          size={12}
          gap={3}
          style={{
        maxWidth: '500px',
        margin: '0 auto',
        marginTop: '30px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
        >
          <div style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1, 
        maxWidth: '180px', 
        alignItems: 'center',
        }}
          >
            {simulatorRedeemMGTokens.isPending && <div style={{ marginBottom: '10px' }}><ComponentLoader /></div> }
            <Button
              size="md"
              style={{ width: '100%' }}
              disabled={simulatorRedeemMGTokens.isPending}
              onClick={async () => {
            setConfirmRedeem(true);
           }}
            >
              {t('Simulate')}
            </Button>
            <Button
              size="md"
              text
              style={{ width: '100%' }}
              disabled={simulatorRedeemMGTokens.isPending}
              onClick={async () => {
            handleClose();
           }}
            >
              {t('Cancel')}
            </Button>
          </div>
     
        </Grid>
        <Modal
          width="500px"
          title="Swap In for MG Tokens"
          open={confirmRedeem}
          handleClose={handlerConfirmRedeem}
        >
          
          <Typography
            fontSize="20px"
            fontWeight={500}
            style={{ marginTop: '-20px', marginBottom: '40px' }}
          >
            Are you sure you want to Swap In from your Ambassador Wallet? 
          </Typography>
          <Typography
            fontSize="18px"
            fontWeight={500}
            style={{ marginTop: '-20px', marginBottom: '20px' }}
          >
            Transaction : <span className="primary-text-gradient">{`$${renderNumber(withdrawAmount)}`}</span>  for <span className="primary-text-gradient">{`${renderNumber(Math.floor(convertUSDToMgTokens({ mgTokensAmount: withdrawAmount, mgToken })))} ${coin}`}</span>
          </Typography>
          
          <Typography
            fontSize="18px"
            fontWeight={500}
            style={{ marginTop: '0px', marginBottom: '20px' }}
          >
            Transaction Fee: <span className="primary-text-gradient">{`${fee} MG Tokens `}</span> 
          </Typography>

          {/* <Typography
            fontSize="18px"
            fontWeight={500}
            style={{ marginTop: '0px', marginBottom: '20px' }}
          >
            Receiving: <span className="primary-text-gradient">{`${renderNumber(Math.floor(convertUSDToMgTokens({ mgTokensAmount: withdrawAmount, mgToken }) - fee))} ${coin}`}</span>
          </Typography> */}
          
          <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '10px',
        justifyContent: 'flex-end',
        }}
          >
      
            <Button
              size="md"
              text
              style={{ width: '100%' }}
              type="button"
              onClick={async () => {
            setConfirmRedeem(false);
           }}
            >
           
              <span style={{ whiteSpace: 'nowrap' }}>{t('No, Cancel')}</span>
            </Button>
            <Button
              size="md"
              style={{ width: '100%' }}
              type="button"
              onClick={async () => {
             setConfirmRedeem(false);
              simulatorRedeemMGTokens.mutate({
                mgTokens: totalCoins,
                amount: withdrawAmount,
                price,
                fee,
              
              });
           }}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{t('Yes, Simulate')}</span>
            </Button>
          </div>
        </Modal>
      </div>
)}
     
   </Modal>
 );
}
