import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import MemeGamerChallengePage from '../MemeGamerChallenge';

const MemeGamerChallengePublic = () => {
  const navigate = useNavigate();

  const onSignIn = () => {
    navigate('/signin');
  };

  return (
    <div className="dashboard memegamer-rules">
      <div className="nav-container">
        <div className="img-container">
          <img src="/assets/MemeGames-logo-sol.png" alt="memegames-logo" />
        </div>

        <div className="button-container flex-1 flex">
          <Button
            onClick={onSignIn}
            bordered
            icon={<Icon name="UserIcon" width={17} height={17} />}
            size="sm"
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '500',
                alignSelf: 'center',
              }}
            >
              Login
            </Typography>
          </Button>
        </div>
      </div>

      <Grid
        container
      >
        <MemeGamerChallengePage />
      </Grid>

      <footer className="footer-container flex flex-column">
        <div className="flex justify-center">
          <img src="/images/white-logo.png" alt="white-logo" />
        </div>
        <div className="flex justify-center">
          <span>
            ©
            {dayjs().tz('GMT').toDate().getFullYear()}
            {' '}
            MemeGames.ai. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default MemeGamerChallengePublic;
