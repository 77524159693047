/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TreeNode as TreeItem } from 'react-organizational-chart';
import dayjs from 'dayjs';
import Tag from '../../../../../../components/Tag';
import UserNode from './user-node';

// const teamcolors = [
//   'brown',
//   'red',
//   'purple',
// ];

const TreeNode = ({
  currentUser,
  user,
  passTypes,
  team,
  onUserNodeClick,
  active = false,
  expiration,
  played,
  monetized,
}) => {
  // let tagColor = active ? 'gold' : teamcolors[team - 1];

  // if (user.rankName === 'Ambassador' && user.active === 'active') {
  //   tagColor = 'green';
  // }

  let tagColor = 'white';

  if (played && !active) {
    tagColor = 'blue';
  }

  if (played && !monetized) {
    tagColor = 'yellow';
  }

  if (played && active) {
    tagColor = 'green';
  }

  if (dayjs(expiration).tz('GMT').toDate() < dayjs().tz('GMT').toDate() && played) {
    tagColor = 'red';
  }

  return (
    <TreeItem
      sx={{ cursor: 'Pointer' }}
      label={(
        <div className="flex justify-center">
          <Tag
            onClick={() => onUserNodeClick(user)}
            color={tagColor}
            user={user}
          >
            <UserNode user={user} currentUser={currentUser} />
          </Tag>
        </div>
      )}
    >
      {user?.children && user?.children?.length > 0 && (
        user.children.map((child, i) => (
          <TreeNode
            passTypes={passTypes}
            key={`nested-node-${i}`}
            user={child}
            team={team}
            onUserNodeClick={onUserNodeClick}
            active={child.active}
            currentUser={currentUser}
            expiration={child.dateExpiring}
            played={child.played}
            monetized={child.monetized}
          />
        ))
      )}
    </TreeItem>
  );
};

export default TreeNode;
