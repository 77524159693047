import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function MoneyInput({
  name = '',
  className,
  value = '',
  focused = false,
  placeholder = '',
  size = 'md',
  endAdornment = '',
  startAdornment = '',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
  allowDecimals = false,
}) {
  return (
    <div className="form-input-group">
      <div
        className={clsx(
          'form-input money-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
        )}
      >
        {startAdornment}
        <span className="currency-symbol">$</span>
        <input
          id={name}
          type="text"
          value={value || '0'}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          className={clsx(focused && 'focused', size, disabled && 'disabled', error && 'error')}
          onChange={(e) => {
            if (!allowDecimals) {
              const val = e.target.value ? Number(e.target.value.match(/\d/g)?.join('')) : '';
              if (!Number.isNaN(val)) onChange(val);
              return;
            }
            const val = e.target.value ? e.target.value.replace(/[^0-9.]/g, '') : '';

            if ((val.match(/\./g) || []).length > 1) {
              return;
            }

            if (val.endsWith('.') || val.endsWith('0')) {
              onChange(val);
              return;
            }

            onChange(Number(val));
          }}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
        />

        {endAdornment}
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

MoneyInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
