import {
  useCallback, useState, useEffect, useContext, 
} from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import { formValidationConfig } from './config';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import validatePassword from '../../../../utils/data/validatePassword';
import { OTPContext } from '../../../../components/2FA/provider';

export default function useChangePassword() {
  const { authPut } = useApi();
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const { checkUser } = useContext(AuthenticationContext);
  const { savePreFunction, setPreData, handleOpen } = useContext(OTPContext);

  const submitPassword = useCallback(async (data) => {
    try {
      const checkform = validateAll(data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
      } else if (!validatePassword(data.newPassword)) {
        setErrors({ newPassword: 'The Password must contain at least 9 characters, a capital letter, a number, and a special character!' });
      } else if (data.newPassword !== data.retypePassword) {
        setErrors({ retypePassword: 'Passwords must match!' });
      } else {
        await authPut('/profile/change-password', { data });
      
        toast.success('Password changed successfully!');
        setMessage({ type: 'success', label: 'Password changed successfully!' });
        setErrors();
        checkUser();
      }
    } catch (err) {
      console.log(err);
      if (err.otp_token && process.env.REACT_APP_2FA_ENABLED) {
        savePreFunction(() => submitPassword(data));
        setPreData(data);
        handleOpen(err);
        return;
      }
      const msg = err?.response?.data?.message || typeof err === 'string' ? err : '';
      setMessage({ type: 'error', label: msg || 'Something went wrong. Please try again later!' });
      setErrors();
    }
  }, [process.env.REACT_APP_2FA_ENABLED, savePreFunction, setPreData, handleOpen]);

  useEffect(() => {
    setTimeout(() => {
      setMessage();
    }, 5000);
  }, [message]);

  return {
    submitPassword,
    errors,
    message,
  };
}
