import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HelioCheckout } from '@heliofi/checkout-react';
import Modal from '../../../../../components/Modal';
import useSolanaPay from '../../../../../utils/hooks/useSolanaPay';
import ApplyPassToggle from '../../../../../components/Inputs/ApplyPassToggle';
import { renderNumber } from '../../../../../utils/string';

export default function PurchasePassModal({
  data, handleModalClose, returnHook = '/payments/passes/ipn/', renderName = (_data) => `${_data.units} ${_data.passType.name}`,

}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processingApply, setProcessingApply] = useState(false);
  const buyPassesIpn = `${process.env.REACT_APP_BASE_URL}${returnHook}${data.PUID}`;
  const serverIpn = data?.serverIpn || buyPassesIpn;
  const helioCCEnabled = process.env.REACT_APP_SOLANA_PAY_CREDIT_CARD === 'true';

  const buyFreePasses = () => {
    setLoading(true);
    handleModalClose(false);

    if (data?.fromSignup) {
      navigate('/thank-you');
      sessionStorage.setItem('paidSignupUser', sessionStorage.getItem('signupUser'));
      sessionStorage.removeItem('signupUser');
    }
  };

  useEffect(() => {
    setLoading(processingApply);
  }, [processingApply]);

  const PayWithCryptoConfig = useSolanaPay({
    data,
    handleModalClose,
    renewMembership: data?.fromSignup,
    secondHalfPayment: data?.secondHalfPayment,
  });

  const PayWithCardConfig = useSolanaPay({
    data,
    handleModalClose,
    renewMembership: data?.fromSignup,
    payWithCard: true,
    secondHalfPayment: data?.secondHalfPayment,
  });

  const applyImmediately = data?.passType?.activateImmediately || false;
  let btcIsEnabled = process.env.REACT_APP_BTC_ENABLED === 'true'; // this will take precendence over the passType setting
  const packPurchaseWith = data?.passType?.purchaseWith || 'all';

  if (packPurchaseWith === 'btc') {
    PayWithCardConfig.helioIsEnabled = false;
  } else if (packPurchaseWith === 'solana') {
    btcIsEnabled = false;
    PayWithCardConfig.helioIsEnabled = true;
  }

  return (
    <Modal
      title={data?.formTitle || !data?.secondHalfPayment ? 'Purchase coin pack confirmation!' : '50/50 payment required!'}
      width="550px"
      open={!!data}
      handleClose={handleModalClose}
    >
      <div style={{ position: 'relative' }}>
        {loading && (
          <div
            className="flex justify-center"
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              width: '100%',
              height: '100%',
              background: 'transparent',
              zIndex: 9999999999,
            }}
          >
            <CircularProgress />
          </div>
        )}

        <h1
          style={{ textAlign: 'center' }}
        >
          {`Pay in app platform cost: $${renderNumber(data.price)}!`}
        </h1>
        {data.priceTotal >= 1 ? (
          <>
            {/* <h3 style={{ textAlign: 'center' }}>{'This can\'t be reversed'}</h3> */}
            {data?.secondHalfPayment && (
              <h5 style={{ textAlign: 'center', marginTop: '1em' }}>
                {`This payment  is due before transfer or activation.
                ${renderNumber(data.priceTotal * 2)}
                MG Tokens will be awarded when this payment is completed AND when this pack is activated.`}
              </h5>
            )}

            {(!data?.fromSignup && !applyImmediately && !data?.secondHalfPayment) && (
              <ApplyPassToggle
                setIsProcessing={setProcessingApply}
                paymentId={data.id}
              />
            )}

            {btcIsEnabled && (
              <form
                className="form"
                method="POST"
                action={process.env.REACT_APP_BTC_PAY}
              >
                <input type="hidden" name="buyerName" value={data.name} />
                <input type="hidden" name="buyerEmail" value={data.email} />
                <input type="hidden" name="orderId" value={data.PUID} />
                <input
                  type="hidden"
                  name="storeId"
                  value={process.env.REACT_APP_BTC_STORE_ID}
                />
                <input
                  type="hidden"
                  name="checkoutDesc"
                  value={data.Description}
                />
                <input type="hidden" name="serverIpn" value={serverIpn} />
                <input
                  type="hidden"
                  name="browserRedirect"
                  value={`${data?.browserRedirect || `${process.env.REACT_APP_HOST}/packages`}`}
                />
                <input type="hidden" name="price" value={data.priceTotal} />
                <input type="hidden" name="currency" value="USD" />
                <button
                  style={{ alignSelf: 'center', border: 'none' }}
                  size="md"
                  className="btn md default normal"
                  type="submit"
                  name="purchasePass"
                >
                  Purchase with BTC
                </button>
              </form>
            )}
            {PayWithCardConfig.helioIsEnabled && (
              <>
                {helioCCEnabled && (
                  <div className="solana-button">
                    <HelioCheckout config={PayWithCardConfig.helioConfig} />
                  </div>
                )}
                <div className="solana-button">
                  <HelioCheckout config={PayWithCryptoConfig.helioConfig} />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="form">
            <button
              style={{
                alignSelf: 'center',
                border: 'none',
                opacity: `${loading ? 0.6 : 1}`,
                cursor: `${loading ? 'default' : 'pointer'}`,
              }}
              size="md"
              className="btn md default normal"
              type="submit"
              name="purchasePass"
              onClick={buyFreePasses}
              disabled={loading}
            >
              {`Purchase ($${data.price})`}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
