import { Container, Grid, Typography } from '@mui/material';
import Coin from './Coin';
import useMemeCoins from './hook';
import CoinModal from './CoinModal';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import AppLoader from '../../components/AppLoader';

export default function MemeCoins() {
  const {
    coins,
    loading,
    onClose,
    onSubmit,
    onAddClick,
    loadingForm,
    onEditClick,
    selectedCoin,
    onDelete,
    deleteId,
    cancelDelete,
    onDeleteSubmit,
  } = useMemeCoins();

  if (loading) {
    return <AppLoader />;
  }

  return (
    <Grid container columnSpacing={3} sx={{ width: '100%' }}>
      <Grid
        xl={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: 'flex', justifyContent: 'end', pt: 3, mb: 3, 
        }}
        item
      >
        <Button size="sm" onClick={onAddClick}>
          Add +
        </Button>
      </Grid>

      {coins.map((item) => (
        <Grid key={`coin${item.id}`} item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Coin onDelete={onDelete} data={item} onEdit={() => onEditClick(item)} />
        </Grid>
      ))}

      <Modal open={!!selectedCoin} handleClose={onClose} title="Add Coin">
        <CoinModal loadingForm={loadingForm} value={selectedCoin} onSubmit={onSubmit} />
      </Modal>
      
      <Modal width="" open={!!deleteId} handleClose={cancelDelete} title="Delete Coin">
        
        <Grid container columnSpacing={1}>
          <Grid item xl={12} lg={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography>
              {' '}
              Are you sure you want to delete
              <span style={{
                color: 'var(--primary-color)', textTransform: 'uppercase', marginLeft: '3px', marginRight: '3px',
              }}
              >
              
                {deleteId?.name}
              </span> 
              ?
            </Typography>
          </Grid>
          
          <Grid item xl={6} lg={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button onClick={() => onDeleteSubmit()} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Delete</Button>
          </Grid>
          
          <Grid item xl={6} lg={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button style={{ display: 'flex', width: '100%', justifyContent: 'center' }} bordered>Cancel</Button>

          </Grid>
          
        </Grid>
       
      </Modal>
    </Grid>
  );
}
