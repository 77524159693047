import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { columnsConfig } from './config';
import Table from '../../../../../../components/Table';
import Select from '../../../../../../components/Inputs/Select';
import MembershipsTab from '../../../../../Memberships/components/MembershipsTab';
import TablePagination from '../../../../../../components/Table/Pagination';
import TableMobile from '../../../../../../components/Table/TableMobile';
import { usePurchases } from '../../../context/hooks';

const PAGE_SIZE = 10;
export default function PurchaseHistory({
  tabsHistoryValue,
  handleTabHistoryChange = () => { },
}) {
  const { t } = useTranslation();
  const { userId } = useParams();
  
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('all');
  const purchases = usePurchases({
    userId, page: currentPage, status, perPage: PAGE_SIZE, 
  });
  const columns = useMemo(() => columnsConfig(t), [t]);

  const tabsConfig = [
    {
      label: 'Transfer',
      key: 'transfer',
      isActive: tabsHistoryValue === 0,
      onClick: () => handleTabHistoryChange(0),
    },
    {
      label: 'Purchases',
      key: 'purchases',
      isActive: tabsHistoryValue === 1,
      onClick: () => handleTabHistoryChange(1),
    },
  ];

  return (
    <>
      <div className="flex packs-filters">
        <div className="flex">
          <Select
            className="transfers-filter-select"
            placeholder="Status"
            size="sm"
            value={status || 'default'}
            onChange={(e) => setStatus(e)}
            options={[
              { value: 'all', label: 'All' },
              { value: 'new', label: 'New' },
              { value: 'confirmed', label: 'Confirmed' },
              { value: 'processing', label: 'Processing' },
              { value: 'expired', label: 'Expired' },
            ]}
          />
        </div>

        <div className="flex flex-1 justify-center">
          <div className="flex flex-column justify-center">
            <MembershipsTab config={tabsConfig} />
          </div>
        </div>
        {purchases.data ? (
          <div className="desktop">
            <TablePagination
              count={Math.ceil(purchases.data.total / PAGE_SIZE)}
              page={currentPage}
              onChange={(val, page) => {
                setCurrentPage({ page });
              }}
            />
          </div>
        ) : null}
        
      </div>

      <div className="table-cont">
        {purchases.isFetching ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : purchases.data ? (
          <>
            <div className="desktop">
              <Table getRowId={(row) => row.PUID} loading={purchases.isFetching} columns={columns} rows={purchases.data.data} className="table mb-4" />
            </div>
            <div className="mobile">
              <TableMobile columns={columns} data={purchases.data.data} className="mb-6" />
              <TablePagination
                isMobile
                count={Math.ceil(purchases.data.total / PAGE_SIZE)}
                page={currentPage}
                onChange={(val, page) => {
                  setCurrentPage({ page });
                }}
              />
            </div>
          </>
        ) : null()}
      </div>
    </>
  );
}
