import dayjs from 'dayjs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Tooltip } from '@mui/material';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultRedeemColumnsConfig = (
  t,
  setRedeemDestinationData,
  setRedemptionCancelDataModal,
) => [
  {
    field: 'name',
    headerName: t('User'),
    flex: 1,
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => `${row.name} ${row.surname} (${row.userId}) - ${row.username}`,
  },
  {
    field: 'memecoin',
    headerName: t('Meme Coin'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ value }) => value.name,
  },
  {
    field: 'createdAt',
    headerName: t('Redeem Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'solanaWalletKey',
    headerName: t('Perform Payment'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <div className="flex" style={{ gap: 10 }}>
        <Tooltip placement="top" title="Perform Payment">
          <PriceCheckIcon
            sx={{ fontSize: '32px', color: 'var(--primary-color)', cursor: 'pointer' }}
            onClick={() => setRedeemDestinationData({
              redeemId: row.id,
              destination: row.solanaWalletKey,
              user: `${row.name} ${row.surname} (${row.userId}) - ${row.username}`,
              memecoinCurrentPrice: row.memecoinCurrentPrice,
              history: row.history,
              amountMgTokens: row.amountMgTokens,
              memecoin: row.memecoin,
            })}
          />
        </Tooltip>
        <Tooltip placement="top" title="Cancel">
          <DeleteForeverIcon
            sx={{ fontSize: '32px', color: '#e83c3c', cursor: 'pointer' }}
            onClick={() => setRedemptionCancelDataModal({
              redeemId: row.id,
              destination: row.solanaWalletKey,
              user: `${row.name} ${row.surname} (${row.userId}) - ${row.username}`,
              amountMgTokens: row.amountMgTokens,
              memecoin: row.memecoin,
            })}
          />
        </Tooltip>
      </div>
    ),
  },
];
