import OtpInput from 'react-otp-input';
import { Typography } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import Modal from '../Modal';
import Button from '../Button';
import { OTPContext, useOTP } from './provider';

export default function FA2Modal() {
  const {
    open, handleClose, otp, setOtp, submit, phoneNumber,
  } = useOTP();

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);
  return (
    <Modal width="40%" titleStyle={{ color: 'white' }} background="black" open={open} handleClose={() => handleClose()} title="2 Factory Authentication">

      <div className="">
        <Typography style={{ color: 'white' }} className="mx-auto flex justify-center mb-5 text-[13px] text-white">
          Code sent to 
          {' '}
          {phoneNumber}
          .
          {' '}
        </Typography>
        <Typography style={{ color: 'white' }} className="mx-auto flex justify-center mb-5 text-[13px] text-white">
          Please check your phone for the 6-digit code
        </Typography>
        <div className="flex justify-center items-center mb-5 mt-5">
          <OtpInput
            ref={inputRef}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
            inputStyle={{
              height: '50px', width: '50px', fontSize: '20px', borderRadius: '10px', border: '1px solid #E5E5E5', textAlign: 'center', 
            }}
           
          />
        </div>

        <Button
          onClick={() => {
            submit();
          }}
          style={{ width: 'fit-content' }}
          className="w-fit max-w-fit mb-5 flex justify-center mx-auto" 
          title="Done"
        >
          Done 
        </Button>
      </div>
    </Modal>
  );
}
