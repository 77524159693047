import { createContext } from 'react';
import useAdminTaxApi from './api';

export const AdminTaxContext = createContext({
  t: () => {},
  user: {},

  taxFormsData: [],
  taxFormsProcessingData: [],
  taxFormsCompletedData: [],
  taxFormsDataLoaded: false,
  taxFormsColumnsConfig: [],
  processingTaxFormsColumnsConfiguration: [],
  completedTaxFormsColumnsConfiguration: [],
  canWithdrawCount: 0,

  taxFormReviewData: null,
  handleReviewModalSubmit: () => {},
  handleReviewModalClose: () => {},

  taxFormDeleteData: null,
  handleDeleteModalSubmit: () => {},
  handleDeleteModalClose: () => {},

  openCompleteAllUsersModal: false,
  handleCompleteAllUsersModalOpen: () => {},
  handleCompleteAllUsersModalSubmit: () => {},
  handleCompleteAllUsersModalClose: () => {},
});

export const AdminTaxProvider = ({ children }) => {
  const value = useAdminTaxApi();
  return <AdminTaxContext.Provider value={value}>{ children }</AdminTaxContext.Provider>;
};
