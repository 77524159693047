/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import CoinComponent from './CoinComponent';
import { renderNumber } from '../../utils/string';
import RedeemButton from '../RedeemButton';
import Button from '../Button';
// import useApi from '../../utils/api';

const GameMatch = ({ game, userTokens, support }) => {
  const launchDate = dayjs(process.env.REACT_APP_REDEMPTION_START_DATE).tz('GMT');
  const gameEndDate = dayjs(game.endDate).tz('GMT');

  const hideRedeemButton = launchDate >= gameEndDate;

  const locked = game?.locked;
  const myPercentage = locked ? game?.lockChange || 0 : game?.change || 0;

  const [activeMatchIndex, setActiveMatchIndex] = useState(0);
  const [activeMatch, setActiveMatch] = useState(null);
  const [highestPercentage, setHighestPercentage] = useState(myPercentage);
  const [tokenReceived, setTokenReceived] = useState(0);
  const [leaderboardRank, setLeaderboardRank] = useState(0);
  const [coinsOrTokens, setCoinsOrTokens] = useState(null);
  const [coin1Winner, setCoin1Winner] = useState(null);
  const [coin2Winner, setCoin2Winner] = useState(null);
  const [coin1Picked, setCoin1Picked] = useState(null);
  const [coin2Picked, setCoin2Picked] = useState(null);
  const [coin1CanRedeem, setCoin1CanRedeem] = useState(null);
  const [coin2CanRedeem, setCoin2CanRedeem] = useState(null);
  // const { authGet } = useApi();

  const handleNextMatchClick = () => {
    if (activeMatchIndex < (game?.votes?.length || 1) - 1) {
      setActiveMatchIndex(activeMatchIndex + 1);
    }
  };

  const handlePrevMatchClick = () => {
    if (activeMatchIndex > 0) {
      setActiveMatchIndex(activeMatchIndex - 1);
    }
  };

  useEffect(() => {
    if (game.votes?.length > 0) {
      const vote = game.votes[activeMatchIndex];
      setActiveMatch(vote);
      setLeaderboardRank(vote?.rankigPercentage || vote?.stats?.rank || 0);
      setCoinsOrTokens(vote.loss || vote.draw ? 'Coins Refunded:' : 'Tokens Received:');
      setTokenReceived((vote?.loss === true || vote?.draw === true) ? vote.coins : vote.token);
      const _coin1Winner = game.coin1 === game.winningCoin;
      const _coin2Winner = game.coin2 === game.winningCoin;
      const _coin1Picked = game.coin1 === vote.memeCoinsId;
      const _coin2Picked = game.coin2 === vote.memeCoinsId;
      const _coin1CanRedeem = _coin1Winner && _coin1Picked;
      const _coin2CanRedeem = _coin2Winner && _coin2Picked;
      setCoin1Winner(_coin1Winner);
      setCoin2Winner(_coin2Winner);
      setCoin1Picked(_coin1Picked);
      setCoin2Picked(_coin2Picked);
      setCoin1CanRedeem(_coin1CanRedeem);
      setCoin2CanRedeem(_coin2CanRedeem);
    }
  }, [activeMatchIndex, leaderboardRank, coinsOrTokens, tokenReceived, coin1Winner, coin2Winner, coin1Picked, coin2Picked, coin1CanRedeem, coin2CanRedeem]);

  useEffect(() => {
    if (game.votes?.length > 0) {
      setHighestPercentage(game?.leader?.change || 0);
    }
  }, []);

  return (
    <Grid
      maxWidth="100%"
      size="xsm"
      item
      sx={{
        mb: { lg: '8px' },
        padding: { xs: 2, lg: 4 },
      }}
      className="game-container"
      display="flex"
      flexDirection="column"
    >
      <Grid
        maxWidth="100%"
        size="xsm"
        className="game-container-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0"
      >
        <div className="flex" style={{ gap: 16 }}>
          <div className="controls">
            <Button
              type="button"
              size="sm"
              bordered={activeMatchIndex === 0}
              disabled={activeMatchIndex === 0}
              onClick={handlePrevMatchClick}
            >
              <ChevronLeft fontSize="medium" />
            </Button>
            <Button
              type="button"
              size="sm"
              bordered={activeMatchIndex >= (game?.votes?.length || 1) - 1}
              disabled={activeMatchIndex >= (game?.votes?.length || 1) - 1}
              onClick={handleNextMatchClick}
            >
              <ChevronRight fontSize="medium" />
            </Button>
          </div>
          <Typography
            sx={{
              fontSize: { xs: '14px', lg: '16px' },
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'left',
            }}
          >
            <span>
              {renderNumber(game?.price)}
              {' Coin Game'}
            </span>
            <span>
              {'Play '}
              {activeMatchIndex + 1}
              {' out of '}
              {renderNumber(game?.votes?.length)}
            </span>
          </Typography>
        </div>

        <div className="info">
          {activeMatch?.loss ? (
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-number"
            >
              <span className="text-red">GAME LOST</span>
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: { xs: '12px' }, fontWeight: '600' }}
              className="text-number"
            >
              <span>Leaderboard Rank:</span>
              <span className="text-sol">
                {`TOP ${parseInt(leaderboardRank, 10)}%`}
              </span>
            </Typography>
          )}
          <Typography sx={{ fontSize: { xs: '10px' } }} className="text-number">
            <span style={{ maxWidth: '60px' }}>
              {coinsOrTokens}
            </span>
            <span className="text-sol">{renderNumber(tokenReceived)}</span>
          </Typography>
          {/* {(coin1CanRedeem || coin2CanRedeem) && (
            <RedeemButton
              totalTokens={userTokens}
              gameId={game.id}
              coin={
                coin1CanRedeem ? game.coinOne : game.coinTwo
              }
              buttonText={userTokens > 2000 ? 'Redeem 2,000' : null}
              bordered
            />
          )} */}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="divider" />
      </Grid>
      <div className="game-container-body">
        <div className={`games${hideRedeemButton ? ' hide-redeem' : ''}`}>
          <CoinComponent
            coin={game.coinOne}
            coinData={game.coin1Data}
            isWinner={coin1Winner}
            myPick={coin1Picked}
            tokens={userTokens}
            gameId={game.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
            coinsOrTokens={coinsOrTokens}
            percentageChange={highestPercentage.toFixed(2)}
            gamePercentageChange={activeMatch?.stats?.change?.toFixed(2) || 0}
          />
          <CoinComponent
            coin={game.coinTwo}
            coinData={game.coin2Data}
            isWinner={coin2Winner}
            myPick={coin2Picked}
            tokens={userTokens}
            gameId={game.id}
            leaderboardRank={leaderboardRank}
            tokenReceived={tokenReceived}
            coinsOrTokens={coinsOrTokens}
            percentageChange={highestPercentage.toFixed(2)}
            gamePercentageChange={activeMatch?.stats?.change?.toFixed(2) || 0}
          />
        </div>
      </div>
      <Grid item xs={12} mt={1}>
        <div className="divider" />
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', lg: '16px' },
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {'Pick Time: '}
          </span>
          <span>
            {activeMatch && dayjs(activeMatch.createdAt).tz('GMT').toString('lll')}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', lg: '16px' },
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {'Game Start: '}
          </span>
          <span>
            {dayjs(game.startDate).tz('GMT').toString('lll')}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', lg: '16px' },
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {'Game End: '}
          </span>
          <span>
            {dayjs(game.endDate).tz('GMT').toString('lll')}
          </span>
        </Typography>

      </Grid>
      {support ? (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            paddingTop: 2,
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', lg: '16px' },
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {'Game ID: '}
            </span>
            <span>
              {game.id}
            </span>
          </Typography>
        </Grid>
      ) : null}

    </Grid>
  );
};

export default GameMatch;
