import {
  useCallback, useContext, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import {
  ambassadorTransactionFormValidationConfig, gamerTransactionFormValidationConfig, getAmbassadorColumnsConfig, getGamerColumnsConfig, 
} from '../config';
import { validateAll } from '../../../../components/Form/config';

const useAdminTransactionsHook = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const {
    authGet, authPost, authPut,
  } = useApi();

  const [openModal, setOpenModal] = useState(false);
  const [transactionsData, setTransactionsData] = useState([]);
  const [transactionsDataLoading, setTransactionsDataLoading] = useState(false);
  const [ambassadorTransactionFormErrors, setAmbassadorTransactionFormErrors] = useState(null);
  const [ambassadorTransactionFormLoading, setAmbassadorTransactionFormLoading] = useState(false);

  const [gamerTransactionFormErrors, setGamerTransactionFormErrors] = useState(null);
  const [gamerTransactionFormLoading, setGamerTransactionFormLoading] = useState(false);

  const [reverseTransactionData, setReverseTransactionData] = useState(null);
  
  // Filters
  const [selectedUser, setSelectedUser] = useState(null);
  const [walletTypeFilter, setWalletTypeFilter] = useState('ambassador');
  const [directionFilter, setDirectionFilter] = useState('default');
  const [latestFirstFilter, setLatestFirstFilter] = useState(true);

  const [walletsData, setWalletsData] = useState(null);

  const handleReverseButtonClick = useCallback(
    async (data) => {
      setReverseTransactionData(data);
    },
    [setReverseTransactionData],
  );

  const ambassadorColumnsConfiguration = useMemo(() => getAmbassadorColumnsConfig(t, handleReverseButtonClick), [t, handleReverseButtonClick]);
  const gamerColumnsConfiguration = useMemo(() => getGamerColumnsConfig(t, handleReverseButtonClick), [t, handleReverseButtonClick]);

  const [selectedColumnsConfiguration, setSelectedColumnsConfiguration] = useState(ambassadorColumnsConfiguration);

  const filterTransactions = useCallback(
    async () => {
      setTransactionsDataLoading(true);

      const response = await authGet(`/admin-transaction/${selectedUser.value}`, {
        params: {
          walletType: walletTypeFilter,
          direction: directionFilter !== 'default' ? directionFilter : null,
          latestFirst: latestFirstFilter,
        },
      });

      setTransactionsData(response.data || []);

      setWalletsData(response.wallets || null);

      setSelectedColumnsConfiguration(walletTypeFilter === 'ambassador' ? ambassadorColumnsConfiguration : gamerColumnsConfiguration);

      setTransactionsDataLoading(false);
    },
    [authGet, selectedUser, walletTypeFilter, directionFilter, latestFirstFilter],
  );

  // Ambassador wallet transactions
  const handleAmbassadorTransactionFormSubmit = useCallback(
    async (_data) => {
      try {
        setAmbassadorTransactionFormErrors(null);
        setAmbassadorTransactionFormLoading(true);

        if (_data.amount === 0) {
          delete _data.amount;
        }

        if (_data.amount && typeof _data.amount !== 'number') {
          _data.amount = parseFloat(_data.amount);
        }

        const checkForm = validateAll(_data, ambassadorTransactionFormValidationConfig);

        if (checkForm) {
          _data.amount = _data.amount ? _data.amount : 0;
          setAmbassadorTransactionFormErrors(checkForm);
          return;
        }

        const data = {
          ..._data,
          userId: selectedUser.value,
        };

        await authPost('/admin-transaction/ambassador', { data });

        setOpenModal(false);
        setAmbassadorTransactionFormLoading(false);

        filterTransactions();

        toast.success('The transaction has been completed successfully.');
      } catch (err) {
        console.log(err);
        toast.error('Error adding transaction.');
      } finally {
        setAmbassadorTransactionFormLoading(false);
      }
    },
    [authPost, filterTransactions, selectedUser],
  );

  // Gamer wallet transactions
  const handleGamerTransactionFormSubmit = useCallback(
    async (_data) => {
      try {
        setGamerTransactionFormErrors(null);
        setGamerTransactionFormLoading(true);

        if (_data.amount === 0) {
          delete _data.amount;
        }

        if (_data.amount && typeof _data.amount !== 'number') {
          _data.amount = parseFloat(_data.amount);
        }

        const checkForm = validateAll(_data, gamerTransactionFormValidationConfig);

        if (checkForm) {
          _data.amount = _data.amount ? _data.amount : 0;
          setGamerTransactionFormErrors(checkForm);
          return;
        }

        const data = {
          ..._data,
          userId: selectedUser.value,
        };

        await authPost('/admin-transaction/gamer', { data });

        setOpenModal(false);

        filterTransactions();

        toast.success('The transaction has been completed successfully.');
      } catch (err) {
        console.log(err);
        toast.error('Error adding transaction.');
      } finally {
        setGamerTransactionFormLoading(false);
      }
    },
    [authPost, filterTransactions, selectedUser],
  );

  const handleReverseTransactionModalSubmit = useCallback(
    async (transactionId) => {
      try {
        await authPut(`/admin-transaction/${transactionId}`);
        setReverseTransactionData(null);

        filterTransactions();

        toast.success('Transaction reversed successfully');
      } catch (err) {
        toast.error('Error trying to reverse the transaction.');
      }
    },
    [setReverseTransactionData, filterTransactions],
  );

  return {
    t,
    user,
    filterTransactions,
    transactionsDataLoading,
    transactionsData,
    setTransactionsData,

    openModal,
    setOpenModal,

    handleAmbassadorTransactionFormSubmit,
    ambassadorTransactionFormLoading,
    ambassadorTransactionFormErrors,
    setAmbassadorTransactionFormErrors,

    handleGamerTransactionFormSubmit,
    gamerTransactionFormLoading,
    gamerTransactionFormErrors,
    setGamerTransactionFormErrors,

    handleReverseTransactionModalSubmit,
    reverseTransactionData,
    setReverseTransactionData,

    selectedUser,
    setSelectedUser,
    walletTypeFilter,
    setWalletTypeFilter,
    directionFilter,
    setDirectionFilter,
    latestFirstFilter,
    setLatestFirstFilter,

    selectedColumnsConfiguration,

    walletsData,
    setWalletsData,
  };
};

export default useAdminTransactionsHook;
