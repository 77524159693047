import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/Inputs/TextInput';
import Modal from '../../../../components/Modal';
import { PendingRedemptionsContext } from '../context';
import { renderNumber } from '../../../../utils/string';

const PayRedemptionModal = () => {
  const [memeCoinsAmount, setMemeCoinsAmount] = useState('');
  const { t } = useTranslation();
  const {
    redeemDestinationData,
    submittingRedeemModal,
    handlePayRedeemSubmitModal,
    handlePayRedeemCloseModal,
  } = useContext(PendingRedemptionsContext);

  useEffect(() => {
    setMemeCoinsAmount('');
  }, [redeemDestinationData]);

  useEffect(() => {
    if (memeCoinsAmount === 0) {
      setMemeCoinsAmount('');
    }
  }, [memeCoinsAmount]);

  return (
    <Modal
      title="Mark as Paid"
      width="800px"
      open={!!redeemDestinationData}
      handleClose={handlePayRedeemCloseModal}
    >
      
      <h2 style={{
        marginTop: '-20px', textAlign: 'center', paddingBottom: '10px', paddingTop: 0, fontWeight: 'bold',
      }}
      >
        <span className="primary-text-gradient" style={{ fontSize: '18px', fontWeight: 'bold' }}>{redeemDestinationData?.user}</span>
      </h2>
      <div style={{ overflowX: 'auto', marginBottom: '20px' }}> 
        <table className="single_table borderless">
          <tbody>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Amount : </th>
              <td>
                
                {renderNumber(redeemDestinationData?.amountMgTokens)}
                {t(' MG Tokens')}
                
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Memecoin : </th>
              <td style={{ whiteSpace: 'nowrap' }}>
                {`${redeemDestinationData?.memecoin.name} - ${redeemDestinationData?.memecoinCurrentPrice}`}
              </td>
            </tr>
            {redeemDestinationData?.destination !== '' ? (
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Destination : </th>
                <td>
                  <Tooltip placement="top" title="Click to Copy">
                    <button
                      type="button"
                      className="text-center"
                      style={{
                        display: 'block',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontWeight: '600',
                      }}
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          redeemDestinationData?.destination,
                        );
                        toast.success('Wallet Key copied!');
                      }}
                    >
                      {redeemDestinationData?.destination}
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <TextInput
        type="number"
        value={memeCoinsAmount}
        onChange={(e) => {
          setMemeCoinsAmount(e);
        }}
        placeholder="Meme Coins Amount"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingRedeemModal && !!memeCoinsAmount) {
            handlePayRedeemSubmitModal(memeCoinsAmount);
          }
        }}
        className="mb-5"
        disabled={submittingRedeemModal || !memeCoinsAmount}
      >
        <span className="no-wrap">Paid</span>
      </Button>
      {redeemDestinationData?.history.length > 0 ? (
        <>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '26px',
              fontWeight: 600,
              mt: 1,
              width: '100%',
              marginBottom: '10px',
            }}
          >
            {t('History')}
          </Typography>
          <div style={{ overflowX: 'auto' }}> 
            <table className="single_table">
              <thead>
                <tr>
                 
                  <th>{t('Memecoin')}</th>
                  <th>{t('Date')}</th>
                  <th>{t('Destination')}</th>
                </tr>
              </thead>
              <tbody>
                {redeemDestinationData?.history.map((item) => (
                  <tr key={item.id}>
                    
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {`${item?.memecoin.name} - ${item?.memecoinCurrentPrice}`}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>{dayjs(item.createdAt).tz('GMT').format('MMM DD, YYYY')}</td>
                    <td>{item.solanaWalletKey}</td>
                  </tr>
                ))}
              </tbody>
        
            </table>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default PayRedemptionModal;
