import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { Context } from '../context';
import Button from '../../../components/Button';
import ComponentLoader from '../../../components/ComponentLoader';
import Layout from '../../../components/CardLayout';

const ActiveChallengePackComponent = () => {
  const {
    userChallengePack,
    userChallengePackLoaded,
    fetchChallengePack,
  } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChallengePack();
  }, []);

  return userChallengePackLoaded ? (
    <Layout maxWidth="100%">
      {userChallengePack ? (
        <Typography>
          <span>Your Challenge is active! Time remaining on your </span>
          <span className="primary-text-gradient">{userChallengePack.Type}</span>
          <span>{`: ${dayjs(userChallengePack.expires).diff(dayjs().tz('GMT'), 'day')} days`}</span>
        </Typography>
      ) : (
        <div
          className="flex"
          style={{
            alignItems: 'center', fontWeight: 500, gap: '16px', color: 'red', 
          }}
        >
          <span>
            You are not live in the challenge! In order to start or unpause your challenge
          </span>
          <Button size="sm" style={{ marginTop: 2 }} onClick={() => navigate('/packages')}>
            Purchase Challenge Packs
          </Button>
        </div>
      )}
    </Layout>
  ) : (
    <ComponentLoader />
  );
};

export default ActiveChallengePackComponent;
