import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import PassTypes from '../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Divider from '../../../../Authentication/PayNow/components/Divider';
import useApi from '../../../../../utils/api';
import PaymentRenewModal from './PaymentRenewModal';

export default function RenewMembershipModal({
  data,
  handleModalClose,
  selectedPackage,
  setSelectedPackage,
}) {
  const { user, availablePasses } = data;
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const { authPost } = useApi();
  const [buyFormData, setBuyFormData] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedPackageInfo, setSelectedPackageInfo] = useState({});

  const onRenew = async () => {
    setLoading(true);
    const token = availablePasses.find((p) => p.id === selectedPackage)?.firstAvailableToken;

    if (token) {
      try {
        const result = await authPost('/passes/consume', { data: { token } });
        if (result.success) {
          toast.success(t('You have successfully activated your membership!'));
          handleModalClose();
        }
      } catch (err) {
        console.log('[renew error]', err);
        toast.error(t('We cannot activate your membership right now, please try again later!'));
      }
    }
    setLoading(false);
  };

  const onBuy = async () => {
    setLoading(true);
    setBuyFormData({ user });
    setLoading(false);
  };

  const handleBuyModalClose = async () => {
    setBuyFormData(null);
  };

  const handleActivateNowClick = () => {
    const selected = availablePasses.find((p) => p.id === selectedPackage);
    setSelectedPackageInfo(selected);
    setOpenConfirmationModal(true);
  };

  return (
    <Modal
      title={t('Activate Membership!')}
      width="350px"
      open={!!data}
      handleClose={handleModalClose}
    >
      <Grid container spacing={2}>
        <PassTypes
          packages={availablePasses}
          loading={loading}
          formDescription="You have available Packs to activate your membership. Do you want to use one?"
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
        />
        <Grid container item style={{ marginTop: '20px' }}>
          <Button
            style={{ background: 'none' }}
            onClick={() => handleActivateNowClick()}
            bordered
            className="full-width"
            type="button"
            disabled={loading}
          >
            <span className="primary-text-gradient">{t('Activate Now')}</span>
          </Button>
        </Grid>
        <Divider />
        <BuyButton disabled={loading} onSubmitBitcoin={onBuy} />
      </Grid>

      {buyFormData && (
        <PaymentRenewModal
          open
          data={buyFormData}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          handleModalClose={handleBuyModalClose}
          location="profile"
        />
      )}

      {openConfirmationModal && (
        <Modal handleClose={() => setOpenConfirmationModal(false)} title="Confirmation Modal" width={500} open={openConfirmationModal}>
          <Box>
            <Typography sx={{ width: '100%', textAlign: 'center' }}>
              Are you sure you want to activate?
            </Typography>
            <Typography sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
              <span className="bold primary-color">
                {`${selectedPackageInfo?.name}`}
              </span>
            </Typography>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Button style={{ width: '100%' }} bordered onClick={() => setOpenConfirmationModal(false)}>
                No
              </Button>
              <Button style={{ width: '100%' }} onClick={() => { onRenew(); setOpenConfirmationModal(false); }}>
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

    </Modal>
  );
}
