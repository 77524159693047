import {
  Typography, Grid, CircularProgress, Pagination,
} from '@mui/material';
import {
  useState,
} from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import Select from '../../../../components/Inputs/Select';
import useScreenSize from '../../../../utils/hooks/useScreenSize';
import {
  ambassadorcolumnsConfig,
} from '../../../Wallets/config';
import { useHistory } from '../context/hooks';

export default function TransactionHistory() {
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const [filterTransactionType, setFilterTransactionType] = useState('default');
  const [filterDirection, setFilterDirection] = useState('default');
  const [historyPageSize, setHistoryPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { userId } = useParams();

  const history = useHistory(false, {
    userId,
    perPage: historyPageSize,
    page: currentPage,
    transactionType: filterTransactionType === 'default' ? null : filterTransactionType,
    direction: filterDirection === 'default' ? null : filterDirection,
  });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          marginBottom: '1.5rem',
          flexDirection: { xs: 'column', md: 'row' },
        }}
        className="wallet-transaction-filters"
      >
        <Grid item xs={12} md={6} sx={{ display: 'flex', gap: '1.5rem', flexDirection: { xs: 'column', md: 'row' } }}>
          <Select
            className="transactions-filter-select"
            placeholder="Any type"
            size="sm"
            value={filterTransactionType}
            onChange={(e) => setFilterTransactionType(e)}
            options={[
              { value: 'Received daily comm', label: 'Daily Pay' },
              { value: 'Match bonus for', label: 'Matching Bonus' },
              { value: 'Redeemed for ', label: 'Packs' },
              { value: 'Withdraw', label: 'Withdrawals' },
              // { value: 'withdraw fee', label: 'Purchase Fees' },
              // { value: 'purchase fee', label: 'Withdraw Fees' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
          <Select
            className="transactions-filter-select"
            placeholder="Any direction"
            size="sm"
            value={filterDirection}
            onChange={(e) => setFilterDirection(e)}
            options={[
              { value: 'receive', label: 'All Credits' },
              { value: 'deducted', label: 'All Debits' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex', gap: '1.5rem', justifyContent: 'flex-end', flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Select
            className="transactions-filter-select"
            placeholder="Pagination"
            size="sm"
            value={`${historyPageSize}`}
            onChange={(e) => { 
              setCurrentPage(1); 
              setHistoryPageSize(Number(e) > 0 ? Number(e) : 10); 
            }}
            options={[
              { value: '10', label: '10 per Page' },
              { value: '20', label: '20 per Page' },
              { value: '30', label: '30 per Page' },
              { value: '40', label: '40 per Page' },
              { value: '50', label: '50 per Page' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </Grid>
      </Grid>
      {history.isFetching ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid container rowSpacing={2}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="stretch"
            className="wallet-transactions-history"
          >
            {isMobile ? (
              history.data.data.map((data, idx) => (
                <Grid
                  key={idx}
                  container
                  borderBottom="1px solid rgba(255, 255, 255, 0.1)"
                  paddingY="24px"
                >
                  <Grid item xs={6} marginBottom="6px">
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Amount
                    </Typography>
                    <Typography fontSize="14px">
                      <span className={data.direction}>
                        {`$${Number(data.amount).toLocaleString('en-us')}`}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} marginBottom="6px">
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Date
                    </Typography>
                    <Typography fontSize="14px">
                      {dayjs(data.actionForDate || data.createdAt).tz('GMT').format('MMM DD, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Description
                    </Typography>
                    <Typography fontSize="14px">
                      {data.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Table
                loading={history.isFetching}
                columns={ambassadorcolumnsConfig(t)}
                rows={history.data.data.map((i) => ({
                  ...i, value: i?.token || i?.mgCoins || i?.balance || i?.coins || 0,
                }))}
                className="table mb-4"
              />
            )}
          </Grid>
          {Math.ceil(history.data.total / historyPageSize) > 1 ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              className="custom-pagination"
            >
              <Pagination
                count={Math.ceil(history.data.total / historyPageSize)}
                page={currentPage}
                onChange={(val, page) => {
                  setCurrentPage(page);
                }}
              />
            </Grid>
          ) : null}

        </Grid>
      )}
    </Grid>
  );
}
