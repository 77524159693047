import { Typography } from '@mui/material';
import { useBreakpoint } from '../../../../utils/store';
import SelectedCoin from '../SelectedCoin';

export default function CointListItem({
  image, coinName, price, active, id, onClick, MGTokens, gameId, priceChange, chart, marketCap, totalVolume,
}) {
  const { isMobile, isTablet } = useBreakpoint();
  const priceF = parseFloat(price).toFixed(8);
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
        }
      }}
      className="current-coins-list__item-wrapper"
    >
      <div
        style={{
          display: 'flex', alignItems: 'center', gap: '10px', flex: 1, marginBottom: isMobile || isTablet ? '20px' : '0px', 
        }}
        className={`current-coins-list__item ${active ? 'active' : ''}`}
      >
        <img src={image} alt={coinName} />
        <Typography
          fontSize="16px"
          fontWeight={400}
          marginBottom="0px"
          marginRight="auto"
        >
          {coinName} 
         
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight={500}
          marginBottom="0px"
        >
          $
          {priceF} 
         
        </Typography>
        {!isMobile && !isTablet && active ? (
          <span style={{
            display: 'block', 
            height: '45px',
            width: '45px',
            backgroundColor: '#1E1F22',
            position: 'absolute',
            top: '50%',
            right: '-75px',
            borderRadius: '8px',
            transform: 'translateY(-50%) rotate(45deg)',
          }}
          />
        ) : null}
      
      </div>

      {(isMobile || isTablet) && active ? <SelectedCoin marketCap={marketCap} totalVolume={totalVolume} gameId={gameId} balace={MGTokens} image={image} coin={coinName} price={price} id={id} priceChange={priceChange} chart={chart} /> : null}
    </div>
    
  );
}
