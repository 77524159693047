import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table from '../../../../../components/Table';
import { columns } from './config';
import { useSimulatorMyCoins, useSimulator } from '../../../../Simulator/context/hook';
import SimulatorCoinHistoryModal from '../../../../Simulator/components/SimulatorCoinHistoryModal';

export default function MyCointsList() {
  const { t } = useTranslation();
  const [currentCoin, setCurrentCoin] = useState(null);
  const [openHistoy, setOpenHistoy] = useState(false);
  const { userId } = useParams();
  
  const myCoins = useSimulatorMyCoins(userId);
  
  const simulator = useSimulator();
  
  return (
    <div className="my-coin-picks-grid-view">
      
      <Grid
        size={{ xs: 12, sm: 8 }}
        sx={{
          alignItems: 'center', gap: '10px', marginBottom: { xs: '20px', sm: '20px' }, display: { sm: 'flex' },
        }}
      />
      <Grid container spacing={4} padding={0}>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {myCoins.data && myCoins.data.data.length > 0 ? (
            <Table
              columns={columns({
                setCurrentCoin, setOpenHistoy, 
              })}
              rows={myCoins.data.data}
              className="table"
              hideFooterPagination
              hideFooter
              getRowId={(row) => row.id}
            />
          ) : null}
         
        </Grid>
      </Grid>
      {currentCoin && simulator.data && simulator.data.data.token ? (
        <SimulatorCoinHistoryModal
          isOpen={openHistoy}
          handleClose={() => {
            setOpenHistoy(false);
            setCurrentCoin(null);
          }}
          currentCoin={currentCoin}
        />
      ) : null}
      
    </div>
       
  );
}
