import { useCallback, useContext } from 'react';
import useApi from '../../../utils/api';
import { AppContext } from '../../../utils/store/app';

export const PAGE_SIZE = 10;

export default function useMembershipsApi() {
  const { authGet, authPost } = useApi();
  const { passTypes } = useContext(AppContext);

  const getPassess = useCallback(
    ({ page, ...filters }) => authGet('/passes', {
      params: {
        perPage: PAGE_SIZE,
        page,
        ...filters,
      },
    }),
    [authGet],
  );

  const getTransfers = useCallback(
    ({ page, ...filters }) => authGet('/passes/transfer-history', {
      params: {
        perPage: PAGE_SIZE,
        page: page || 1,
        ...filters,
      },
    }),
    [authGet],
  );

  const getPurchaseHistory = useCallback(
    ({ page, ...filters }) => authGet('/payments', {
      params: {
        perPage: PAGE_SIZE,
        page: page || 1,
        ...filters,
      },
    }),
    [authGet],
  );

  const getPassTypes = passTypes;
  const buyPassStart = useCallback(() => authPost('/payments/passes/start'), [authPost]);

  const solanaPaymentConfirmedStatus = useCallback((data) => authPost(`payments/package/solana/${data.puid}`, {
    data: {
      transactionSignature: data.signature,
      statusToken: data.statusToken,
      transactionId: data.transactionId,
      secondHalfPayment: data?.secondHalfPayment || false,
      status: 'confirmed',
    },
  }), [authPost]);

  const validateSolanaPaymentPass = useCallback((puid) => authPost(`/payments/package/solana/${puid}/validate`), [authPost]);

  return {
    getPassess,
    passTypes,
    getPassTypes,
    buyPassStart,
    getTransfers,
    getPurchaseHistory,
    
    solanaPaymentConfirmedStatus,
    validateSolanaPaymentPass,
  };
}
