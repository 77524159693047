import React from 'react';

const ItemData = ({ label, value }) => {
  // if value is email do not capitalize
  const isEmail = value.includes('@');
  return (
    <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
      <span className="label">
        {label}
        {' '}
        :
      </span>
      <span className="value" style={{ textTransform: isEmail ? 'none' : 'capitalize', fontWeight: 700 }}>
        {value}
      </span>
    </div>
  );
};

export default ItemData;
