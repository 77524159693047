/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  useContext, useState, useCallback,
  useEffect,
} from 'react'; 
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication'; 
import useApi from '../../../../utils/api'; 
import { AppContext } from '../../../../utils/store/app';

export default function usePurchasePass(reset) {
  const { user } = useContext(AuthenticationContext);
  const [errors, setErrors] = useState(null);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [renewedMsg, setRenewedMsg] = useState('');
  const [purchaseData, setPurchaseData] = useState(null);
  const { authPost, authDelete } = useApi();

  const [defaultValue, setDefaultValue] = useState({ amountOfPasses: 1 });
  const { passTypes } = useContext(AppContext);

  useEffect(() => {
    if (passTypes) {
      setDefaultValue({ passType: passTypes[0]?.id, amountOfPasses: 1 });
    }
  }, [passTypes]);
  
  const handleModalClose = async (canceled = true) => {
    try {
      const _solana = sessionStorage.getItem('_solana_pay_transaction_id');
      if (canceled && !_solana) {
        const _puid = sessionStorage.getItem('_puid');
        if (_puid) {
          await authDelete(`/payments/passes/remove/${_puid}`);
        }
      } else {
        if (!_solana) {
          await authPost('/payments/passes/free', { data: { ...purchaseData, SID: user?.SID || purchaseData.SID } });
        }
        sessionStorage.removeItem('_solana_pay_transaction_id');
      }
    } catch (err) {
      console.log(err);
    }
    setPurchaseData(null);
    setErrors(null);
    sessionStorage.removeItem('_puid');
    if (reset) {
      reset();
    }
  };
  
  const submitPurchase = useCallback(async (data) => {
    setIsLoading(true);
    try {
      if (!data?.passType) {
        setErrors({ passType: 'Pack type is required' });
      } else if (!data?.amountOfPasses || data?.amountOfPasses <= 0) {
        setErrors({ amountOfPasses: 'Amount is required' });
      } else {
        const result = await authPost('/payments/passes/start', { data: { ...data, SID: user.SID } });
        if (result.priceTotal <= 0) {
          delete result.priceTotal;
        }
        setPurchaseData(result);
        sessionStorage.setItem('_puid', result.PUID);
        
        // for stripe use this
        // setPurchaseData({ ...data });
        
        setErrors(null);
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err);
      if (err === 'Invalid pass token') {
        setErrors({ pass: err });
      } else {
        setErrors({ pass: 'An error occurred. Please try again later!' });
      }
      setIsLoading(false);
    }
  }, [userData]);

  return {
    user,
    userData,
    purchaseData,
    setPurchaseData,
    submitPurchase,
    isLoading,
    setIsLoading,
    errors,
    setErrors,
    renewedMsg,
    setRenewedMsg,
    handleModalClose,
    defaultValue,
  };
}
