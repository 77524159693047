// import { useState } from 'react';
import { Grid, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../../../../../components/Table';
import { columns } from './config';
import AppLoader from '../../../../../../components/AppLoader';
import DatePicker from '../../../../../../components/Inputs/DatePicker';
import Button from '../../../../../../components/Button';
import { useMyCoinPicks } from '../../../context/hooks';

export default function GridView() {
  const [curretDate, setCurrentDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState('desc');
  const { userId } = useParams();
  const myCoinPicks = useMyCoinPicks({
    page: currentPage,
    startDate: curretDate,
    userId,
  });
  
  const handleClear = () => {
    setCurrentDate(null);
  };

  return (
    <>
      {myCoinPicks.isFetching && <AppLoader overpage />}

      <Grid className="my-coin-picks-grid-view" container>
        <Grid
          container
          sx={{
            alignItems: 'center', gap: '10px', justifyContent: 'flex-end', marginBottom: { xs: '20px', sm: '20px' }, display: { sm: 'flex' },
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
            sx={{ marginBottom: { xs: '10px', sm: 0 } }}
          >
            Filter by Date:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              size="sm"
              sx={{ minWidth: '200px' }}
              value={curretDate}
              currentTimeZone="UTC"
              onChange={(v) => {
                setCurrentDate(dayjs(v).toISOString());
              }}
            />
            {curretDate && (
            <Button variant="outlined" onClick={handleClear}>
              Clear Date
            </Button>
            )}
          </div>
        </Grid>
        
        {myCoinPicks.data ? (
          <Grid container spacing={4} padding={0}>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Table
                sortModel={[
                  {
                    field: 'lastVotedAt',
                    sort: order,
                  },
                ]}
                onSortModelChange={(newSortModel) => {
                  setOrder(newSortModel[0] === 'd' ? 'desc' : 'asc');
                }}
                sortingOrder={['desc', 'asc']}
                sortingMode="server"
                columns={columns}
                rows={myCoinPicks.data?.data}
                className="table"
                hideFooterPagination
                hideFooter
                getRowId={(row) => row.id}
              />
              {myCoinPicks.data && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${myCoinPicks.data.total} votes`}
                </Typography>
                <Pagination
                  count={Math.ceil(myCoinPicks.data.total / 10)}
                  page={currentPage}
                  onChange={(val, page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
              )}
            </Grid>
          </Grid>
        ) : null}
       
      </Grid>
    </>
  );
}
