/* eslint-disable react/no-danger */
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import dayjs from 'dayjs';
import Banner from '../../../../components/Banner';
import { GlobalBannerContext } from '../context';

const BannersListRow = ({ banner }) => {
  const {
    handleOpenModalToEdit,
    handleOpenModalToDelete,
  } = useContext(GlobalBannerContext);

  return (
    <Grid container className="banners-row">
      <Grid
        item
        xs={12}
        md={10}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <span className="title">
          <span className="primary-text-gradient" style={{ fontSize: '16px', fontWeight: 600 }}>
            {banner.title}
          </span>
        </span>
        <span>
          <b>Start Date:</b>
          {' '}
          {dayjs(banner.startDate).tz('GMT').format('MMM DD, YYYY')}
        </span>
        <span>
          <b>End Date:</b>
          {' '}
          {dayjs(banner.endDate).tz('GMT').format('MMM DD, YYYY')}
        </span>
        <Banner
          data={{
            message: (
              <span dangerouslySetInnerHTML={{ __html: banner.message }} />
            ),
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className="buttons"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <EditIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenModalToEdit(banner); }}
        />
        <DeleteIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenModalToDelete(banner); }}
        />
      </Grid>
    </Grid>
  );
};

export default BannersListRow;
