import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  useCallback, useContext, useState, 
} from 'react';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import ConfirmOptOutModal from './ConfirmOptOutModal';

const OptOutChallenge = () => {
  const { user } = useContext(AuthenticationContext);
  const { authPut } = useApi();
  const [optOutChallenge, setOptOutChallenge] = useState(!!user.optOutChallenge);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCheckboxChange = (newVal) => {
    if (!newVal) {
      toast.error('You can\'t change this value.');
      return;
    }
    setOpenConfirmModal(newVal);
  };

  const handleConfirmOptOutModalSubmit = useCallback(
    async () => {
      try {
        await authPut('/profile/opt-out-challenge', { data: { optOutChallenge: true } });
        setOpenConfirmModal(false);
        setOptOutChallenge(true);
        toast.success('Updated successfully');
      } catch (err) {
        toast.error('Error updating Profile');
      }
    },
    [authPut, setOptOutChallenge],
  );

  return (
    <>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          fontSize: '24px',
          fontWeight: 600,
          mb: 2,
          width: '100%',
        }}
      >
        Opt Out of Challenge
      </Typography>
      <FormControlLabel
        control={(
          <Checkbox
            name="Sort"
            value={optOutChallenge}
            checked={optOutChallenge}
            onChange={(_, v) => handleCheckboxChange(v)}
            sx={{ color: 'white', fontSize: '12px !important' }}
            disabled={optOutChallenge}
          />
        )}
        label={(
          <div className="flex">
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                letterSpacing: '1.2px',
              }}
            >
              Opt Out Challenge
            </Typography>
          </div>
        )}
      />
      <ConfirmOptOutModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        handleConfirmOptOutModalSubmit={handleConfirmOptOutModalSubmit}
      />
    </>
  );
};

export default OptOutChallenge;
