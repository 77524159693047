import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';

export const colConfig = () => ({
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: () => 'first-column ',
  renderCell: (params) => {
    if (params.field === 'Name') {
      return (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"

        >
          <Grid item xs={12}>
            <Typography>
              {`${params.row.Name} ${params.row.Surname}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: '#919191' }}>
              {`${params.row.Username} (${params.row.SID})`}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (params.field === 'EnrolledParentID') {
      return (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography>
              {`${params.row?.sponsor?.Name} ${params.row?.sponsor?.Surname}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: '#919191' }}>
              {`${params.row?.sponsor?.Username} (${params.row?.sponsor?.SID})`}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (params.field === 'actions') {
      const { row } = params;
  
      return (
        <div className="flex" style={{ gap: '10px' }}>
          
          <Link to={`/admin/support/${row.SID}`}>
            <Button
              size="sm"
              type="button"
            >
              Games
            </Button>
          </Link>
          <Link to={`/admin/support/simulator/${row.SID}`}>
            <Button
              size="sm"
              type="button"
            >
              Simulator
            </Button>
          </Link>
     
        </div>
      );
    }

    return params;
  },
});

export const defaultFormData = {
  name: '',
};

export const userFormConfig = [
  {
    name: 'myAccountTitle',
    isText: true,
    type: 'h6',
    label: 'Account Data',
  },
  {
    name: 'enrollmentLink',
    type: 'text',
    label: 'Enrollment Link',
  },
  {
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'surname',
    type: 'text',
    label: 'Surname',
  },
  {
    name: 'username',
    type: 'text',
    label: 'Username',
  },
  {
    name: 'memberID',
    type: 'text',
    label: 'Member ID',
  },
  {
    name: 'enrolledParentId',
    type: 'text',
    label: 'Enrolled Parent ID',
  },
  {
    name: 'serviceLevel',
    type: 'text',
    label: 'Service Level',
  },
  {
    name: 'addressInfoTitle',
    isText: true,
    type: 'h6',
    label: 'Address Info',
  },
  {
    name: 'addressLine1',
    type: 'text',
    label: 'Address Line 1',
  },
  {
    name: 'addressLine2',
    type: 'text',
    label: 'Address Line 2',
  },
  {
    name: 'country',
    type: 'text',
    label: 'Country',
  },
  {
    name: 'city',
    type: 'text',
    label: 'City',
  },
  {
    name: 'state',
    type: 'text',
    label: 'State',
  },
  {
    name: 'zipCode',
    type: 'text',
    label: 'Zip Code',
  },
  {
    name: 'personalInformationTitle',
    isText: true,
    type: 'h6',
    label: 'Personal Information',
  },
  {
    name: 'mobileNo',
    type: 'text',
    label: 'Mobile No',
  },
  {
    name: 'email',
    type: 'text',
    label: 'email',
  },
  {
    name: 'birthday',
    type: 'date',
    label: 'Date Of Birth',
  },
  {
    name: 'title',
    isText: true,
    type: 'h6',
    label: 'Change Password',
  },
  {
    name: 'oldPassword',
    type: 'password',
    label: 'Old Password',
  },
  {
    name: 'newPassword',
    type: 'password',
    label: 'New Password',
  },
  {
    name: 'retypePassword',
    type: 'password',
    label: 'Retype Password',
  },
];

export default {};
