import dayjs from 'dayjs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Tooltip } from '@mui/material';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultTurnsColumnsConfig = (
  t,
  handleChangeTurnOrder,
  handleDeleteTurn,
) => [
  {
    field: 'order',
    headerName: t('Order'),
    minWidth: 100,
    ...defaultColConfig,
  },
  {
    field: 'User',
    headerName: t('User'),
    flex: 1,
    minWidth: 350,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <div
        className="flex w-full"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div className="flex">
          <span style={{ lineHeight: 'normal', marginRight: '5px' }}>
            {`${
              row.User.Country?.emoji || ''
            }`}
          </span>
          <span>{`(${row.User.SID}) ${row.User.Username} - ${row.totalLeads} Leads`}</span>
        </div>
      </div>
    ),
  },
  {
    field: 'current',
    headerName: t('Current Turn'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? 'Up to bat' : '--'),
  },
  {
    field: 'lastTurn',
    headerName: t('Last Turn'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? dayjs(value).tz('GMT').format('MMM DD, YYYY HH:mm:ss') : '--'),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 100,
    ...defaultColConfig,
    renderCell: (data) => (
      <>
        <div className="flex flex-column mr-4">
          {data.row.order > 1 && (
            <Tooltip placement="left" title="Move Up">
              <KeyboardArrowUpIcon
                sx={{ cursor: 'pointer', display: 'block' }}
                color="primary"
                onClick={() => handleChangeTurnOrder({
                  ambassadorId: data.row.ambassadorId,
                  moveForward: false,
                })}
              />
            </Tooltip>
          )}
          {data.api.getRowsCount() > data.row.order && (
            <Tooltip placement="left" title="Move Down">
              <KeyboardArrowDownIcon
                sx={{ cursor: 'pointer', display: 'block' }}
                color="primary"
                onClick={() => handleChangeTurnOrder({ ambassadorId: data.row.ambassadorId })}
              />
            </Tooltip>
          )}
        </div>
        {!data.row.current && (
          <DeleteIcon
            sx={{ cursor: 'pointer', display: 'block' }}
            color="primary"
            onClick={() => handleDeleteTurn({ ambassadorId: data.row.ambassadorId })}
          />
        )}
      </>
    ),
  },
];
