export const faqFormConfig = [
  {
    name: 'categoryId',
    label: 'Category',
    type: 'ajaxSelect',
    endPoint: '/faq/category',
    mapper: (val) => ({ label: val.title, value: val.id }),
  },
  {
    name: 'question',
    type: 'text',
    label: 'Question',
    placeholder: 'Question?',
    required: true,
  },
  {
    name: 'answer',
    type: 'textarea',
    label: 'Answer',
    placeholder: 'Answer',
    required: true,
    addLinkIcon: true,
    rows: 5,
  },
  {
    name: 'active',
    type: 'muiCheckbox',
    label: 'Active',
    checked: true,
  },
];

export const faqFormValidationConfig = {
  categoryId: { isEmpty: false, type: 'String', message: 'Category is required' },
  question: { isEmpty: false, type: 'String', message: 'Question is required' },
  answer: { isEmpty: false, type: 'String', message: 'Answer is required' },
};

export const faqsTableConfig = {};
