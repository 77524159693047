import { useState } from 'react';
import {
  Box, Container, Tab, Tabs, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/CardLayout';
import ResultsBox from '../../AdminReports/components/ResultsBox';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';
import { useSupportUser } from './context/hooks';
import MyCointsList from './components/MyCointsList';
import MyCointsTransactions from './components/MyCointsTransactions';

export default function UserSimulatorDetails() {
  const { t } = useTranslation();
  const [listTabsValue, setListTabsValue] = useState(0);
  const { userId } = useParams();
  const supportUser = useSupportUser(userId);
  const handleListTabChange = (_, newValue) => {
    setListTabsValue(newValue);
  };

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 4, padding: { xs: 0, lg: '24px' } }}>
      {supportUser.data ? (
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '20px',
        }}
        >

          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '26px', lg: '32px' },
              fontWeight: 600,
              mb: 4,
              textAlign: 'center',
            }}
          >
            {`${supportUser.data.Name} ${supportUser.data.Surname}  (${supportUser.data.Username} - ${supportUser.data.SID})`}
          </Typography>

          <div style={{
            width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', justifyContent: 'center',
          }}
          >
   
            {supportUser.data.wallet ? (
              <div className="result">
                <ResultsBox
                  title="Ambassador wallet"
                  result={`${supportUser.data.wallet.currency} $${supportUser.data.wallet.balance.toLocaleString('en-us')}`}
                  text
                />

              </div>
            ) : null}

            {supportUser.data.gamerWallet ? (
              <div className="result">
                <ResultsBox
                  title="Gamer wallet"
                  result={`${supportUser.data.gamerWallet.coins && supportUser.data.gamerWallet.coins.toLocaleString('en-us')} MG Coins |  ${supportUser.data.gamerWallet.token && supportUser.data.gamerWallet.token.toLocaleString('en-us')} MG Tokens`}
                  text
                />

              </div>
            ) : null}

          </div>

        </div>

      ) : null}

      <Container
        className="ambassador-tabs noPadding"
        style={{ paddingLeft: '0px !imporatant', paddingRight: '0px !imporatant' }}
        maxWidth="xl"
      >
        <Box>
          <Tabs
            value={listTabsValue}
            onChange={handleListTabChange}
            aria-label="History Section"
            className="ambassador-tabs-header"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Meme Coins" {...a11yProps(0)} />
            <Tab label="Transactions" {...a11yProps(1)} />
            
          </Tabs>
        </Box>
        <CustomTabPanel
          value={listTabsValue}
          index={0}
          className="ambassador-tabs-transaction"
        >

          <Typography
            className="flex-1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '18px', md: '26px' },
              fontWeight: 600,
              mb: 2,
            }}
          >
            Meme Coins
          </Typography>

          <MyCointsList />
        </CustomTabPanel>
        <CustomTabPanel
          value={listTabsValue}
          index={1}
          className="ambassador-tabs-earnings"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: { xs: '21px', lg: '26px' },
              fontWeight: 600,
              mb: 2,
              textAlign: 'center',
            }}
          >
            Transactions
          </Typography>
          <MyCointsTransactions />
        </CustomTabPanel>

      </Container>
    </Layout>
  );
}
