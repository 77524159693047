import {
  Container, Grid, Typography, 
} from '@mui/material';
import { useContext } from 'react';
import { AdminTransactionsContext, AdminTransactionsProvider } from './context';
import Layout from '../../../components/CardLayout';
import TransactionsFilters from './components/TransactionsFilters';
import Table from '../../../components/Table';
import ComponentLoader from '../../../components/ComponentLoader';
import ReverseConfirmationModal from './components/ReverseConfirmationModal';

const AdminTransactionsPage = () => {
  const {
    t,
    transactionsData,
    selectedColumnsConfiguration,
    transactionsDataLoading,
  } = useContext(AdminTransactionsContext);

  return (
    <Container className="admin-transactions-page" maxWidth="xl">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
            marginBottom: '16px',
            textAlign: 'center',
          }}
        >
          <span className="primary-text-gradient">
            {t('Wallet Transactions Administration')}
          </span>
        </Typography>

        <ReverseConfirmationModal />
        
        <TransactionsFilters />

        {transactionsDataLoading && <ComponentLoader />}

        {(!transactionsDataLoading && transactionsData?.length > 0) && (
          <Grid container>
            <Table
              className="table"
              columns={selectedColumnsConfiguration}
              rows={transactionsData}
              loading={transactionsDataLoading}
            />
          </Grid>
        )}
      </Layout>
    </Container>
  );
};

export default () => (
  <AdminTransactionsProvider>
    <AdminTransactionsPage />
  </AdminTransactionsProvider>
);
