import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import Price from '../../../../components/Price';

export const columns = () => [

  {
    field: 'memeCoins',
    headerName: 'Amount',
    flex: 1,
    renderCell: ({ value }) => (
      <Typography className="coin-name">
        {value.toFixed(2)}
      </Typography>
    ),
  },
  {
    field: 'memeCoinPrice',
    headerName: 'Price',
    width: 160,
    renderCell: ({ value }) => (
      <Price className="coin-price" value={value} dollarSign />
    ),
  },
  {
    field: 'transactionType',
    headerName: 'Transaction',
    width: 150,
    renderCell: ({ value }) => (
      <Typography style={{ textTransform: 'capitalize', color: value === 'redeem' ? 'green' : 'red' }}>{value === '' ? 'Swap In' : 'Swap Out'}</Typography>
      
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 190,
    renderCell: ({ value }) => (
      <Typography className="coin-price">
        {`${dayjs(value).tz('GMT').format('MMM DD, YY HH:mm:ss')} GMT`}
      </Typography>
    ),
  },

];

export default {};
