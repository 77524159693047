import AddLinkIcon from '@mui/icons-material/AddLink';
import Modal from '../Modal';
import Form from '../Form';
import useCreateLinkModalHook from './hook';

const CreateLinkModal = ({ iconColor = '#FFFFFF', title = 'Create and Copy Link' }) => {
  const {
    isOpen,
    handleOpenModal,
    handleCloseModal,
    handleModalSubmit,
    formConfig,
    defaultValues,
    formErrors,
    setFormErrors,
  } = useCreateLinkModalHook();

  return (
    <>
      <AddLinkIcon
        style={{ color: iconColor, cursor: 'pointer' }}
        onClick={handleOpenModal}
      />

      <Modal
        title={title}
        width="600px"
        open={isOpen}
        handleClose={handleCloseModal}
        bodyStyle={{ paddingTop: '0', marginTop: 0 }}
      >
        <Form
          config={formConfig}
          size="sm"
          onSubmit={handleModalSubmit}
          defaultFormValue={defaultValues}
          errors={formErrors}
          setErrors={setFormErrors}
          submitLabel="Copy Link"
          buttonType="Submit"
          buttonStyle={{ width: '100%', marginTop: '10px' }}
        />
      </Modal>
    </>
  );
};

export default CreateLinkModal;
