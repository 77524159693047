import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, Typography, Box, Pagination,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { PendingWithdrawalsContext, PendingWithdrawalsProvider } from './context';
import Table from '../../../components/Table';
import PayWithdrawModal from './components/PayWithdrawModal';
import CancelWithdrawModal from './components/CancelWithdrawModal';
import Button from '../../../components/Button';
import { browserDownloadBlobData } from '../../../utils/file';
import { downloadCSV } from '../../../utils/csv';
import Select from '../../../components/Inputs/Select';
import TextInput from '../../../components/Inputs/TextInput';

const PendingWithdrawals = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,
    withdrawDataColumnsConfiguration,
    withdrawData,
    loadedWithdrawData,
    fetchPaymentReportData,
    setUserSIDUserName,
    setPerPage,
    setPage,
    perPage,
    page,
    userSIDUserName,
    fetchWithdrawData,
  } = useContext(PendingWithdrawalsContext);
  const currentDate = dayjs().format('YYYY-MM-DD');
  const [idOrUsername, setIdOrUsername] = useState('');

  const handleDownloadPaymentReport = async () => {
    const data = await fetchPaymentReportData();

    if (data.length > 0) {
      browserDownloadBlobData(data, `withdraw_payment_report_${currentDate}.csv`);
      return;
    }

    toast.error('No Records found');
  };

  if (!user) {
    navigate('/landing');
  }
  
  useEffect(
    () => {
      fetchWithdrawData();
    },
    [page, perPage, userSIDUserName],
  );

  return (
    <Container className="admin-pending-withdrawals">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('Pending Withdrawals')}</span>
        </Typography>

        {loadedWithdrawData && (
          <>
            <Grid container my={2}>
              <div className="divider" />
            </Grid>
            <div style={{ gap: '10px' }} className="flex justify-between mb-2">
              <Button
                onClick={handleDownloadPaymentReport}
                size="sm"
              > 
                {t('Payment Report CSV')}
              </Button>
              {/* <Button
                onClick={() => {
                  downloadCSV(withdrawData, `pending_withdrawals_${currentDate}.csv`);
                }}
                size="sm"
              > 
                {t('Table Report CSV')}
              </Button> */}
            </div>
          </>
        )}
        <Grid
          xs={12}
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Grid
            container
            className="notifications-filters"
            display="flex"
            alignItems="center"
            spacing={4}
            sx={{ marginBottom: '20px' }}
          >
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <TextInput
                  className="search-input"
                  size="xsm"
                  placeholder="User ID or Username"
                  value={idOrUsername}
                  onChange={(e) => {
                    if (e === '') {
                      setUserSIDUserName('');
                    }
                    setIdOrUsername(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setUserSIDUserName(idOrUsername); 
                      fetchWithdrawData();
                    }
                  }}
                  endAdornment={(
                    <Button
                      onClick={() => {
                        setUserSIDUserName(idOrUsername);
                        fetchWithdrawData();
                      }}
                      className="py-auto my-auto mr-5"
                      bordered
                      size="sm"
                    >
                      <span className="no-wrap">Search</span>
                    </Button>
                        )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: 'flex', gap: '1.5rem', flexDirection: { xs: 'column', md: 'row' } }}>
          
              <Box sx={{ width: { md: '50%' } }}>
                <Select
                  className="user-type-filter-select"
                  placeholder="Pagination"
                  size="sm"
                  value={perPage.toString()}
                  onChange={(v) => {
                    if (v === 'default') {
                      setPerPage(10);
                      setPage(1);
                      return;
                    }
                   
                    setPerPage(Number(v));
                    setPage(1);
                  }}
                  options={[
                    { value: 10, label: '10 per Page' },
                    { value: 20, label: '20 per Page' },
                    { value: 30, label: '30 per Page' },
                    { value: 40, label: '40 per Page' },
                    { value: 50, label: '50 per Page' },
                    { value: 100, label: '100 per Page' },
                  ]}
                  sx={{ paddingRight: '1rem' }}
                />
              </Box>
            </Grid>
       
          </Grid>
          {loadedWithdrawData ? (
            <>
              <Table
                loading={!loadedWithdrawData}
                columns={withdrawDataColumnsConfiguration}
                rows={withdrawData.data}
                className="table mb-4"
              />
              {(withdrawData && withdrawData.total > perPage) && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${withdrawData.total} users`}
                </Typography>
                <Pagination
                  count={Math.ceil(withdrawData.total / perPage)}
                  page={page}
                  onChange={(val, _page) => {
                    setPage(_page);
                  }}
                />
              </div>
              )}
            </>
            
          ) : (
            <div className="flex justify-center">
              <ComponentLoader />
            </div>
          )}
        </Grid>
      </Layout>
   
      <PayWithdrawModal />
      <CancelWithdrawModal />
    </Container>
  );
};

export default () => (
  <PendingWithdrawalsProvider>
    <PendingWithdrawals />
  </PendingWithdrawalsProvider>
);
