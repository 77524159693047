import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';
import Button from '../../../../../../../components/Button';

export default function ConfirmModal({
  title,
  confirmModalData,
  handleConfirmModalClose,
  confirmMsg,
  onConfirmation,
  data,
  currentData,
}) {
  const { t } = useTranslation();
  console.log('currentData', currentData);
  return (
    <Modal
      title={title}
      width={500}
      open={!!confirmModalData}
      handleClose={handleConfirmModalClose}
    >
      <div className="flex flex-column justify-center">
        <Box>
          <Typography sx={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
            {t(confirmMsg)}
          </Typography>
          {process.env.REACT_APP_APPLY_PURCHASE_FEE === 'true' && (
          <Typography sx={{
            width: '100%', textAlign: 'center', marginBottom: '20px', fontSize: '14px',
          }}
          >
            {`${t('Processing Fee: ')} $${data.price * 0.01}`}
          </Typography>
          )}
          {currentData.transferable && !currentData.renewOnTransfer ? (
            <Typography sx={{
              width: '100%', textAlign: 'center', marginBottom: '20px', fontSize: '14px',
            }}
            >
              
              {`${t('This user will be allowed to transfer this pack/packs to other people.')}`}
            </Typography>
          ) : null}
          
          {currentData.renewOnTransfer ? (
            <Typography sx={{
              width: '100%', textAlign: 'center', marginBottom: '20px', fontSize: '14px',
            }}
            >
              {`${t('This pack will be automatically activated after transfer.')}`}
            </Typography>
          ) : null}

          <Box sx={{ display: 'flex', gap: 3 }}>
            <Button type="button" style={{ width: '100%' }} bordered onClick={() => handleConfirmModalClose()}>
              {t('No')}
            </Button>
            <Button type="button" style={{ width: '100%' }} onClick={() => onConfirmation()}>
              {t('Yes')}
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
}
