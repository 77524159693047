import { Container, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { useSimulatorRules, useSimulatorUpdateRules } from './context/hook';
import { simulatorRulesFormConfig } from './config';
import Form from '../../../components/Form';
import { validateAll } from '../../../components/Form/config';

const SimulaTorRules = () => {
  const { t } = useTranslation();
  const [canGet, setCanGet] = useState(true);
  const simulatorRules = useSimulatorRules();
  const simulatorUpdateRules = useSimulatorUpdateRules({
    onSuccess: () => {
    }, 
  });
  const [postFormErrors, setPostFormErrors] = useState({});
  
  const formValidationConfig = {
    title: { isEmpty: false, type: 'String', message: 'Title is required' },
    content: { isEmpty: false, type: 'String', message: 'Content is required' },
  };
  
  useEffect(() => {
    if (canGet) { setCanGet(false); }
  }, []);
  
  const handleFormSubmit = (data) => {
    // Format data
    const _data = {
      title: data.title,
      content: data.content_content ? data.content_content : data.content,
    };
    const checkData = validateAll(_data, formValidationConfig);
    if (Object.keys(checkData).length > 0) {
      setPostFormErrors(checkData);
    } else {
      simulatorUpdateRules.mutate({
        title: _data.title,
        content: _data.content,
      });
    }
  };

  return (
    <Container className="admin-blog-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 2,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('Simulator Rules')}
          </span>
        </Typography>
        
        {(simulatorRules.data) ? (
          <Form
            size="md"
            defaultFormValue={simulatorRules.data.data}
            config={simulatorRulesFormConfig}
            onSubmit={handleFormSubmit}
            errors={postFormErrors}
            setErrors={setPostFormErrors}
            buttonType="button"
            buttonStyle={{ width: '100%', marginTop: 0 }}
            submitLabel={t('Save')}
            formLoading={simulatorUpdateRules.isPending}
          />
        ) : (
          <ComponentLoader />
        )}

      </Layout>
    </Container>
  );
};

export default SimulaTorRules;
