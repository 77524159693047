import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import Modal from '../../../../components/Modal';
import { AdminTaxContext } from '../context';
import Button from '../../../../components/Button';

const TaxFormDeleteModal = () => {
  const {
    t,
    taxFormDeleteData,
    handleDeleteModalSubmit,
    handleDeleteModalClose,
  } = useContext(AdminTaxContext);

  return (
    <Modal
      title="Delete Tax Form"
      open={!!taxFormDeleteData}
      handleClose={handleDeleteModalClose}
      width="500px"
    >
      {taxFormDeleteData && (
        <>
          <Typography
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
          >
            <span>{t('Delete this Tax Form')}</span>
          </Typography>
          <Typography
            fontSize="16px"
            textAlign="center"
            margin="24px 0"
          >
            <span className="primary-text-gradient">
              {`${taxFormDeleteData.username} - ${taxFormDeleteData.formType}`}
            </span>
          </Typography>

          <Button onClick={() => handleDeleteModalSubmit(taxFormDeleteData.id)} bordered>
            {t('Delete')}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default TaxFormDeleteModal;
