export const defaultFormConfig = [
  {
    name: 'link',
    type: 'text',
    placeholder: 'Link Address',
    required: true,
  },
  {
    name: 'text',
    type: 'text',
    placeholder: 'Link text',
    required: true,
  },
];

export const defaultFormValues = {
  link: '',
  text: '',
};

export const defaultFormValidationConfig = {
  link: { isEmpty: false, type: 'String', message: 'Link is required' },
  text: { isEmpty: false, type: 'String', message: 'Text is required' },
};
