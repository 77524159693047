import { useContext, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { PendingRedemptionsContext } from '../context';
import { renderNumber } from '../../../../utils/string';
import TextInput from '../../../../components/Inputs/TextInput';

const CancelRedemptionModal = () => {
  const [notification, setNotification] = useState('');
  const { t } = useTranslation();
  const {
    submittingCancelRedemptionModal,
    redemptionCancelDataModal,
    handleCancelRedemptionSubmitModal,
    handleCancelRedemptionCloseModal,
  } = useContext(PendingRedemptionsContext);

  useEffect(() => {
    setNotification('');
  }, [redemptionCancelDataModal]);

  return (
    <Modal
      title="Mark as Canceled"
      width="650px"
      open={!!redemptionCancelDataModal}
      handleClose={handleCancelRedemptionCloseModal}
    >
      <h2
        style={{
          marginTop: '-20px',
          textAlign: 'center',
          paddingBottom: '10px',
          paddingTop: 0,
          fontWeight: 'bold',
        }}
      >
        <span
          className="primary-text-gradient"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          {redemptionCancelDataModal?.user}
        </span>
      </h2>
      <div style={{ overflowX: 'auto', marginBottom: '20px' }}>
        <table className="single_table borderless">
          <tbody>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Amount : </th>
              <td>
                {renderNumber(redemptionCancelDataModal?.amountMgTokens)}
                {t(' MG Tokens')}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Memecoin : </th>
              <td>
                {`${redemptionCancelDataModal?.memecoin.name}`}
              </td>
            </tr>
            {redemptionCancelDataModal?.destination !== '' && (
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Wallet : </th>
                <td>{redemptionCancelDataModal?.destination}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TextInput
        type="textarea"
        value={notification}
        onChange={(e) => {
          setNotification(e);
        }}
        placeholder="Notification"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingCancelRedemptionModal && !!notification) {
            handleCancelRedemptionSubmitModal(notification);
          }
        }}
        className="mb-5"
        disabled={submittingCancelRedemptionModal || !notification}
      >
        <span className="no-wrap">Cancel Redemption</span>
      </Button>
    </Modal>
  );
};

export default CancelRedemptionModal;
