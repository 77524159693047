import { useContext, useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/Inputs/TextInput';
import Modal from '../../../../components/Modal';
import { PendingWithdrawalsContext } from '../context';
import { renderNumber } from '../../../../utils/string';

const PayWithdrawModal = () => {
  const [transactionId, setTransactionId] = useState('');
  const { t } = useTranslation();
  const {
    submittingPayWithdrawModal,
    withdrawDestinationDataModal,
    handlePayWithdrawSubmitModal,
    handlePayWithdrawCloseModal,
  } = useContext(PendingWithdrawalsContext);

  useEffect(() => {
    setTransactionId('');
  }, [withdrawDestinationDataModal]);

  return (
    <Modal
      title="Mark as Paid"
      width="650px"
      open={!!withdrawDestinationDataModal}
      handleClose={handlePayWithdrawCloseModal}
    >
      <h2 style={{
        marginTop: '-20px', textAlign: 'center', paddingBottom: '10px', paddingTop: 0, fontWeight: 'bold',
      }}
      >
        <span className="primary-text-gradient" style={{ fontSize: '18px', fontWeight: 'bold' }}>{withdrawDestinationDataModal?.user}</span>
      </h2>
      <div style={{ overflowX: 'auto', marginBottom: '20px' }}> 
        <table className="single_table borderless">
          <tbody>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Amount : </th>
              <td>
                $
                {renderNumber(withdrawDestinationDataModal?.amount)}
              </td>
            </tr>
            {withdrawDestinationDataModal?.destination !== '' ? (
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Destination : </th>
                <td>
                  <Tooltip placement="top" title="Click to Copy">
                    <button
                      type="button"
                      className="text-center"
                      style={{
                        display: 'block',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontWeight: '600',
                      }}
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          withdrawDestinationDataModal?.destination,
                        );
                        toast.success('Wallet Key copied!');
                      }}
                    >
                      {withdrawDestinationDataModal?.destination}
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      
      <TextInput
        value={transactionId}
        onChange={(e) => {
          setTransactionId(e);
        }}
        placeholder="Transaction Id"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingPayWithdrawModal && !!transactionId) {
            handlePayWithdrawSubmitModal(transactionId);
          }
        }}
        className="mb-5"
        disabled={submittingPayWithdrawModal || !transactionId}
      >
        <span className="no-wrap">Paid</span>
      </Button>
      {withdrawDestinationDataModal?.history.length > 0 ? (
        <>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '26px',
              fontWeight: 600,
              mt: 1,
              width: '100%',
              marginBottom: '10px',
            }}
          >
            {t('History')}
          </Typography>
          <div style={{ overflowX: 'auto' }}> 
            <table className="single_table">
              <thead>
                <tr>
                  <th>{t('Amount')}</th>
                  <th>{t('Date')}</th>
                  <th>{t('Destination')}</th>
                </tr>
              </thead>
              <tbody>
                {withdrawDestinationDataModal?.history.map((item) => (
                  <tr key={item.id}>
                    <td>
                      $
                      {renderNumber(item.amount)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>{dayjs(item.createdAt).tz('GMT').format('MMM DD, YYYY')}</td>
                    <td>{item.destination}</td>
                  </tr>
                ))}
              </tbody>
        
            </table>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default PayWithdrawModal;
