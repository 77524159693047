/* eslint-disable react/no-danger */
import { useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { GlobalNotifyContext } from '../context';

const NotifyListRow = ({ notify }) => {
  const {
    t,
    handleOpenModalToEdit,
    handleOpenModalToCopy,
    handleOpenModalToDelete,
  } = useContext(GlobalNotifyContext);

  return (
    <Grid container className="notify-row">
      <Grid item xs={12} md={10} display="flex" flexDirection="column" gap={1}>
        <span className="title">
          <b>{notify.title}</b>
        </span>
        <span className="status">
          <b>{t('Status:')}</b>
          {` ${notify.active ? 'active' : 'inactive'}`}
        </span>
        <div className="rules">
          {notify.specific_user && (
            <span>
              <b>{t('Only this User:')}</b>
              {` ${notify.username} (${notify.userId})`}
            </span>
          )}
          {notify.role !== 'empty' && !notify.specific_user && (
            <span>
              <b>{t('Only this Role:')}</b>
              {` ${notify.role}`}
            </span>
          )}
          {notify.role === 'empty' && !notify.specific_user && (
            <span>
              <b>{t('Show to All Users')}</b>
            </span>
          )}
        </div>
        <span dangerouslySetInnerHTML={{ __html: notify.message }} />
        <span className="date">
          <b>{t('Created At: ')}</b>
          {dayjs(notify.createdAt).tz('GMT').format('lll')}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        className="buttons"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <ContentCopyIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => handleOpenModalToCopy(notify)}
        />
        <EditIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => handleOpenModalToEdit(notify)}
        />
        <DeleteIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => handleOpenModalToDelete(notify)}
        />
      </Grid>
    </Grid>
  );
};

export default NotifyListRow;
