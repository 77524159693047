import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import { notifyFormValidationConfig } from '../config';

const useGlobalNotifyApi = () => {
  const {
    authGet, authPost, authPut, authDelete,
  } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);

  const [allGlobalNotifyData, setAllNotifyData] = useState([]);
  const [allGlobalNotifyDataLoaded, setAllNotifyDataLoaded] = useState(false);
  const [singleNotifyData, setSingleNotifyData] = useState(null);
  const [deleteNotifyData, setDeleteNotifyData] = useState(null);
  const [formErrors, setFormErrors] = useState();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const fetchGlobalNotifyData = useCallback(
    async ({
      search = null, userId = null, userType = null, latestFirst = false, 
    }) => {
      setAllNotifyDataLoaded(false);

      const response = await authGet('/global-notify', {
        params: {
          search,
          userId,
          userType,
          latestFirst,
        },
      });
      
      setAllNotifyData(response.data.map((notify) => {
        if (notify.userId) {
          notify.user = { label: notify.username, value: notify.userId };
        }

        if (!['gamer', 'ambassador'].includes(notify?.role)) {
          notify.role = 'empty';
        }

        return notify;
      }));

      setAllNotifyDataLoaded(true);
    },
    [authGet],
  );

  const handleFormSubmitModal = useCallback(
    async (data) => {
      setFormSubmitting(true);

      // Add conditional validation
      const userKeyValidation = data.specific_user ? { user: { message: 'User is required' } } : {};

      const checkForErrors = validateAll(data, { ...notifyFormValidationConfig, ...userKeyValidation });

      if (checkForErrors) {
        setFormErrors(checkForErrors);
        setFormSubmitting(false);

        return;
      }
      
      const isEditing = data?.id?.length > 0;

      // If role is not 'gamers' or 'ambassador', set undefined
      if (!['gamer', 'ambassador'].includes(data?.role)) {
        data.role = undefined;
      }

      try {
        if (isEditing) {
          await authPut(`/global-notify/${data.id}`, { data });
        } else {
          await authPost('global-notify', { data });
        }

        const successMessage = `Global Notify Message ${isEditing ? 'Edited' : 'Added'} Successfully`;

        toast.success(successMessage);
        fetchGlobalNotifyData({});
      } catch (err) {
        console.log(err);
        // const errorMessage = `Error ${isEditing ? 'Editing' : 'Creating'} Global Notify Message`;
        toast.error(err);
      } finally {
        setFormErrors(null);
        setSingleNotifyData(null);
        setFormSubmitting(false);
      }
    },
    [setSingleNotifyData],
  );

  const handleFormDeleteSubmitModal = useCallback(
    async (notifyId) => {
      setFormSubmitting(true);
      try {
        await authDelete(`/global-notify/${notifyId}`);
        toast.success('The record has been deleted.');
      } catch (err) {
        console.log(err);
        toast.error('Error deleting record.');
      } finally {
        setDeleteNotifyData(null);
        setFormSubmitting(false);
        fetchGlobalNotifyData({});
      }
    },
    [authDelete],
  );

  const handleOpenModalToAdd = useCallback(
    async () => {
      setSingleNotifyData({
        title: '',
        message: '',
        specific_user: false,
        active: true,
        role: 'empty',
      });
    },
    [setSingleNotifyData],
  );

  const handleOpenModalToEdit = useCallback(
    async (data) => {
      const { notified, ...notify } = data;
      setSingleNotifyData(notify);
    },
    [setSingleNotifyData],
  );

  const handleOpenModalToCopy = useCallback(
    async (data) => {
      const { id, notified, ...notify } = data;
      setSingleNotifyData(notify);
    },
    [setSingleNotifyData],
  );

  const handleOpenModalToDelete = useCallback(
    async (data) => {
      setDeleteNotifyData(data);
    },
    [setDeleteNotifyData],
  );

  const handleCloseModal = useCallback(
    async () => {
      setFormErrors(null);
      setSingleNotifyData(null);
      setFormSubmitting(false);
    },
    [setSingleNotifyData],
  );

  const handleCloseDeleteModal = useCallback(
    async () => {
      setDeleteNotifyData(null);
    },
    [setDeleteNotifyData],
  );

  // useEffect(() => {
  //   fetchGlobalNotifyData();
  // }, []);

  return {
    t,
    user,

    allGlobalNotifyData,
    allGlobalNotifyDataLoaded,
    singleNotifyData,
    deleteNotifyData,
    formErrors,
    formSubmitting,
    setFormErrors,
    fetchGlobalNotifyData,
    handleFormSubmitModal,
    handleFormDeleteSubmitModal,
    handleOpenModalToAdd,
    handleOpenModalToEdit,
    handleOpenModalToCopy,
    handleOpenModalToDelete,
    handleCloseModal,
    handleCloseDeleteModal,
  };
};

export default useGlobalNotifyApi;
