import { Grid, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import PassTypeCard from './components/card';
import usePassTypes from './hook';
import PackageModal from './components/modal';
import Button from '../../../components/Button';
import AppLoader from '../../../components/AppLoader';

export default function PassTypes() {
  const {
    submit,
    errors,
    loadingPassTypes,
    passTypes,
    onPassTypeClick,
    selectedPassType,
    handleModalClose,
    message,
    formLoading,
  } = usePassTypes();

  const [hideInactive, setHideInactive] = useState(sessionStorage.getItem('_pt_h_in') === 'true');
  const [packTypes, setPackTypes] = useState(passTypes);

  useEffect(() => {
    if (hideInactive) {
      const filteredPassTypes = passTypes.filter((item) => item.active);
      setPackTypes(filteredPassTypes);
    } else {
      setPackTypes(passTypes);
    }
  }, [hideInactive, passTypes]);

  if (loadingPassTypes) {
    return <AppLoader />;
  }

  return (
    <>
      <Grid container columnSpacing={3} sx={{ paddingLeft: { lg: 4 } }}>
        <Grid
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}
          item
        >
          <FormControlLabel
            control={(
              <Switch
                name="visible_switch"
                color="primary"
                checked={hideInactive || false}
                onChange={(v) => {
                  sessionStorage.setItem('_pt_h_in', v.target.checked);
                  setHideInactive(v.target.checked);
                }}
              />
            )}
            label="Hide inactive"
          />
          <Button
            size="sm"
            onClick={() => {
              onPassTypeClick();
            }}
          >
            Add +
          </Button>
        </Grid>
        {packTypes?.map((item) => (
          <Grid key={`passType${item.id}`} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <PassTypeCard onPassTypeClick={onPassTypeClick} data={item} />
          </Grid>
        ))}
      </Grid>
      <PackageModal
        errors={errors}
        submit={submit}
        data={selectedPassType}
        handleModalClose={handleModalClose}
        message={message}
        formLoading={formLoading}
      />
    </>
  );
}
