import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { columns } from './config';
import Layout from '../../../../components/CardLayout';
import { useSimulatorMyCoins, useSimulator } from '../../context/hook';
import SimulatorRedeemModal from '../SimulatorRedeemModal';
import SimulatorWithdrawModal from '../SimulatorWithdrawModal';
import SimulatorCoinHistoryModal from '../SimulatorCoinHistoryModal';

export default function MyCointsList() {
  const { t } = useTranslation();
  const [openRedeem, setOpenRedeem] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [currentCoin, setCurrentCoin] = useState(null);
  const [openHistoy, setOpenHistoy] = useState(false);
  
  const myCoins = useSimulatorMyCoins();
  
  const simulator = useSimulator();
  
  return (
    <Layout className="my-coin-picks-grid-view" maxWidth="xl" cardMainClassName="pt-6 pl-0 pr-0 pb-6" size="xsm">
      
      <Grid
        size={{ xs: 12, sm: 8 }}
        sx={{
          alignItems: 'center', gap: '10px', marginBottom: { xs: '20px', sm: '20px' }, display: { sm: 'flex' },
        }}
      >
        <Typography
          fontSize="26px"
          fontWeight={500}
          marginBottom="0px"
          marginRight={{ xs: '0', sm: 'auto' }}
        >
          {t('Your Meme Coins')}
        </Typography>

      </Grid>
      <Grid container spacing={4} padding={0}>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {myCoins.data && myCoins.data.data.length > 0 ? (
            <Table
              columns={columns({
                setOpenRedeem, setOpenWithdraw, setCurrentCoin, setOpenHistoy, 
              })}
              rows={myCoins.data.data}
              className="table"
              hideFooterPagination
              hideFooter
              getRowId={(row) => row.id}
            />
          ) : null}
         
        </Grid>
      </Grid>
      {currentCoin && simulator.data ? (
        <>
          {openRedeem ? (
            <SimulatorRedeemModal
              isOpen={openRedeem}
              handleClose={() => {
                setOpenRedeem(false);
                setCurrentCoin(null);
              }}
              image={currentCoin.coinImage}
              coin={currentCoin.coinName}
              price={currentCoin.currentPrice}
              startPrice={currentCoin.startPrice}
              id={currentCoin.coinApiId}
              balance={simulator.data?.data.token}
              change={currentCoin.change24Hours ? currentCoin.change24Hours : 0}
              gameId={currentCoin.gameId}
            />
          ) : null}
          
          {openWithdraw ? (
            <SimulatorWithdrawModal
              isOpen={openWithdraw}
              handleClose={() => {
                setOpenWithdraw(false);
                setCurrentCoin(null);
              }}
              image={currentCoin.coinImage}
              name={currentCoin.coinName}
              price={currentCoin.currentPrice}
              startPrice={currentCoin.startPrice}
              id={currentCoin.coinApiId}
              balance={currentCoin.balance || 20}
              change={currentCoin.change24Hours ? currentCoin.change24Hours : 0}
            />
          ) : null}
          {openHistoy ? (
            <SimulatorCoinHistoryModal
              isOpen={openHistoy}
              handleClose={() => {
                setOpenHistoy(false);
                setCurrentCoin(null);
              }}
              currentCoin={currentCoin}
            />
          ) : null}
         
        </>
      ) : null}
      
    </Layout>
       
  );
}
