import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { useSimulator, useSimulatorSettings } from '../context/hook';
import { renderNumber } from '../../../utils/number';
import SimulatorGetMGTokensModal from './SimulatorGetMGTokensModal';

export default function CurrentCoinsHeader() {
  const { t } = useTranslation();
  const simulator = useSimulator();
  const simulatorSettings = useSimulatorSettings();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); 
  
  return (
    <Grid
      container
      size={{ xs: 12 }}
      sx={{
        alignItems: 'center', gap: '10px', marginBottom: { xs: '20px', sm: '20px' }, display: 'flex', 
      }}
    >
      <Typography
        fontSize="26px"
        fontWeight={500}
        marginBottom="0px"
        marginRight={{ xs: '0', sm: 'auto' }}
        textAlign={{ xs: 'center', sm: 'left' }}
      >
        {t('Current Meme Coins')}
      </Typography>
      <Grid
        item
        sx={
          {
            display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '10px', alignItems: 'center', flex: { xs: 1, md: 0 }, 
          }
        }
      > 
        <Grid
          item
          sx={
          {
            flexDirection: { xs: 'column', md: 'row' }, gap: '10px', alignItems: 'center', flex: { xs: 1, md: 0 }, 
          }
        }
        >
          {simulator.data && simulator.data.data ? (
            <Typography
              fontSize="16px"
              fontWeight={500}
              marginBottom="0px"
              marginRight={{ xs: 'auto', sm: '15px' }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t('MG Tokens ')} 
              {' '}
              <span className="primary-text-gradient">
                {renderNumber(simulator.data.data.token.toFixed(2))}
              </span>
            </Typography>
          ) : null}
 
        </Grid>
        <Grid
          item
          sx={
          {
            display: 'flex', gap: '10px', alignItems: 'center', flex: { xs: 1, md: 0 }, 
          }
        }
        >  
          
          <Button
            onClick={() => {
              setOpen(true);
            }}
            size="sm"
            bordered
          >
            <span style={{ whiteSpace: 'nowrap' }}>Simulate MG Tokens</span>
          </Button>
          <Button
            onClick={() => {
              navigate('/home');
            }}
            size="sm"
          >
            <span style={{ whiteSpace: 'nowrap' }}>Exit Simulation</span>
          </Button>
        </Grid>
                 
      </Grid>
      {
        simulator.data && simulator.data.data && simulator.data.data.Wallet && simulatorSettings.data && simulatorSettings.data.mgTokenPrice && open ? (
          <SimulatorGetMGTokensModal
            isOpen={open}
            handleClose={() => {
              setOpen(false);
            }}
            coin="MG Token"
            price={simulatorSettings.data.mgTokenPrice}
            balance={simulator.data.data.Wallet.balance}
          />
        ) : null
      }
     
    </Grid>
    
  );
}
