/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Container, Grid, Slider, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { modalTypes } from '../config';
import Button from '../../../components/Button';
import RadioGroup from '../../../components/Inputs/RadioGroup';
import { AppContext } from '../../../utils/store/app';
import { renderNumber } from '../../../utils/string';
import { getNextWeekFriday, dateIsBusinessDay, dateIsHoliday } from '../../../utils/date';

const withdrawIncrement = 135;

export default function WithdrawModal() {
  const {
    t,
    user,
    modalData,
    onModalClose,
    onWithdrawSubmit,
    defaultWallet,
    latestWithdrawData,
    withdrawBtnDisabled,
    openConfirmSubmitModal,
    setOpenConfirmSubmitModal,
    solanaWallet,
    checkCanWithdraw,
    withdrawErrors,
    setWithdrawErrors,
  } = useContext(Context);

  const { passTypes, packages } = useContext(AppContext);

  const filteredPassTypes = [
    ...passTypes.filter((p) => p.price > 0 && p.active && (p.purchaseWith === 'btc' || p.purchaseWith === 'solana' || p.purchaseWith === 'all')),
    ...packages.filter((p) => p.redeem && p.isActive),
  ];

  const [passTypesLoaded, setPassTypesLoaded] = useState(false);

  const [withdrawCharge, setWithdrawCharge] = useState(0);
  const [minWithdrawAmount, setMinWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawAmount, setWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawType, setWithdrawType] = useState('passes');

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);
  const [selectedIsBundle, setSelectedIsBundle] = useState(false);

  const [todayIsWeekend, setTodayIsWeekend] = useState(false);
  const [todayIsHoliday, setTodayIsHoliday] = useState(false);
  const [solanaInactiveMessage, setSolanaInactiveMessage] = useState('Only one Withdrawal is allowed per day.');
  const [selectedPass, setSelectedPass] = useState(null);

  const handleWithdrawButtonSubmit = () => {
    const passTypeId = selectedPassTypeId || filteredPassTypes[0].id;
    const selectedPassType = filteredPassTypes.find((pt) => pt.id === passTypeId);

    onWithdrawSubmit({
      withdrawType,
      withdrawAmount,
      gamePass: selectedPassType,
    });

    onModalClose();
    setOpenConfirmSubmitModal(false);
  };

  const handleSelectedPassTypeChange = (id) => {
    setSelectedPassTypeId(id);
    const selected = filteredPassTypes.find((pt) => pt.id === id);

    console.log(selected);
    const _priceNumber = (selected?.price || selected?.Price || 0) / 2; 
    const amount = Math.floor(withdrawAmount / _priceNumber);
    setSelectedPass({
      pass: selected,
      amount,
    });

    setMinWithdrawAmount(_priceNumber);
    setWithdrawAmount(_priceNumber);

    setSelectedIsBundle(selected?.Price > 0);
  };

  const availableBalance = defaultWallet.balance - withdrawCharge;
  const maxWithdrawAmount = (Math.floor(availableBalance / minWithdrawAmount) * minWithdrawAmount);

  const getMarks = [
    {
      value: minWithdrawAmount,
      label: `$${renderNumber(minWithdrawAmount)}`,
    },
    {
      value: minWithdrawAmount * ((maxWithdrawAmount / minWithdrawAmount) < 6 ? 2 : 1),
      label: `$${renderNumber(minWithdrawAmount * ((maxWithdrawAmount / minWithdrawAmount) < 6 ? 2 : 1))}`,
    },
    {
      value: Math.ceil((maxWithdrawAmount / minWithdrawAmount) / 2) * minWithdrawAmount,
      label: `$${renderNumber(Math.ceil((maxWithdrawAmount / minWithdrawAmount) / 2) * minWithdrawAmount)}`,
    },
    {
      value: maxWithdrawAmount,
      label: `$${renderNumber(maxWithdrawAmount)}`,
    },
  ];

  const hideSlider = maxWithdrawAmount <= minWithdrawAmount
    || selectedIsBundle
    || ((todayIsWeekend || todayIsHoliday) && withdrawType === 'solana')
    || !!latestWithdrawData;

  const disableRedeemButton = availableBalance < minWithdrawAmount
    || ((todayIsWeekend || todayIsHoliday || !!latestWithdrawData) && withdrawType === 'solana')
    || withdrawBtnDisabled;

  useEffect(() => {
    if (!passTypesLoaded) {
      setPassTypesLoaded(filteredPassTypes?.length > 0);
    }
  }, [filteredPassTypes]);

  useEffect(() => {
    if (passTypesLoaded) {
      handleSelectedPassTypeChange(filteredPassTypes[0]?.id);
    }

    // Charge only for withdrawals
    setWithdrawCharge(withdrawType === 'solana' ? 5 : 0);
    setMinWithdrawAmount((prev) => (withdrawType === 'solana' ? 135 : prev));
    setWithdrawAmount((prev) => (withdrawType === 'solana' ? 135 : prev));
  }, [withdrawType, passTypesLoaded]);

  useEffect(() => {
    setTodayIsWeekend(!dateIsBusinessDay());
    setTodayIsHoliday(!!dateIsHoliday());

    if (dateIsHoliday()) {
      setSolanaInactiveMessage('Withdrawals are not processed on U.S. federal holidays.');
    }

    if (!dateIsBusinessDay()) {
      setSolanaInactiveMessage('Withdrawals are only allowed from Monday to Friday GMT time.');
    }
  }, []);

  const totalPrice = useMemo(() => (selectedPass?.pass?.price || selectedPass?.pass?.Price || 0) + (selectedPass?.pass?.secondHalfPrice || 0), [selectedPass]);

  if (modalData && modalData?.type === modalTypes.WITHDRAW) {
    return (
      <>
        <Modal
          width="700px"
          title={modalData.description}
          open={!!modalData}
          handleClose={onModalClose}
        >
          <Typography
            className="text-center"
            sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
          >
            Choose your packs!
          </Typography>

          {!!defaultWallet && (availableBalance + withdrawCharge) >= withdrawIncrement ? (
            <Container>
              {(withdrawType === 'solana' && user?.canWithdraw)
                && (!solanaWallet ? (
                  <Typography className="text-center">
                    You need to connect your Solana Wallet to the system
                  </Typography>
                ) : (
                  todayIsWeekend || todayIsHoliday || !!latestWithdrawData ? (
                    <p className="mt-5 mb-10 text-center">
                      {solanaInactiveMessage}
                    </p>
                  ) : (
                    <>
                      <Typography className="text-center">
                        {`Transferring $${renderNumber(withdrawAmount)} to your solana Wallet`}
                      </Typography>
                      <Typography className="text-center" style={{ color: 'grey', wordBreak: 'break-all' }}>
                        {` ${solanaWallet.publicKey}`}
                      </Typography>
                      <Typography className="text-center" style={{ paddingTop: '4px', color: 'var(--primary-color)' }}>
                        A $5 service charge will be applied to this transaction.
                      </Typography>
                    </>
                  )
                ))}

              {withdrawType === 'passes' && (selectedPassTypeId || filteredPassTypes.length > 0) && (
                <RadioGroup
                  onChange={(e) => {
                    handleSelectedPassTypeChange(e);
                  }}
                  value={selectedPassTypeId}
                  defaultValue={selectedPassTypeId || filteredPassTypes[0]?.id}
                  setErrors={() => { }}
                  name="selectedPassTypeId"
                  options={filteredPassTypes.map((p) => {
                    const _priceNumber = (p?.price || p?.Price || 0) / 2;
                    const _price = renderNumber(_priceNumber);

                    return (
                      {
                        label: `${p.name} ($${_price}) ${`(${renderNumber(Math.floor(withdrawAmount / _priceNumber))} packs)`}`,
                        value: p.id,
                      }
                    );
                  })}
                />
              )}

              {(user.canWithdraw || withdrawType === 'passes') ? (
                <Grid sx={{ marginTop: 2 }}>
                  <Grid sx={{ paddingX: '10px', display: hideSlider ? 'none' : 'block' }}>
                    {minWithdrawAmount > 0 && (
                      <Slider
                        min={minWithdrawAmount}
                        max={maxWithdrawAmount}
                        value={withdrawAmount}
                        step={minWithdrawAmount}
                        getAriaValueText={() => `$${withdrawAmount}`}
                        valueLabelDisplay="auto"
                        onChange={(e, v) => {
                          setWithdrawAmount(v);
                        }}
                        disabled={hideSlider}
                        marks={getMarks}
                      />
                    )}
                  </Grid>
                  <RadioGroup
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      color: '#FFFFFF',
                    }}
                    onChange={(e) => {
                      setWithdrawType(e);
                    }}
                    value={withdrawType}
                    setErrors={() => { }}
                    name="withdrawType"
                    options={[
                      // { label: 'To Solana Wallet', value: 'solana' },
                      { label: 'Redeem for Coin Packs', value: 'passes' },
                    ]}
                  />
                  <Button
                    type="button"
                    size="md"
                    style={{ width: '100%' }}
                    onClick={() => {
                      const _selected = selectedPass;
                      _selected.amount = Math.floor(withdrawAmount / ((selectedPass?.pass?.price || selectedPass?.pass?.Price || 0) / 2));
                      setSelectedPass(_selected);
                      if (availableBalance >= minWithdrawAmount
                        && !(
                          (todayIsWeekend || !!latestWithdrawData)
                          && withdrawType === 'solana'
                        )
                        && (selectedPassTypeId || filteredPassTypes.length > 0)
                      ) {
                        if (withdrawType === 'solana') {
                          checkCanWithdraw(withdrawAmount);
                        } else {
                          setOpenConfirmSubmitModal(true);
                        }
                      }
                    }}
                    className="mt-6"
                    disabled={disableRedeemButton}
                  >
                    <span className="no-wrap">
                      {availableBalance >= minWithdrawAmount
                        ? `Redeem ($${renderNumber(withdrawAmount + withdrawCharge)})`
                        : 'Redeem'}
                    </span>
                  </Button>

                  {withdrawType === 'solana' && (
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: { xs: '14px', md: '16px' },
                        paddingTop: { xs: 2, md: 3 },
                      }}
                    >
                      {`The Friday of the week following your redemption: ${getNextWeekFriday()}`}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <div>
                  <Typography sx={{ mb: 4 }}>
                    In order to withdraw, we must have a W9 or W-8BEN for international
                    ambassadors. Please fill out the <Link to="/taxes">Upload W9/W-8BEN Form. </Link>
                    Once we have received and processed your W9/W-8BEN, you will be able to
                    perform a withdrawal.
                  </Typography>
                  {/* <Typography sx={{ mb: 4 }}>
                    In order to withdraw, we must have a
                    <a href="https://s3.us-east-1.amazonaws.com/cdn.memegames.ai/downloads/w9.pdf" target="_blank" rel="noreferrer"> W9 </a>
                    on file (Or <a href="mailto:w9@memegames.ai" target="_blank" rel="noreferrer">W-8BEN</a> for international ambassadors).
                    {' '}
                    Please fill out the W9 located in the resources section and send it to <a href="mailto:w9@memegames.ai">w9@memegames.ai</a>.
                    {' '}
                    Once we have received and processed your W9, you will be able to perform a withdrawal.
                  </Typography> */}
                  <Button onClick={() => onModalClose()}>
                    Ok
                  </Button>
                </div>
              )}
            </Container>
          ) : (
            <Typography
              className="text-center"
              sx={{ marginTop: 2, marginBottom: 3 }}
            >
              {`Your current balance is $${renderNumber(availableBalance || 0)}`}
            </Typography>
          )}
        </Modal>
        {openConfirmSubmitModal && (user.canWithdraw || withdrawType === 'passes') && (
          <Modal
            open={openConfirmSubmitModal}
            title={`Are you sure you want to buy ${selectedPass?.amount} x ${selectedPass?.pass?.name}?`}
            handleClose={() => setOpenConfirmSubmitModal()}
            width="550px"
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              textAlign="center"
              marginBottom="40px"
              display="flex"
              flexDirection="column"
            >
              <span>
                {`Do you want to Redeem $${renderNumber(withdrawAmount + withdrawCharge)}
                  ${withdrawType === 'solana' ? 'to your Solana Wallet' : ' from your Wallet'}?
                `}
              </span>
              {withdrawType === 'passes' && (
                <Typography style={{
                  display: 'flex', flexDirection: 'column', gap: '0.2em',
                }}
                >
                  <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px', mt: 1,
                  }}
                  >
                    {`${t('Processing Fee: ')} $${(withdrawAmount + withdrawCharge) * 0.01}`}
                  </Typography>
                  <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    Ambassador wallet cost                    {' '}
                    <span style={{
                      fontWeight: 'bold', color: 'var(--primary-color)', fontSize: '15px',
                    }}
                    >  ${renderNumber(withdrawAmount + withdrawCharge)}
                    </span>
                    .

                  </Typography>
                  <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    This can&apos;t be reversed.
                  </Typography>
                  <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    A second half payment of 25% of the initial promotion payment is due before transfer or activation.
                  </Typography>
                  {/* <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    Another
                    {' '}
                    ${renderNumber(totalPrice * 0.25)}
                    {' '}
                    off-platform transaction is required to activate or transfer this pack.
                  </Typography> */}
                  <Typography sx={{
                    width: '100%', textAlign: 'center', fontSize: '14px',
                  }}
                  >
                    {renderNumber(totalPrice * 0.25 * 2)}  MG Tokens will be awarded when this payment is completed and when this pack is activated
                    {/* Another
                    {' '}
                    ${renderNumber(totalPrice * 0.5)}
                    {' '}
                    is automatically withdrawn from your Ambassador Wallet when funds are available. */}
                  </Typography>
                  <Typography />
                </Typography>
              )}
              <span>
                {withdrawCharge > 0
                  ? `A $${withdrawCharge} service charge for this transaction`
                  : ''}
              </span>
            </Typography>
            <Grid
              item
              xs={12}
              display="flex"
              sx={{
                justifyContent: { xs: 'center', md: 'flex-end' },
                flexDirection: { xs: 'column-reverse', md: 'row' },
                alignItems: 'center',
                gap: { xs: '16px', md: '20px' },
              }}
            >
              <Button
                onClick={() => setOpenConfirmSubmitModal(false)}
                size="sm"
                type="button"
                bordered
                style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              >
                {t('No')}
              </Button>
              <Button
                onClick={() => {
                  if (availableBalance >= minWithdrawAmount) {
                    handleWithdrawButtonSubmit();
                  }
                }}
                size="sm"
                type="button"
                style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              >
                {t('Yes')}
              </Button>
            </Grid>
          </Modal>
        )}

        {withdrawErrors && (
          <Modal
            open={!!withdrawErrors}
            title=""
            handleClose={() => setWithdrawErrors(null)}
            width="550px"
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              textAlign="center"
              marginBottom="40px"
              display="flex"
              flexDirection="column"
            >
              <span>
                {withdrawErrors}
              </span>
            </Typography>
            <Grid
              item
              xs={12}
              display="flex"
              sx={{
                justifyContent: { xs: 'center', md: 'flex-end' },
                flexDirection: { xs: 'column-reverse', md: 'row' },
                alignItems: 'center',
                gap: { xs: '16px', md: '20px' },
              }}
            >
              <Button
                onClick={() => setWithdrawErrors(null)}
                size="sm"
                type="button"
                bordered
                style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              >
                {t('Cancel')}
              </Button>
            </Grid>
          </Modal>
        )}

      </>
    );
  }
}
