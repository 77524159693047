import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { columnsConfig } from './config';
import Table from '../../../../../../components/Table';
import Select from '../../../../../../components/Inputs/Select';
import TableMobile from '../../../../../../components/Table/TableMobile';
import TablePagination from '../../../../../../components/Table/Pagination';
import MembershipsTab from '../../../../../Memberships/components/MembershipsTab';
import { useTransfers } from '../../../context/hooks';
  
const PAGE_SIZE = 10;

export default function Transfers({
  tabsHistoryValue,
  handleTabHistoryChange = () => { },
}) {
  const { t } = useTranslation();
  const { userId } = useParams();
  const columns = useMemo(() => columnsConfig(t), [t]);
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState('all');
  const transfers = useTransfers({
    userId, page: currentPage, direction, perPage: PAGE_SIZE, 
  });

  const handleChange = (selectedFilter) => {
    setDirection(selectedFilter);
  };

  const tabsConfig = [
    {
      label: 'Transfer',
      key: 'transfer',
      isActive: tabsHistoryValue === 0,
      onClick: () => handleTabHistoryChange(0),
    },
    {
      label: 'Purchases',
      key: 'purchases',
      isActive: tabsHistoryValue === 1,
      onClick: () => handleTabHistoryChange(1),
    },
  ];

  return (
    <>
      <div className="flex packs-filters">
        <div className="flex">
          <Select
            className="transfers-filter-select"
            placeholder="Direction"
            size="sm"
            value={direction || 'all'}
            onChange={(e) => handleChange(e)}
            options={[
              { value: 'all', label: 'All' },
              { value: 'sent', label: 'Sent' },
              { value: 'received', label: 'Received' },
            ]}
          />
        </div>

        <div className="flex flex-1 justify-center">
          <div className="flex flex-column justify-center">
            <MembershipsTab config={tabsConfig} />
          </div>
        </div>
        {transfers.data ? (
          <div className="desktop">
            <TablePagination
              count={Math.ceil(transfers.data.total / PAGE_SIZE)}
              page={currentPage}
              onChange={(val, page) => {
                setCurrentPage({ page });
              }}
            />
          </div>
        ) : null}
        
      </div>
      {transfers.data ? (
        <div className="table-cont">
          {transfers.isFetching ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="desktop">
                <Table loading={transfers.isFetching} columns={columns} rows={transfers.data.data} className="table mb-4" />
              </div>
              <div className="mobile">
                <TableMobile columns={columns} data={transfers.data.data} className="mb-6" />
                <TablePagination
                  isMobile
                  count={Math.ceil(transfers.data.total / PAGE_SIZE)}
                  page={currentPage}
                  onChange={(val, page) => {
                    setCurrentPage({ page });
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : null}
      
    </>
  );
}
