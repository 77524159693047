import React, { createContext } from 'react';
import useAdminTransactionsHook from './hook';

export const AdminTransactionsContext = createContext({
  t: () => {},
  user: {},
  filterTransactions: () => {},
  transactionsDataLoading: false,
  transactionsData: [],
  setTransactionsData: () => {},

  openModal: {},
  setOpenModal: () => {},

  handleAmbassadorTransactionFormSubmit: () => {},
  ambassadorTransactionFormLoading: false,
  ambassadorTransactionFormErrors: {},
  setAmbassadorTransactionFormErrors: () => {},

  handleGamerTransactionFormSubmit: () => {},
  gamerTransactionFormLoading: false,
  gamerTransactionFormErrors: {},
  setGamerTransactionFormErrors: () => {},

  handleReverseTransactionModalSubmit: () => {},
  reverseTransactionData: {},
  setReverseTransactionData: () => {},

  selectedUser: {},
  setSelectedUser: () => {},
  walletTypeFilter: 'ambassador',
  setWalletTypeFilter: () => {},
  directionFilter: 'default',
  setDirectionFilter: () => {},
  latestFirstFilter: true,
  setLatestFirstFilter: () => {},
  
  selectedColumnsConfiguration: [],
  walletsData: null,
  setWalletsData: () => {},
});

export const AdminTransactionsProvider = ({ children }) => {
  const value = useAdminTransactionsHook();

  return (
    <AdminTransactionsContext.Provider value={value}>
      {children}
    </AdminTransactionsContext.Provider>
  );
};
