import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  Box, Checkbox, Container, FormControlLabel, Grid, Typography, 
} from '@mui/material';
import Layout from '../../../components/CardLayout';
import { GlobalNotifyContext, GlobalNotifyProvider } from './context';
import NotifyListRow from './components/NotifyListRow';
import AddEditGlobalNotifyModal from './components/AddEditGlobalNotifyModal';
import DeleteGlobalNotifyModal from './components/DeleteGlobalNotifyModal';
import ComponentLoader from '../../../components/ComponentLoader';
import Select from '../../../components/Inputs/Select';
import CustomAutocomplete from '../../../components/Inputs/CustomAutocomplete';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';

const AdminGlobalNotifyPage = () => {
  const { navigate } = useNavigate();
  const {
    t, user, allGlobalNotifyData, allGlobalNotifyDataLoaded, fetchGlobalNotifyData, 
  } = useContext(GlobalNotifyContext);

  const [usersFilter, setUsersFilter] = useState();
  const [userTypeFilter, setUserTypeFilter] = useState('default');
  const [textFilter, setTextFilter] = useState();
  const [latestFirst, setLatestFirst] = useState(false);

  const handleChangeLatest = () => {
    setLatestFirst((prev) => !prev);
  };

  const filterRecords = () => {
    const filter = {};
    filter.search = textFilter;

    if (usersFilter) {
      filter.userId = usersFilter.id;
    }

    if (userTypeFilter !== 'default') {
      filter.userType = userTypeFilter;
    }

    if (latestFirst) {
      filter.latestFirst = true;
    }

    fetchGlobalNotifyData(filter);
  };

  if (!user) {
    navigate('/landing');
  }

  useEffect(() => {
    fetchGlobalNotifyData({});
  }, []);

  useEffect(() => {
    filterRecords();
  }, [usersFilter, userTypeFilter, latestFirst]);

  return (
    <Container className="admin-notify-page">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
            marginBottom: '16px',
          }}
        >
          <span className="primary-text-gradient">
            {t('Global Notify Administration')}
          </span>
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex', justifyContent: 'center', paddingTop: '14px', paddingBottom: '26px', 
          }}
        >
          <AddEditGlobalNotifyModal />
          <DeleteGlobalNotifyModal />
        </Grid>
        <Grid
          container
          className="notifications-filters"
          display="flex"
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: 'flex',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <TextInput
                className="search-input"
                size="xsm"
                placeholder="What would you like to search for?"
                value={textFilter}
                onChange={(e) => {
                  setTextFilter(e);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') filterRecords();
                }}
                endAdornment={(
                  <Button onClick={filterRecords} className="py-auto my-auto mr-5" bordered size="sm">
                    <span className="no-wrap">Search</span>
                  </Button>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ display: 'flex', gap: '1.5rem', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ width: { md: '50%' } }}>
              <CustomAutocomplete
                endPoint="/users"
                name="users"
                mapper={(item) => ({ id: item.SID, label: item.Username })}
                value={usersFilter}
                onChange={(v) => setUsersFilter(v)}
                disabled={false}
                error={false}
                size="sm"
                placeholder="By User"
              />
            </Box>
            <Box sx={{ width: { md: '50%' } }}>
              <Select
                className="user-type-filter-select"
                placeholder="User Type"
                size="sm"
                value={userTypeFilter}
                onChange={(e) => setUserTypeFilter(e)}
                options={[
                  { value: 'ambassador', label: 'Ambassadors' },
                  { value: 'gamer', label: 'Gamers' },
                ]}
                sx={{ paddingRight: '1rem' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: '1.5rem' }}>
            <Box>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="SortDate"
                    value={latestFirst}
                    checked={latestFirst}
                    onChange={handleChangeLatest}
                    sx={{ color: 'white', fontSize: '12px !important' }}
                  />
                )}
                label={(
                  <div className="flex">
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        letterSpacing: '1.2px',
                      }}
                    >
                      {t('Latests First')}
                    </Typography>
                  </div>
                )}
              />
            </Box>
          </Grid>
        </Grid>
        
        {allGlobalNotifyDataLoaded ? (
          <>
            <Grid container marginTop={6}>
              <Grid item xs={8} md={10}>
                <Typography fontSize="16px" fontWeight="600">
                  Messages
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display="flex" justifyContent="center">
                <Typography fontSize="16px" fontWeight="600">
                  Actions
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="divider" />
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
              }}
            >
              {allGlobalNotifyData?.map((notify, idx) => <NotifyListRow key={idx} notify={notify} />)}
            </Grid>
          </>
        ) : (
          <Grid container marginTop={6}>
            <ComponentLoader />
          </Grid>
        )}
      </Layout>
    </Container>
  );
};

export default () => (
  <GlobalNotifyProvider>
    <AdminGlobalNotifyPage />
  </GlobalNotifyProvider>
);
