import { Typography } from '@mui/material';
import { useState } from 'react';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';

export default function Email({
  onSubmitEmail, errors, setErrors, loading,
}) {
  const [text, setText] = useState('');

  return (
    <>
      <Typography sx={{
        display: 'flex', justifyContent: 'start', color: 'white', mb: 2,
      }}
      >
        Email
      </Typography>
      <TextInput value={text} onChange={(e) => { setErrors(); setText(e); }} />
      {
        errors ? (
          <Typography sx={{
            color: 'brown', fontSize: '12px', display: 'flex', justifyContent: 'center',
          }}
          >
            {errors}
            {' '}
          </Typography>
        ) : ''
      }
      <Button
        onClick={() => {
          onSubmitEmail(text);
        }}
        style={{ marginTop: '25px' }}
        disabled={loading}
      >
        Submit
      </Button>
    </>
  );
}
