import dayjs from 'dayjs';
import Button from '../../../components/Button';
import { renderNumber } from '../../../utils/string';

export const ambassadorTransactionFormConfig = [
  {
    name: 'action',
    type: 'select',
    label: 'Action',
    options: [
      { value: 'withdraw', label: 'Withdraw' },
      { value: 'transfer', label: 'Transfer' },
      { value: 'commission', label: 'Commission' },
      { value: 'expired', label: 'Expired' },
      { value: 'purchase-fee', label: 'Purchase Fee' },
      { value: 'transfer-fee', label: 'Transfer Fee' },
      { value: 'withdraw-fee', label: 'Withdraw Fee' },
    ],
  },
  {
    name: 'direction',
    type: 'select',
    label: 'Transaction Type (Direction)',
    options: [
      { value: 'receive', label: 'Received' },
      { value: 'deducted', label: 'Deducted' },
    ],
  },
  {
    name: 'amount',
    type: 'money',
    label: 'Amount',
    placeholder: '0',
    required: true,
    allowDecimals: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'What will the user see?',
    required: true,
  },
  {
    name: 'justification',
    type: 'textarea',
    label: 'Justification',
    placeholder: 'Why are we doing this manual transaction?',
    required: true,
    rows: 3,
  },
  {
    name: 'actionForDate',
    type: 'date',
    label: 'Action for Date',
  },
  {
    name: 'changeWalletAmount',
    type: 'muiCheckbox',
    label: 'Change the Wallet amount',
    checked: true,
  },
];

export const ambassadorTransactionFormDefaultValues = {
  action: 'commission',
  direction: 'receive',
  amount: 0,
  description: '',
  justification: '',
  actionForDate: new Date(),
  changeWalletAmount: true,
};

export const ambassadorTransactionFormValidationConfig = {
  action: { message: 'Action is required' },
  direction: { message: 'Direction Type is required' },
  amount: { message: 'Amount is required' },
  description: { message: 'Description is required' },
  justification: { message: 'Justification is required' },
};

export const gamerTransactionFormConfig = [
  {
    name: 'action',
    type: 'select',
    label: 'Action',
    options: [
      { value: 'played', label: 'Played' },
      { value: 'transfer', label: 'Transfer' },
      { value: 'receive', label: 'Receive' },
      { value: 'transaction', label: 'Transaction' },
      { value: 'redeem', label: 'Redeem' },
      { value: 'expired', label: 'Expired' },
    ], 
  },
  {
    name: 'direction',
    type: 'select',
    label: 'Transaction Type (Direction)',
    options: [
      { value: 'receive', label: 'Received' },
      { value: 'deducted', label: 'Deducted' },
    ], 
  },
  {
    name: 'type',
    type: 'select',
    label: 'MG Coin/MG Token',
    options: [
      { value: 'Coins', label: 'Coins' },
      { value: 'Tokens', label: 'Tokens' },
      { value: 'CoinPack', label: 'CoinPack' },
    ],
  },
  {
    name: 'amount',
    type: 'number',
    label: 'Amount',
    placeholder: '0',
    required: true,
    allowDecimals: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'What will the user see?',
    required: true,
  },
  {
    name: 'justification',
    type: 'textarea',
    label: 'Justification',
    placeholder: 'Why are we doing this manual transaction?',
    required: true,
    rows: 3,
  },
  {
    name: 'changeWalletAmount',
    type: 'muiCheckbox',
    label: 'Change the Wallet amount',
    checked: true,
  },
];

export const gamerTransactionFormDefaultValues = {
  action: 'transaction',
  direction: 'receive',
  type: 'Coins',
  amount: 0,
  description: '',
  justification: '',
  changeWalletAmount: true,
};

export const gamerTransactionFormValidationConfig = {
  action: { message: 'Action is required' },
  direction: { message: 'Transaction Type is required' },
  type: { message: 'MG Coin/MG Token field is required' },
  amount: { message: 'Amount is required' },
  description: { message: 'Description is required' },
  justification: { message: 'Justification is required' },
};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const getAmbassadorColumnsConfig = (t, handleReverseButtonClick) => [
  {
    field: 'amount',
    headerName: t('Amount'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <span className={row.transaction.direction}>
        {`$${renderNumber(row.transaction.amount)}`}
      </span>
    ),
  },
  {
    field: 'action',
    headerName: t('Action'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => row.transaction.action,
  },
  {
    field: 'description',
    headerName: t('Description'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => row.transaction.description,
  },
  {
    field: 'date',
    headerName: t('Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => dayjs(row.transaction.actionForDate).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 180,
    ...defaultColConfig,
    renderCell: ({ row }) => (row.reversed
      ? (`Reversed By: ${row.reversedBy}`)
      : (<Button onClick={() => handleReverseButtonClick(row)} size="sm">Reverse</Button>)),
  },
];

export const getGamerColumnsConfig = (t, handleReverseButtonClick) => [
  {
    field: 'amount',
    headerName: t('Amount'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <span className={row.transaction.direction}>
        {`${renderNumber(row.transaction.mgCoins || row.transaction.token)}`}
      </span>
    ),
  },
  {
    field: 'type',
    headerName: t('Type'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => row.transaction.type,
  },
  {
    field: 'description',
    headerName: t('Description'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => row.transaction.description,
  },
  {
    field: 'date',
    headerName: t('Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => dayjs(row.transaction.actionForDate).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 180,
    ...defaultColConfig,
    renderCell: ({ row }) => (row.reversed
      ? (`Reversed By: ${row.reversedBy}`)
      : (<Button onClick={() => handleReverseButtonClick(row)} size="sm">Reverse</Button>)),
  },
];
