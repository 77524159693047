import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { defaultFormConfig, defaultFormValidationConfig, defaultFormValues } from './config';
import { validateAll } from '../Form/config';

const useCreateLinkModalHook = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleModalSubmit = useCallback(async (data) => {
    setFormErrors(null);

    const checkForm = validateAll(data, defaultFormValidationConfig);
    if (checkForm) {
      setFormErrors(checkForm);
      return;
    }

    const { link, text } = data;

    const anchor = `<a href="${link}">${text}</a>`;
    copy(anchor);

    toast.success('Link copied to clipboard');

    handleCloseModal();
  }, [handleCloseModal]);

  const formConfig = useMemo(() => defaultFormConfig, []);
  const defaultValues = useMemo(() => defaultFormValues, []);

  return {
    isOpen,
    handleOpenModal,
    handleCloseModal,
    handleModalSubmit,
    formConfig,
    defaultValues,
    formErrors,
    setFormErrors,
  };
};

export default useCreateLinkModalHook;
