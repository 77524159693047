import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import useApi from '../../../../utils/api';

const SimulatorSettingsHook = () => {
  const { t } = useTranslation();
  const { authPost } = useApi();
  const queryClient = useQueryClient();

  const updateSimulatorSettings = useMutation({
    mutationFn: async (data) => {
      const response = await authPost('simulator/settings', { data });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['simulator-settings'] });
      toast.success('Settings successful updated');
    },
    onError: (error) => {
      console.log(error);
      toast.error('Error updating settings');
    },
  });

  const handleSettingsFormSubmit = useCallback(
    async (data) => updateSimulatorSettings.mutate(data),
    [updateSimulatorSettings],
  );

  return {
    t,
    updateSimulatorSettings,
    handleSettingsFormSubmit,
  };
};

export default SimulatorSettingsHook;
