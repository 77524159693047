import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { defaultRedeemColumnsConfig } from '../config';

const usePendingRedeemApi = () => {
  const { authGet, authPut } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [redemptionsData, setRedemptionsData] = useState([]);
  const [loadedRedemptionsData, setLoadedRedemptionsData] = useState(false);
  const [redeemDestinationData, setRedeemDestinationData] = useState(null);
  const [submittingRedeemModal, setSubmittingRedeemModal] = useState(false);
  const [userSIDUserName, setUserSIDUserName] = useState(undefined);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [redemptionCancelDataModal, setRedemptionCancelDataModal] = useState(null);
  const [submittingCancelRedemptionModal, setSubmittingCancelRedemptionModal] = useState(false);

  const fetchRedeemData = useCallback(
    async () => {
      setLoadedRedemptionsData(false);
      const response = await authGet(`/redemptions/pending?perPage=${perPage}&page=${page}${userSIDUserName ? `&user=${userSIDUserName}` : ''}`);

      setRedemptionsData(response);

      setLoadedRedemptionsData(true);
    },
    [authGet, perPage, page, userSIDUserName],
  );

  const fetchPaymentReportData = useCallback(
    async () => {
      const response = await authGet('redemptions/pending/csv');

      return response || [];
    },
    [authGet],
  );

  const handlePayRedeemSubmitModal = useCallback(
    async (memeCoinsAmount) => {
      if (!memeCoinsAmount) {
        toast.error('Missing Meme Coins amount.');
        return;
      }
      setSubmittingRedeemModal(true);
      if (redeemDestinationData) {
        try {
          await authPut(`/redemptions/${redeemDestinationData.redeemId}/completed`, { data: { memeCoinsAmount } });

          setRedeemDestinationData(null);

          fetchRedeemData();

          toast.success(`Marked as completed with Meme Coins amount: ${memeCoinsAmount}`);
        } catch (err) {
          toast.error('Error trying to mark the redeem as Paid.');
        }
      }
      setSubmittingRedeemModal(false);
    },
    [authPut, redeemDestinationData],
  );

  const handleCancelRedemptionSubmitModal = useCallback(
    async (notification) => {
      if (!notification) {
        toast.error('Missing Notification.');
        return;
      }

      setSubmittingCancelRedemptionModal(true);

      if (redemptionCancelDataModal) {
        try {
          await authPut(`/redemptions/${redemptionCancelDataModal.redeemId}/cancel`, { data: { notification } });

          setRedemptionCancelDataModal(null);

          fetchRedeemData();

          toast.success('Canceled transaction');
        } catch (err) {
          toast.error('Error trying to mark the redemption as Canceled.');
        }
      }

      setSubmittingCancelRedemptionModal(false);
    },
    [authPut, redemptionCancelDataModal],
  );

  const handlePayRedeemCloseModal = useCallback(() => {
    setRedeemDestinationData(null);
  }, [setRedeemDestinationData]);

  const handleCancelRedemptionCloseModal = useCallback(() => {
    setRedemptionCancelDataModal(null);
  }, [setRedemptionCancelDataModal]);

  useEffect(() => {
    fetchRedeemData();
  }, []);

  // Columns Configuration ===================================
  const redemptionsDataColumnsConfiguration = useMemo(
    () => defaultRedeemColumnsConfig(t, setRedeemDestinationData, setRedemptionCancelDataModal),
    [t, setRedeemDestinationData, setRedemptionCancelDataModal],
  );
  // Columns Configuration ===================================

  return {
    t,
    user,
    userSIDUserName,
    setUserSIDUserName,
    perPage,
    setPerPage,
    page,
    setPage,

    fetchRedeemData,
    redemptionsDataColumnsConfiguration,
    redemptionsData,
    loadedRedemptionsData,

    redeemDestinationData,
    submittingRedeemModal,
    setRedeemDestinationData,
    handlePayRedeemSubmitModal,
    handlePayRedeemCloseModal,

    redemptionCancelDataModal,
    submittingCancelRedemptionModal,
    setRedemptionCancelDataModal,
    handleCancelRedemptionSubmitModal,
    handleCancelRedemptionCloseModal,

    fetchPaymentReportData,
  };
};

export default usePendingRedeemApi;
