import { useContext } from 'react';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { renderNumber } from '../../../utils/string';

const CancelWithdrawModal = () => {
  const {
    onCancelWithdrawal,
    currentCancelWithdraw,
    showCancelWithdrawModal,
    setShowCancelWithdrawModal,
    t,
  } = useContext(Context);

  return (
    <Modal
      title="Are you sure you want to cancel this withdrawal?"
      width="550px"
      open={showCancelWithdrawModal}
      handleClose={() => setShowCancelWithdrawModal(false)}
    >
      {currentCancelWithdraw ? (
        <> 
          <Grid item className="flex" justifyContent="center" position="relative" top="-20px" mb={1}>
            <span className="primary-text-gradient">{`${t('Date :')} ${dayjs(currentCancelWithdraw.createdAt).tz('GMT').format('lll')}`}</span>
          </Grid>
          <Grid item className="flex" justifyContent="center" position="relative" top="-20px" mb={1}>
            <span className="primary-text-gradient">{`${t('Amount :')} $${renderNumber(currentCancelWithdraw.amount)}`}</span>
          </Grid>
          <Grid item className="flex" justifyContent="center" position="relative" top="-20px" mb={1}>
            <span className="primary-text-gradient">{`${t('Fee :')} $5`}</span>
          </Grid>
        </>
      ) : null}
     
      <Button
        onClick={() => {
          onCancelWithdrawal(currentCancelWithdraw.id);
        }}
        className="mb-5"
      >
        <span className="no-wrap">Yes, Cancel</span>
      </Button>
    </Modal>
  );
};

export default CancelWithdrawModal;
