/* eslint-disable no-unused-vars */
import {
  useContext, useState, useCallback, useEffect, 
} from 'react';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { AppContext } from '../../../../utils/store/app';

export default function usePurchasePackage(reset) {
  const { user } = useContext(AuthenticationContext);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renewedMsg, setRenewedMsg] = useState('');
  const [purchaseData, setPurchaseData] = useState(null);
  const { authPost, authDelete } = useApi();
  const [defaultValue, setDefaultValue] = useState({});
  const [activePackages, setActivePackages] = useState([]);
  const { packages } = useContext(AppContext);

  useEffect(() => {
    const activePacks = packages?.filter((pack) => pack.isActive);

    if (activePacks.length > 0) {
      setDefaultValue({ package: activePacks[0]?.id });
      setActivePackages(activePacks);
    }
  }, [packages]);

  const handleModalClose = async (canceled = true) => {
    try {
      const _solana = sessionStorage.getItem('_solana_pay_transaction_id');

      if (canceled && !_solana) {
        const _puid = sessionStorage.getItem('_puid');
        if (_puid) {
          await authDelete(`/payments/passes/remove/${_puid}`);
        }
      } else {
        if (!_solana) {
          await authPost('/payments/passes/free', { data: { ...purchaseData, SID: user.SID } });
        }
        sessionStorage.removeItem('_solana_pay_transaction_id');
      }
    } catch (err) {
      console.log(err);
    }
    setPurchaseData(null);
    setErrors(null);
    sessionStorage.removeItem('_puid');
    if (reset) {
      reset();
    }
  };
  
  const submitPurchase = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        if (!data?.package) {
          setErrors({ package: 'Please choose a Bundle from the list!' });
        } else {
          const result = await authPost('/payments/package/start', {
            data: { ...data, SID: user.SID },
          });
          // setPurchaseData({ ...data });
          setPurchaseData(result);
          sessionStorage.setItem('_puid', result.PUID);
          setErrors(null);
        }
        setIsLoading(false);
      } catch (err) {
        if (err === 'Invalid pass token') {
          setErrors({ pass: err });
        } else {
          setErrors({ pass: 'An error occurred. Please try again later!' });
        }
        setIsLoading(false);
      }
    },
    [authPost],
  );

  return {
    purchaseData,
    submitPurchase,
    isLoading,
    setIsLoading,
    errors,
    setErrors,
    renewedMsg,
    setRenewedMsg,
    handleModalClose,
    defaultValue,
    packages,
    activePackages,
  };
}
