import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { HelioCheckout } from '@heliofi/checkout-react';
import Modal from '../../../../../components/Modal';
import useSolanaPay from '../../../../../utils/hooks/useSolanaPay';

export default function PurchasePassModal({
  data, handleModalClose,
}) {
  const [loading, setLoading] = useState(false);
  const serverIpn = `${process.env.REACT_APP_BASE_URL}/payments/package/ipn/${data.PUID}`;
  const uri = window.location.href;
  const uriParts = new URL(uri);
  const { origin } = uriParts;
  const redirect = `${origin}/packages`;
  const helioCCEnabled = process.env.REACT_APP_SOLANA_PAY_CREDIT_CARD === 'true';
  // const redirect = `${process.env.REACT_APP_HOST}/packages`;

  const buyFreePackage = () => {
    setLoading(true);
    handleModalClose(false);
  };

  const PayWithCardConfig = useSolanaPay({
    data,
    handleModalClose,
    payWithCard: true,
  });

  const PayWithCryptoConfig = useSolanaPay({
    data,
    handleModalClose,
  });

  const btcIsEnabled = process.env.REACT_APP_BTC_ENABLED === 'true';

  return (
    <Modal
      title="Purchase Bundle confirmation!"
      width="550px"
      open={!!data}
      handleClose={handleModalClose}
    >
      <div style={{ position: 'relative' }}>
        {loading && (
          <div
            className="flex justify-center"
            style={{ position: 'absolute', left: '50%' }}
          >
            <CircularProgress />
          </div>
        )}
        <h1
          style={{ textAlign: 'center', fontWeight: 'bold' }}
        >
          {`"${data.package.name}"`}
        </h1>
        {data.priceTotal >= 1 ? (
          <>
            <h3 style={{ textAlign: 'center' }}>{'This can\'t be reversed'}</h3>
            {btcIsEnabled && (
              <form
                className="form"
                method="POST"
                action={process.env.REACT_APP_BTC_PAY}
              >
                <input type="hidden" name="orderId" value={data.PUID} />
                <input
                  type="hidden"
                  name="storeId"
                  value={process.env.REACT_APP_BTC_STORE_ID}
                />
                <input
                  type="hidden"
                  name="checkoutDesc"
                  value={data.Description}
                />
                <input type="hidden" name="serverIpn" value={serverIpn} />
                <input type="hidden" name="browserRedirect" value={redirect} />
                <input type="hidden" name="price" value={data.priceTotal} />
                <input type="hidden" name="currency" value="USD" />
                <button
                  style={{ alignSelf: 'center', border: 'none' }}
                  size="md"
                  className="btn md default normal"
                  type="submit"
                  name="purchasePass"
                >
                  Purchase with BTC
                </button>
              </form>
            )}
            {PayWithCardConfig.helioIsEnabled && (
              <>
                {helioCCEnabled && (
                  <div className="solana-button">
                    <HelioCheckout config={PayWithCardConfig.helioConfig} />
                  </div>
                )}
                <div className="solana-button">
                  <HelioCheckout config={PayWithCryptoConfig.helioConfig} />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="form">
            <button
              style={{
                alignSelf: 'center',
                border: 'none',
                opacity: `${loading ? 0.6 : 1}`,
                cursor: `${loading ? 'default' : 'pointer'}`,
              }}
              size="md"
              className="btn md default normal"
              type="submit"
              name="purchasePass"
              onClick={buyFreePackage}
              disabled={loading}
            >
              {`Purchase ($${data.price})`}
            </button>
          </div>
        )}
      </div>
      {' '}
    </Modal>
  );
}
