import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Transfers from './Transfers';
import PurchaseHistory from './PurchasesHistory';
import CoinPacks from './CoinPacks';

export default function CoinPackHistory() {
  const { t } = useTranslation();
  const [tabsHistoryValue, setTabsHistoryValue] = useState(0);
  return (
    <>
      
      <div className="">

        <CoinPacks />
  
      </div>
      <div className="coin-packs-card" style={{ paddingLeft: '0px', paddingRight: '0px' }}>

        {tabsHistoryValue === 0 ? (
          <Transfers
            tabsHistoryValue={tabsHistoryValue}
            handleTabHistoryChange={setTabsHistoryValue}
          />
        ) : (
          <PurchaseHistory
            tabsHistoryValue={tabsHistoryValue}
            handleTabHistoryChange={setTabsHistoryValue}
          />
        )}
      </div>
    </>
    
  );
}
