import { Container, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Layout from '../../../components/CardLayout';

export default function Coin({ data, onEdit = () => { }, onDelete = () => { } }) {
  return (
  // <Layout>
    <div style={{
      display: 'flex',
      position: 'relative',
      gap: 20,
      alignItems: 'center',
      background: 'linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%)',
      borderRadius: '20px',
      padding: '20px',
      margin: '10px',
    }}
    >
      <div style={{
        position: 'absolute', right: '10px', top: '10px', display: 'flex', gap: 20, 
      }}
      >
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onEdit(data);
          }}
        />

        <DeleteIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onDelete(data);
          }}
        />
      </div>
        
      {
        data.image ? (
          <div>
            <img style={{ height: '60px', borderRadius: '40px' }} alt={data.name} src={data.image} />
          </div>
        ) : null
      }
        
      <div>
        <Typography
          sx={{
            fontWeight: 600,
            color: 'var(--primary-color)',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className="primary-text-gradient">{data.name}</span>
          <div style={{
            display: 'flex',
            gap: 3,
          }}
          />

        </Typography>
        <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>

        <Typography>
          API ID:
          <span className="pl-2">{data.apiId}</span>
        </Typography>
      </div>
    </div>

  // </Layout>
  );
}
