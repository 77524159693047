import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/CardLayout';
import Button from '../../components/Button';
import ComponentLoader from '../../components/ComponentLoader';
import { useAuthentication } from '../../utils/store';
import { useSimulatorRules } from '../SimulatorAdmin/SimulatorRules/context/hook';

const SimulatorRulesPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const { t } = useTranslation();
  const simulatorRules = useSimulatorRules();

  return (
    <Container className="blog-post-page" maxWidth="lg">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mt: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {simulatorRules.data && simulatorRules.data.data && simulatorRules.data.data.title ? (simulatorRules.data.data.title 
            ) : (
              <span>Simulator Rules</span>
            )}
          </span>
        </Typography>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: { xs: 'center', md: 'space-between' },
            paddingTop: '14px',
            gap: '12px',
          }}
        >
          <Grid
            item
            style={{
              padding: '6px 0',
              display: 'flex',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <Button size="sm" onClick={() => navigate('/simulator')} bordered>
              <span>{t('Go to Simulator')}</span>
            </Button>
            {user.Role === 'admin' && !!simulatorRules.data && (
              <Button
                size="sm"
                onClick={() => navigate('/simulator-admin/rules')}
                bordered
              >
                <span>{t('Edit')}</span>
              </Button>
            )}
          </Grid>
  
        </Grid>

        <Grid style={{ padding: '0px 0' }}>
          <div className="divider" />
        </Grid>

        {(!simulatorRules.isFetching) ? (
          <Grid style={{ padding: '24px 0' }}>
            {simulatorRules.data && simulatorRules.data.data && simulatorRules.data.data.content ? (
              <div
                className="richContent"
                dangerouslySetInnerHTML={{ __html: simulatorRules.data.data.content }}
              />
            ) : null}
            
          </Grid>
        ) : (
          <ComponentLoader />
        )}
       
      </Layout>
    </Container>
  );
};

export default SimulatorRulesPage;
