/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useCallback, useMemo, useState,
} from 'react';
import debounce from 'lodash/debounce';
import useApi from '../../../utils/api';

export default function CustomAutocomplete({
  name = '',
  value = '',
  focused = false,
  placeholder = 'Type to Search ...',
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  size = 'md',
  endPoint = '/',
  mapper = (val) => val,
  disabled = false,
  error,
  _debounce = 500,
  qs = '',
}) {
  const { authGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const fetchOptions = useCallback(async (_searchInput) => {
    setLoading(true);
    try {
      const res = await authGet(`${endPoint}${_searchInput ? `?search=${encodeURIComponent(_searchInput)}&${qs}` : qs ? `?${qs}` : ''}`);
      if (Array.isArray(res)) {
        setOptions(res.map(mapper));
      } else if (Array.isArray(res?.data)) {
        setOptions(res.data.map(mapper));
      } else if (Array.isArray(Object.keys(res.data))) {
        setOptions(Object.keys(res.data).map(mapper));
      }
    } catch (err) {
      console.error('Error fetching options:', err);
    } finally {
      setLoading(false);
    }
  }, [authGet, endPoint, mapper, qs]);

  const onDebounce = useMemo(() => {
    const func = (_value) => {
      fetchOptions(_value);
    };
    return debounce(func, _debounce);
  }, [_debounce, fetchOptions]);

  const onChangeInput = useCallback((newInputValue) => {
    setSearchInput(newInputValue);
    onDebounce(newInputValue);
  }, [onDebounce]);

  return (
    <div className="form-input select-input flex-col">
      <Autocomplete
        id={name}
        value={value || null}
        defaultValue={value || null}
        onChange={(_, newValue) => {
          onChange(newValue || '');
        }}
        inputValue={searchInput}
        onInputChange={(_, newInputValue) => {
          onChangeInput(newInputValue);
        }}
        sx={{
          '.MuiOutlinedInput-root': {
            padding: '5px',
            borderRadius: '8px',
          },
        }}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        options={options}
        placeholder={placeholder}
        loading={loading}
        isOptionEqualToValue={(option) => option.label}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            className={clsx('select', focused && 'focused', size, error ? 'error' : 'clean', 'besaaa')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),

            }}
            // error={Boolean(error)}
            // helperText={error}
            disabled={disabled}
            // sx={{ mt: -1 }}
            sx={{
              '&.MuiFormLabel-root': {
                mt: '-5px',
              },
            }}
          />
        )}
      />
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

CustomAutocomplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  mapper: PropTypes.func,
  onChange: PropTypes.func,
};
