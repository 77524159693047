import { Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import ComponentLoader from '../../../components/ComponentLoader';
import { AdminBlogContext, AdminBlogProvider } from './context';
import PostTagCheckbox from './components/PostTag/PostTagCheckbox';
import Form from '../../../components/Form';
import { blogEditFormConfig } from './config';

const AdminBlogEditPostPage = () => {
  const { postSlug } = useParams();
  const {
    t,
    handleAddEditPostFormSubmit,
    postFormSubmitting,
    postFormErrors,
    setPostFormErrors,
    fetchPostSingleData,
    postSingle,
    postSingleLoaded,
    handleCancelEditPostButtonClick,
  } = useContext(AdminBlogContext);

  useEffect(() => {
    fetchPostSingleData(postSlug);
  }, [fetchPostSingleData]);

  return (
    <Container className="admin-blog-page edit" maxWidth="xl">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('Edit Post')}
          </span>
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '14px',
            gap: '24px',
          }}
        >
          <Button size="sm" onClick={handleCancelEditPostButtonClick} bordered>
            <span>{t('Go Back')}</span>
          </Button>
          <Button
            size="sm"
            onClick={() => {
              window.open(`/blog/post/${postSlug}`, '_blank');
            }}
            bordered
          >
            <span>{t('View Post')}</span>
          </Button>
          
        </Grid>

        <Grid item xs={12} mt={6}>
          <div className="divider" />
        </Grid>

        <Grid container pt={2} mb={4} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Grid item xs={12} lg={9} paddingRight={{ lg: 4 }}>
            {(postSlug && postSingleLoaded) ? (
              <Form
                size="md"
                defaultFormValue={postSingle}
                config={blogEditFormConfig}
                onSubmit={handleAddEditPostFormSubmit}
                errors={postFormErrors}
                setErrors={setPostFormErrors}
                buttonType="button"
                buttonStyle={{ width: '100%', marginTop: 0 }}
                submitLabel={t('Save')}
                formLoading={postFormSubmitting}
              />
            ) : (
              <ComponentLoader />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            gap={4}
            display="flex"
            flexDirection="column"
            sx={{ marginY: { xs: '32px', lg: '0' } }}
          >
            <div className="relations">
              <Typography
                fontSize="16px"
                fontWeight={600}
              >
                <span>Select Tags</span>
              </Typography>
              <div className="divider" />
              <PostTagCheckbox />
            </div>
            {(postSlug && postSingleLoaded && postSingle.imageUrl) ? (
              <div>
                <Typography
                  fontSize="16px"
                  fontWeight={600}
                >
                  <span>Current Image</span>
                </Typography>
                <div className="divider" />
                <img
                  src={postSingle.imageUrl}
                  alt="current post"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '4px',
                    marginTop: '16px',
                  }}
                />
              </div>
            ) : (
              null
            )}
          </Grid>
          
        </Grid>
      </Layout>
    </Container>
  );
};

export default () => (
  <AdminBlogProvider>
    <AdminBlogEditPostPage />
  </AdminBlogProvider>
);
