import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PhoneInput from '../../../../components/Inputs/PhoneInput';

const UserAccordionHeader = ({
  name, mobile, activation, email, pack, rank, isDeleted,
}) => (
  <Grid
    container
    max-width="100%"
    className="user-accordion-body"
    sx={{ p: 0 }}
  >
    <Grid item xs={6} lg={4} className="info">
      <Typography className="title">Name</Typography>
      <Typography className="description">{name}</Typography>
    </Grid>
    <Grid item xs={6} lg={4} className="info">
      <Typography className="title">Mobile</Typography>
      {!isDeleted && (
        <Typography variant="span" className="description">
          <PhoneInput value={mobile} disabled />
        </Typography>
      )}

    </Grid>
    <Grid item xs={6} lg={4} className="info">
      <Typography className="title">Activation</Typography>
      <Typography className="description">{dayjs(activation).tz('GMT').format('MMM DD, YYYY')}</Typography>
    </Grid>
    <Grid item xs={6} lg={4} className="info">
      <Typography className="title">Email</Typography>
      {!isDeleted && (
        <Typography className="description">{email}</Typography>
      )}
    </Grid>
    <Grid item xs={6} lg={4} className="info">
      <Typography className="title">Coin Pack</Typography>
      <Typography className="description">{pack}</Typography>
    </Grid>
    {rank && (
      <Grid item xs={6} lg={4} className="info">
        <Typography className="title">Rank</Typography>
        <Typography className="description">{rank}</Typography>
      </Grid>
    )}
  </Grid>
);

export default UserAccordionHeader;
