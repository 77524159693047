import ListView from './ListView';
import GridView from './GridView';
import { useBreakpoint } from '../../../../../utils/store';

export default function MyCoinPicks() {
  const { isMobile, isTablet } = useBreakpoint();
  return (
    (isMobile && isTablet) ? <ListView /> : <GridView />
  );
}
