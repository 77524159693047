import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import Modal from '../../../../components/Modal';
import { AdminTaxContext } from '../context';
import Button from '../../../../components/Button';

const TaxFormReviewModal = () => {
  const {
    t,
    taxFormReviewData,
    handleReviewModalSubmit,
    handleReviewModalClose,
  } = useContext(AdminTaxContext);

  return (
    <Modal
      title="Review Tax Form Data"
      open={!!taxFormReviewData}
      handleClose={handleReviewModalClose}
      width="500px"
    >
      {taxFormReviewData && (
        <>
          <Typography
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
          >
            <span>{t('Mark this Tax Form as reviewed')}</span>
          </Typography>

          <Typography
            fontSize="16px"
            textAlign="center"
            margin="24px 0"
          >
            <span className="primary-text-gradient">
              {`${taxFormReviewData.username} - ${taxFormReviewData.formType}`}
            </span>
          </Typography>

          <Button onClick={() => handleReviewModalSubmit(taxFormReviewData.id)}>
            {t('Submit')}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default TaxFormReviewModal;
