import { useEffect, useState } from 'react';
import {
  Container, Grid, Slider, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import ChangeBadges from '../../../components/ChangeBadges';
import { useBreakpoint } from '../../../utils/store'; 
import {
  convertCoinsToMgTokens, getMarks, 
  useSimulatorWithdrawCoins, calcMinCoinsToBeMgToken, useSimulatorSettings,
} from '../context/hook';
import { renderNumber } from '../../../utils/number';
import TextInput from '../../../components/Inputs/TextInput';

export default function SimulatorWithdrawModal({
  isOpen, handleClose, image, name, price, id, balance, change,
}) {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoint();
  const [withdrawAmount, setWithdrawAmount] = useState(1);
  const [totalCoins, setTotalCoins] = useState(0);
  const [confirmWithdraw, setConfirmWithdraw] = useState(false);
  const [minAmount, setMinAmount] = useState(1);
  const [incrementAmount, setIncrementAmount] = useState(1);
  const simulatorSettings = useSimulatorSettings();
  
  const handlerConfirmWithdraw = () => setConfirmWithdraw(!confirmWithdraw);
  
  const [porcentage, setPorcentage] = useState(0.01);
  const [mgToken, setMgToken] = useState(1);
  
  useEffect(() => {
    if (simulatorSettings.data) {
      const porc = simulatorSettings.data.feePercentage ? simulatorSettings.data.feePercentage / 100 : 0.01;
      setPorcentage(porc);
      setMgToken(simulatorSettings.data.mgTokenPrice);
      setMinAmount(simulatorSettings.data.minMGTokenToRedeem);
    }
  }, [simulatorSettings.data]);
  
  const [fee, setFee] = useState(0);
  useEffect(() => {
    if (simulatorSettings.data && simulatorSettings.data.feeMinCharge) {
      const f = Math.ceil(porcentage * totalCoins);
      const currentFee = f > simulatorSettings.data?.feeMinCharge ? f : simulatorSettings.data.feeMinCharge;
      setFee(currentFee);
    }
  }, [totalCoins, simulatorSettings.data]);
  
  useEffect(() => {
    const min = calcMinCoinsToBeMgToken({ coinPrice: price, mgToken });
    setMinAmount(min);
    setIncrementAmount(calcMinCoinsToBeMgToken({ coinPrice: price, mgToken }));
    setWithdrawAmount(min);
  }, [balance]);
  
  useEffect(() => {
    const coinReverse = convertCoinsToMgTokens({ coinAmount: withdrawAmount, coinPrice: price, mgToken });

    setTotalCoins(Math.floor(coinReverse));
  
    return () => {};
  }, [withdrawAmount]); 
  
  const simulatorWithdrawCoins = useSimulatorWithdrawCoins({
    onSuccess: () => {
      setWithdrawAmount(minAmount);
      setTotalCoins(0);
      handleClose();
    }, 
  });

  return (
    <Modal
      width="700px"
      title={`Swap Out your  ${name} Coins`}
      open={isOpen}
      handleClose={handleClose}
    >
      {Math.ceil(minAmount) > balance ? (
        <div style={{ maxWidth: '500px', margin: '0 auto', marginBottom: '20px' }}>
          <Typography style={{
            opacity: '.7', fontSize: '20px', textAlign: 'center', marginBottom: '10px', 
          }}
          > 
            {'In order to swap out, you must have enough '}
            <span className="primary-text-gradient" style={{ fontWeight: '700' }}>{` ${name} `}</span>
            coins to equal at least 1 MG Token.
        
          </Typography>
          <Typography style={{
            opacity: '.7', fontSize: '16px', textAlign: 'center', marginBottom: '30px', 
          }}
          >
            {'Currently your '}
            <span className="primary-text-gradient" style={{ fontWeight: '700' }}>{` ${name} `}</span>
            {` coins is equal to ${renderNumber((price / mgToken) * balance)} MG Token.`}
          
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}> 
            <Button
              size="md"
              style={{ width: '200px' }}
              type="button"
              onClick={async () => {
                handleClose();      
              }}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{t('Ok')}</span>
            </Button>
          </div>
        
        </div>
      ) : (
        <div>
   
          <Grid
            container
            size={12}
            gap={3}
            style={{ maxWidth: '500px', margin: '0 auto', marginBottom: '30px' }}
          >
            <Grid
              item
            >
              <img
                style={{ width: isMobile || isTablet ? '80px' : '94px', height: isMobile || isTablet ? '80px' : '94px', borderRadius: '90px' }}
                src={image}
                alt={name}
              />
         
            </Grid>
            <Grid
              item
              sx={{ flex: 1 }}
            >
        
              <Typography
                fontSize="14px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '0px' }}
              >
                {price.toFixed(8)}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '0px', opacity: '0.5', marginBottom: '10px' }}
              >
                {t('Current price:')}
              </Typography>
         
              {change ? (
                <ChangeBadges
                  value={`${change.toFixed(2)}%`}
                  increase={change > 0}
                />
              ) : null}
            </Grid>
            <Grid
              item
              sx={{ flex: 1 }}
            >
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                color="#fff"
                sx={{ opacity: '0.5', marginBottom: '20px' }}
              >
                {t(`MG Tokens have a value of  $${simulatorSettings.data ? simulatorSettings.data.mgTokenPrice.toFixed(2) : 1.00} each`)}
              </Typography>
         
              <div style={{
                border: '1px solid rgb(255 255 255 / 14%)',
                padding: '10px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center', 
              }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={500}
                  marginBottom="0px"
                  marginRight="10px"
                >
                  <span className="primary-text-gradient">{t('=')}</span>
            
                </Typography>
                <div style={{ flex: 1 }}>
             
                  <Typography
                    fontSize="24px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <span className="primary-text-gradient">
                      {`${renderNumber(totalCoins)} ` }
                      {' '}
                    </span> 
                    <span className="primary-text-gradient" style={{ fontSize: '10px' }}>  MG Tokens</span>
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    sx={{ marginTop: '-5px', opacity: '0.5' }}
                  >
                    {t('Total')}
                  </Typography>
                </div>
           
              </div>
            </Grid>
          </Grid>

          <Container style={{ maxWidth: '550px', margin: '0 auto' }}>
            <Typography style={{ opacity: '.7', fontSize: '12px' }}>
              Available 
              {' '}
              {`${name} Coins`}
            </Typography>
            {
        minAmount > 1 ? (
          <Slider
            min={Math.ceil(minAmount)}
            max={balance}
            value={Math.floor(withdrawAmount)}
            step={incrementAmount}
            getAriaValueText={() => `$${withdrawAmount}`}
            valueLabelDisplay="auto"
            onChange={(e, v) => {
              setWithdrawAmount(v);
            }}
            disabled={false}
            marks={getMarks({ max: balance, min: Math.ceil(minAmount) })}
          />
        ) : null
       }
  
          </Container>
          <div style={{
            display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center', marginTop: '10px', alignItems: 'flex-end',
          }}
          >
            <div>
              <Typography
                fontSize="14px"
                fontWeight={500}
                marginBottom="10px"
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <span className="primary-text-gradient" style={{ fontSize: '14px', textAlign: 'center' }}>{`${name} Coins`}</span>
              </Typography>
              <TextInput
                size="md"
                name="amount"
                type="number"
                min={minAmount}
                max={balance}
                value={Math.ceil(withdrawAmount).toString()}
                placeholder="Amount"
                style={{ textAlign: 'center' }}
                onChange={(v) => {
                  const value = parseFloat(v);
                  if (value > balance) {
                    setWithdrawAmount(balance);
                    toast.error('You do not have enough balance');
                    return;
                  }
                  if (value < minAmount) {
                    setWithdrawAmount(minAmount);
                    toast.error(`You must Swap Out at least ${Math.ceil(minAmount)}  ${name} Coins`);
                    return;
                  }
                  setWithdrawAmount(parseFloat(v));
                }}
              />
        
            </div>
            <Button
              size="md"
              style={{ width: '200px', marginBottom: '5px' }}
              bordered
              onClick={async () => {
                setWithdrawAmount(balance);
                setConfirmWithdraw(true);
              }}
            >
              {t('Swap Out All')}
            </Button>

          </div>
          <Grid
            container
            size={12}
            gap={3}
            style={{
              maxWidth: '500px',
              margin: '0 auto',
              marginTop: '30px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1, 
              maxWidth: '180px', 
            }}
            >
              <Button
                size="md"
                style={{ width: '100%' }}
                onClick={async () => {
                  setConfirmWithdraw(true);
                }}
              >
                {t('Swap Out')}
              </Button>
              <Button
                size="md"
                text
                style={{ width: '100%' }}
                onClick={async () => {
                  handleClose();
                }}
              >
                {t('Cancel')}
              </Button>
            </div>
     
          </Grid>
          <Modal
            width="500px"
            title={`Swap Out your ${name} Coins`}
            open={confirmWithdraw}
            handleClose={handlerConfirmWithdraw}
          >
      
            <Typography
              fontSize="20px"
              fontWeight={500}
              style={{ marginTop: '-20px', marginBottom: '40px' }}
            >
              Are you sure you want to swap out to your Ambassador Wallet? 
            </Typography>
            <Typography
              fontSize="18px"
              fontWeight={500}
              style={{ marginTop: '-20px', marginBottom: '20px' }}
            >
              Transaction : 
              {' '}
              <span className="primary-text-gradient">{`${renderNumber(Math.floor(withdrawAmount))} ${name} coins`}</span>
              {' '}
              for 
              {' '}
              <span className="primary-text-gradient">{`${renderNumber(totalCoins)} MG Tokens`}</span>
            </Typography>
                
            <Typography
              fontSize="18px"
              fontWeight={500}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            >
              Transaction Fee: 
              {' '}
              <span className="primary-text-gradient">{`${fee} MG Tokens `}</span> 
            </Typography>
      
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '10px',
              justifyContent: 'flex-end',
            }}
            >
                
              <Button
                size="md"
                text
                style={{ width: '100%' }}
                onClick={async () => {
                  setConfirmWithdraw(false);
                }}
              >
                     
                <span style={{ whiteSpace: 'nowrap' }}>{t('No, Cancel')}</span>
              </Button>
         
              <Button
                size="md"
                style={{ width: '100%' }}
                onClick={async () => {
                  setConfirmWithdraw(false);
                  simulatorWithdrawCoins.mutate({
                    coinApiId: id,
                    coinAmount: withdrawAmount,
                    coinPrice: price,
                    mgTokens: totalCoins,
                    fee,
                  });
                }}
              >
                <span style={{ whiteSpace: 'nowrap' }}>{t('Yes, Swap Out')}</span>
              </Button>
            </div>
          </Modal>
        </div>
      )}
    
    </Modal>
  );
}
