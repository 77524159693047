// import { useState } from 'react';
import { Grid } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import Layout from '../../../../components/CardLayout';
import CurrentCoinsHeader from '../CurrentCoinsHeader';
import CurrentCoinsList from '../CurrentCoinsList';
import SelectedCoin from '../SelectedCoin';
import { useBreakpoint, useAuthentication } from '../../../../utils/store';
import { SocketContext } from '../../../../utils/store/socket';
import ComponentLoader from '../../../../components/ComponentLoader';
import { useSimulator, useSimulatorGameCoins } from '../../context/hook';

export default function CurrentCoins() {
  const { isMobile, isTablet } = useBreakpoint();

  const [coinsData, setCoinsData] = useState([]);
  const [currentCoin, setCurrentCoin] = useState(null);
  const socket = useContext(SocketContext);
  const { user } = useAuthentication();
  
  const simulator = useSimulator();
  const simulatorGameCoins = useSimulatorGameCoins();

  useEffect(() => {
    if (simulatorGameCoins?.data?.length > 0) {
      setCoinsData(simulatorGameCoins.data);
    }
  }, [simulatorGameCoins]);

  useEffect(() => {
    socket.on('simulator.coins.price', (data) => {
      setCoinsData(data.data);
    });

    return () => {
      socket.off('simulator.coins.price');
    };
  }, [socket, setCoinsData]);
  
  useEffect(() => {
    if (coinsData.length > 0 && !currentCoin) {
      setCurrentCoin(coinsData[0]);
    }
  }, [coinsData]);

  useEffect(() => {
    socket.on(`simulator.coins.price:${user.SID}`, (data) => {
      setCoinsData(data.data.data);
    });

    return () => {
      socket.off(`simulator.coins.price:${user.SID}`);
    };
  }, [socket, setCoinsData]);

  return (
    <Layout className="my-coin-picks-grid-view" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
      <CurrentCoinsHeader />
      <Grid size={12} container style={{ paddingBottom: '40px' }}> 
          
        <Grid
          item
          lg={6}
          xs={12}
          position="relative"
        > 
          {coinsData.length === 0
            ? <ComponentLoader /> 
            : <CurrentCoinsList MGTokens={simulator.data?.data.token || 0} coins={coinsData} setCurrentCoin={setCurrentCoin} />}
          
        </Grid>
          
        {!isMobile && !isTablet && currentCoin ? (
          <Grid
            item
            md={6}
            xs={12}
          > 
            <SelectedCoin
              marketCap={currentCoin.marketCap}
              totalVolume={currentCoin.totalVolume}
              MGTokens={simulator.data?.data.token || 0}
              image={currentCoin.image}
              coin={currentCoin.name}
              price={currentCoin.price}
              id={currentCoin.apiId}
              gameId={currentCoin.gameId}
              startPrice={currentCoin.startPrice}
              priceChange={currentCoin.priceChange}
              chart={currentCoin.chart}
            />
          </Grid>
        ) : null}
          
      </Grid>
   
    </Layout>
  );
}
