import { useContext, useEffect, useState } from 'react';
import {
  Pagination, Typography, Grid,
} from '@mui/material';
import { t } from 'i18next';
import { Context } from '../context';
import Table from '../../../components/Table';
import Select from '../../../components/Inputs/Select';
import CancelWithdrawModal from './CancelWithdrawModal';

const Withdrawals = () => {
  const {
    withdrawData,
    fetchWithdrawals,
    loadedWithdrawData,
    withdrawDataColumnsConfig,
    withdrawPaginationData,
    historyPage,
  } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(undefined);
  const status = [
    { value: 'pending', label: 'Pending' }, 
    { value: 'paid', label: 'Paid' }, 
    { value: 'completed', label: 'Completed' },
    { value: 'canceled', label: 'Canceled' },
  ];
  
  useEffect(() => {
    fetchWithdrawals({ page: currentPage, status: currentStatus });
  }, [currentPage, currentStatus, historyPage]);
  
  return (
    <div className="flex" style={{ flexDirection: 'column' }}>
      
      {/* Filter by Categories */}
            
      <Grid
        size={{ xs: 12, sm: 8 }}
        sx={{
          justifyContent: 'right', marginBottom: '20px', display: { sm: 'flex' }, 
        }}
      >
              
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingRight: '12px',
          }}
          sx={{ marginBottom: { xs: '10px', sm: 0 } }}
        >
          By Status:
        </Typography>
        <Select
          name="status-filter-select"
          placeholder="All Status"
          size="sm"
          sx={{ minWidth: '200px' }}
          value={currentStatus === undefined ? '' : currentStatus}
          onChange={(v) => {
            if (v === 'default') {
              setCurrentStatus(undefined);
              return;
            }
            setCurrentStatus(v);
          }}
          options={status}
        />
      </Grid>
            
      <Table
        loading={!loadedWithdrawData}
        columns={withdrawDataColumnsConfig}
        rows={withdrawData}
        className="table mb-4"
      />
      {(withdrawData && withdrawPaginationData?.total > 1) && (
      <div className="flex justify-end mt-4">
        <Typography sx={{ mt: 0.5 }}>
          {t(`Total of: ${withdrawPaginationData.total} pages`)}
        </Typography>
        <Pagination
          size="small"
          siblingCount={0}
          count={withdrawPaginationData?.total}
          page={withdrawPaginationData?.page}
          onChange={(val, page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      )}
      <CancelWithdrawModal />
    </div>
  );
};

export default Withdrawals;
