import moment from 'moment';
import 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Container, Grid, Typography } from '@mui/material';
import useMemeGames from './hook';
import MemeModal from './MemeModal';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import AppLoader from '../../components/AppLoader';

const localizer = momentLocalizer(moment);

export default function MemeGames() {
  const {
    loading,
    onClose,
    modalEvent,
    loadingForm,
    onSubmitForm,
    setModalEvent,
    calendarEvents,
    cancelDelete,
    deleteId,
    onDeleteSubmit,
    onRangeChange,
    calendarRef,
  } = useMemeGames();

  // if (loading) {
  //  return <AppLoader />;
  // }

  return (
    <Container maxWidth="xl" className="meme-games" sx={{ position: 'relative' }}>
      {
        loading ? <AppLoader /> : ''
      }
      <div className="calendar-cont">
        <Button size="sm" className="add-game-btn" onClick={() => setModalEvent({})}>
          Add +
        </Button>

        <div className="mt-5">
          <Calendar
            ref={calendarRef}
            resizable
            view="month"
            onView={() => { }}
            events={calendarEvents}
            localizer={localizer}
            style={{ height: '85vh' }}
            defaultDate={moment().tz('GMT').toDate()}
            onRangeChange={(v) => { onRangeChange(v); }}
            onDoubleClickEvent={(data) => setModalEvent(data)}

          />
        </div>
      </div>

      <Modal open={!!modalEvent} handleClose={onClose} title="Add Game">
        <MemeModal loadingForm={loadingForm} value={modalEvent || { startDate: moment().tz('GMT').startOf('day') }} onSubmit={onSubmitForm} />
      </Modal>

      <Modal width="" open={!!deleteId} handleClose={cancelDelete} title="Delete Coin">

        <Grid container columnSpacing={1}>
          <Grid item xl={12} lg={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography>
              {' '}
              Are you sure you want to delete
              <span style={{
                color: 'var(--primary-color)', textTransform: 'uppercase', marginLeft: '3px', marginRight: '3px',
              }}
              >

                {deleteId?.coinOne.name}
                {' '}
                {' '}
                vs
                {' '}
                {' '}
                {deleteId?.coinTwo?.name}
              </span>
              ?
            </Typography>
          </Grid>

          <Grid item xl={6} lg={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button onClick={() => onDeleteSubmit()} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Delete</Button>

          </Grid>

          <Grid item xl={6} lg={6} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button onClick={() => cancelDelete()} style={{ display: 'flex', width: '100%', justifyContent: 'center' }} bordered>Cancel</Button>

          </Grid>

        </Grid>

      </Modal>
    </Container>
  );
}
