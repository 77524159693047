/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Typography, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import BtcWalletNumber from './btcWallet';
import Modal from '../../../components/Modal';
import PurchasePasses from '../../Memberships/components/PurchasePasses';
import PurchasePackages from '../../Memberships/components/PurchasePackages';
import SolanaWallet from './solanaWallet';
import RedeemButton from '../../../components/RedeemButton';
import TransferCoinsModal from './TransferCoinsModal';
import RedeemCoinPackModal from '../../RedeemToken/components/redeemCoinPackModal';
import useRedeemTokenApi from '../../RedeemToken/context/api';
import { RedeemTokenProvider } from '../../RedeemToken/context';

export const getDisclaimerContent = () => (
  <Typography sx={{ fontSize: '11px', color: 'GrayText', textTransform: 'uppercase' }}>
    Be careful!
    {' '}
    <a href="https://memegames.ai/" target="_blank" rel="noreferrer">
      MemeGames.ai
    </a>
    {' '}
    is not responsible for any lost funds sent to an incorrect address.
  </Typography>
);

function Base() {
  const {
    t, defaultWallet, setDefaultWallet, tokens, withdrawConditons,
  } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [openCoinTransferModal, setOpenCoinTransferModal] = useState(false);

  const onPurchaseMore = () => {
    setOpenModal(true);
  };

  const {
    user,
  
    loadedGamesData,
    gamesData,
  
    loadedWalletData,
    walletData,
  
    handleRedeemCoinPackSubmit,
  } = useRedeemTokenApi();

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handleOpenPopUpModal = () => {
    setPopupIsOpen(true);
  };

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 6, padding: { xs: 0, md: 4 } }}>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '26px',
          fontWeight: 600,
          mb: 4,
        }}
      >
        {t('Gamer Wallet')}
      </Typography>

      <Grid container>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: { md: 2 } }}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid
              item
              xl={6}
              lg={6}
              md={12}
              sm={6}
              xs={12}
            >
              <div style={{
                width: '100%', position: 'relative', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
              }}
              >
                <Typography sx={{ fontWeight: 500, float: 'left' }}>
                  {' '}
                  {t('MG Coins')}

                </Typography>

              </div>

            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={12}
              sm={6}
              xs={12}
              item
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: {
                  xl: 'flex-end', lg: 'flex-end', md: 'flex-start', sm: 'flex-end', xs: 'flex-start', alignItems: 'flex-start',
                },
              }}
            >
              <Button
                onClick={onPurchaseMore}
                className="py-auto my-auto mr-3"
                bordered
                size="sm"
              >
                <span className="no-wrap">{t('Purchase more coins')}</span>
              </Button>
              <Button
                onClick={() => setOpenCoinTransferModal(true)}
                className="py-auto my-auto mr-3"
                bordered
                size="sm"
              >
                <span className="no-wrap">{t('Transfer MG Coins')}</span>
              </Button>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="divider" />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography sx={{ mb: 2 }}>{ }</Typography>
              <TextInput
                value={defaultWallet?.coins?.toLocaleString('en-us') || '0'}
                onChange={(e) => {
                  setDefaultWallet(e);
                }}
                readOnly
                endAdornment={(
                  <div className="flex items-center">
                    <Typography sx={{
                      mr: 3, ml: 1, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                    }}
                    >
                      MG Coins
                    </Typography>
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: { md: 2 }, marginTop: { xs: 4, md: 0 } }}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid
              xl={6}
              lg={6}
              md={12}
              sm={6}
              xs={12}
              item
              sx={{

              }}
            >
              <div style={{
                width: '100%', position: 'relative', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
              }}
              >
                <Typography sx={{ fontWeight: 500, float: 'left' }}>
                  {' '}
                  {t('MG Tokens')}

                </Typography>
              </div>
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={12}
              sm={6}
              xs={12}
              item
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: {
                  xl: 'flex-end', lg: 'flex-end', md: 'flex-start', sm: 'flex-end', xs: 'flex-start', alignItems: 'flex-start',
                },

              }}
            >
              {/*           
              <Button
                onClick={onTransferClick}
                className="py-auto my-auto mr-3"
                bordered
                size="sm"
              >
                <span className="no-wrap">{t('Transfer')}</span>
              </Button> */}

              {/* <RedeemButton totalTokens={tokens} bordered /> */}
              <RedeemCoinPackModal />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="divider" />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography sx={{ mb: 2 }}>{ }</Typography>
              <TextInput
                value={defaultWallet?.token?.toLocaleString('en-us') || '0'}
                onChange={(e) => {
                  setDefaultWallet(e);
                }}
                readOnly
                endAdornment={(
                  <div className="flex items-center">
                    <Typography sx={{
                      mr: 3, ml: 1, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                    }}
                    >
                      MG Tokens
                    </Typography>
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <SolanaWallet /> */}
      </Grid>

      <BtcWalletNumber />

      <Modal
        open={openModal}
        width="500px"
        title="Purchase MG Coins"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <Grid item xs={12}>
          <PurchasePasses />
        </Grid>

        <Grid item xs={12}>
          <PurchasePackages />
        </Grid>
      </Modal>
      {/* <RedeemCoinPackModal user={user} walletData={walletData} t={t} loadedWalletData={loadedWalletData} handleRedeemCoinPackSubmit={handleRedeemCoinPackSubmit} /> */}

      <TransferCoinsModal
        open={openCoinTransferModal}
        setOpenCoinTransferModal={setOpenCoinTransferModal}
      />
    </Layout>
  );
}

export default function WalletManagement() {
  return (
    <RedeemTokenProvider>
      <Base />
    </RedeemTokenProvider>
  );
}
