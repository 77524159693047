import { Grid, Typography, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

export const columns = ({ setCurrentCoin, setOpenHistoy }) => [
  {
    field: 'coinName',
    headerName: 'Coin',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row, value }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Tooltip style={{ zIndex: 999 }} placement="top" title="View the history">
          <Grid item xs={12}>
            <button
              type="button"
              onClick={() => {
                setCurrentCoin(row);
                setOpenHistoy(true);
              }}
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
              }}
              onMouseEnter={(e) => {
                e.target.style.color = '#78e4ff';
              }}
              onMouseLeave={(e) => {
                e.target.style.color = '#fff';
              }}
            >
              <span className="coin-image" style={{ float: 'left' }}>
                <img src={row?.coinImage} alt={value.coinName} />
              </span>
              <Typography className="coin-name">
                
                {row.coinName}
                {' '}
                coins
              
              </Typography>
            </button>
          </Grid>
        </Tooltip>
        
      </Grid>
    ),
  },
  
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 300,
    renderCell: ({ value }) => (
     
      <div style={{
        maxWidth: '100%', whiteSpace: 'break-spaces', paddingTop: '5px', paddingBotton: '5px', 
      }}
      >
        {value}
      </div>
      
    ),
  },
  {
    field: 'transactionType',
    headerName: 'Transaction',
    width: 150,
    renderCell: ({ row, value }) => (
      <Typography style={{ textTransform: 'capitalize', color: value === 'redeem' ? 'green' : 'red' }}>{value}</Typography>
        
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 190,
    renderCell: ({ value }) => (
      <Typography className="coin-price">
        {`${dayjs(value).tz('GMT').format('MMM DD, YY HH:mm:ss')} GMT`}
      </Typography>
    ),
  },
  
];

export default {};
