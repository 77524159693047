/* eslint-disable no-unreachable */
/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import PassTypes from '../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../components/Modal';
import { AppContext } from '../../../../../utils/store/app';
import BuyForm from '../../../../../components/BuyForm';
import useApi from '../../../../../utils/api';
// import StripePay from '../../../../Authentication/PayNow/components/StripePay';
// import RadioGroup from '../../../../../components/Inputs/RadioGroup';

export default function PaymentRenewModal({
  data,
  handleModalClose,
  selectedPackage,
  setSelectedPackage,
  // location = '',
}) {
  const { user } = data;
  const { t } = useTranslation();
  const { passTypes } = useContext(AppContext);
  const [loading, setLoading] = useState();
  const { authPost, authDelete } = useApi();
  const [buyFormData, setBuyFormData] = useState();

  const onBuy = async () => {
    setLoading(true);
    try {
      const result = await authPost('/payments/membership/start', {
        data: {
          SID: user.SID,
          passType: selectedPackage,
          amountOfPasses: 1,
          actionScreen: 'profile',
          fromProfile: true,
        },
      });

      const serverIpn = `${process.env.REACT_APP_BASE_URL}/payments/renew/membership/ipn/${result.PUID}`;
      const uri = window.location.href;
      const uriParts = new URL(uri);
      const { origin } = uriParts;
      const redirect = `${origin}/profile`;

      setBuyFormData({
        ...result,
        formTitle: 'Proceed to checkout?',
        redirect,
        serverIpn,
        fromProfile: true,
      });
      sessionStorage.setItem('_puid', result.PUID);
    } catch (err) {
      console.log('[buy]', err);
      setBuyFormData(null);
      handleModalClose();
      if (!err?.statusCode && err?.statusCode !== 409) {
        toast.error('We cannot activate your membership right now, please try again later!');
      }
    }

    setLoading(false);
  };

  const handleBuyModalClose = async (canceled = true) => {
    const _solana = sessionStorage.getItem('_solana_pay_transaction_id');
    setLoading(true);

    try {
      if (canceled && !_solana) {
        const _puid = sessionStorage.getItem('_puid');
        if (_puid) {
          await authDelete(`/payments/passes/remove/${_puid}`);
        }
      } else {
        if (!_solana) {
          await authPost('/payments/renew/membership/free', {
            data: {
              SID: user.SID,
              passType: buyFormData.passType.id,
              amountOfPasses: 1,
              actionScreen: 'profile',
            },
          });
        }
        toast.success(t('You have succefully activated your membership!'));
        sessionStorage.removeItem('_solana_pay_transaction_id');
      }
    } catch (err) {
      console.log(err);
      toast.error(t('We cannot activate your membership right now, please try again later!'));
    }
    setBuyFormData(null);
    sessionStorage.removeItem('_puid');
    setLoading(false);
    handleModalClose();
  };

  return (
    <Modal
      title={t('Purchase coin pack confirmation!')}
      width="500px"
      open={!!data}
      handleClose={handleModalClose}
    >
      {/* {
        step === 1 ? ( */}
      <Grid container spacing={2}>
        <PassTypes
          packages={passTypes.filter((p) => p.active && (user.isAffiliate || (!user.isAffiliate && !p.name.includes('End of Year Challenge'))))}
          loading={loading}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          formTitle="Select your Pack"
        />

        {/* <RadioGroup
          onChange={(e) => { setPaymentMethod(e); }}
          setErrors={() => { }}
          value={paymentMethod}
          options={[{ value: 'creditCard', label: 'Credit Card' }, { value: 'crypto', label: 'Crypto' }]}
          sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', my: 3,
          }}
        /> */}

        <BuyButton disabled={loading} onSubmitBitcoin={onBuy} style={{ marginTop: '25px' }} />
      </Grid>
      {/* )

          : (
            <StripePay
              setStep={setStep}
              signupUser={user}
              location={location}
              clientSecret={clientSecret}
            />
          )
      } */}

      {buyFormData && (
        <Modal
          title={t('Activate Membership!')}
          width="450px"
          open={!!buyFormData}
          handleClose={handleBuyModalClose}
        >
          <BuyForm
            data={buyFormData}
            handleModalClose={handleBuyModalClose}
          />
        </Modal>
      )}
    </Modal>
  );
}
