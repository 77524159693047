/* eslint-disable max-len */
import { useContext } from 'react';
import { AppContext } from '../../../../../../../utils/store/app';

export const formConfig = [
  // {
  //  name: 'username',
  //  type: 'text',
  //  label: 'Recipient Username/Email',
  // },
  {
    name: 'username',
    label: 'Recipient Username/Email',
    type: 'text',
    // endPoint: '/users?isProduct=true', // TODO: add endpoint
    // mapper: (val) => ({ label: `${val.Name} ${val.Surname} (${val.Username})`, value: val }), // TODO: add mapper
  },
  {
    name: 'renewOnTransfer',
    label: 'Auto activate the gamer after transfer?',
    type: 'muiCheckbox',
    checked: false,
  },
  {
    name: 'transferable',
    label: 'Allow the user to transfer this pack/packs to other persons?',
    type: 'muiCheckbox',
    checked: true,
  },
];

export const getFormConfig = () => {
  const { passTypes } = useContext(AppContext);
  const opts = passTypes?.map((p) => ({ label: `${p?.name} (${p?.total || 0})`, value: p?.id }));
  formConfig[0].options = opts;
  return formConfig;
};

export const defaultValue = { amountOfPasses: 1, transferable: true, renewOnTransfer: false };

export const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  // amountOfPasses: {
  //   isEmpty: false,
  //   type: 'Number',
  //   message: 'Please do not leave this field empty!',
  // },
  // passType: { isEmpty: false, type: 'String', message: 'Please chose a Pack type!' },
};
