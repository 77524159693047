import { useRef, useState } from 'react';

export default function use2FA() {
  const [open, setOpen] = useState(false);
  const [preData, setPreData] = useState(null);
  const [otp, setOtp] = useState();
  const [preFunction, setPreFunction] = useState(() => {});
  const [otpToken, setOtpToken] = useState(null);
  const functionRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleClose = () => {
    setOpen(false);
    setOtp(null);
    setPreFunction();
    setOtpToken(null);
    sessionStorage.removeItem('otpToken');
    sessionStorage.removeItem('otp');
  };

  const handleOpen = (_data) => {
    setOtpToken(_data.otp_token);
    sessionStorage.setItem('otpToken', _data.otp_token);
    setPhoneNumber(_data.sent_to);
    setOpen(true);
  };
  const savePreFunction = (func) => {
    functionRef.current = func;
  };
  const runPreFunction = (data) => {
    if (functionRef.current) {
      functionRef.current(data);
    }
  };
  
  const submit = () => {
    try {
      sessionStorage.setItem('otp', otp);
      runPreFunction(preData);
    } catch (err) {
      console.log(err);
    }
  };

  const intercept = (config) => {
    const _config = { ...config };
    const _otp = sessionStorage.getItem('otp');
    const _otpToken = sessionStorage.getItem('otpToken');
    if (_otp && _otpToken) {
      _config.headers = { ...config.headers, 'Otp-Code': _otp, 'Otp-Token': _otpToken };
      handleClose();
    }
    return _config;
  };

  return (
    {
      open,
      setOpen,
      handleClose,
      handleOpen,
      submit,
      otp,
      setOtp,
      preFunction,
      setPreFunction,
      setPreData,
      preData,
      otpToken,
      setOtpToken,
      savePreFunction,
      runPreFunction,
      intercept,
      phoneNumber,
      setPhoneNumber,
    }
  );
}
