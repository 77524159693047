import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import SimulatorRedeemModal from './SimulatorRedeemModal';
import Button from '../../../components/Button';
import ChangeBadges from '../../../components/ChangeBadges';
import { useBreakpoint } from '../../../utils/store'; 
import { renderNumber } from '../../../utils/string';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default function SelectedCoin({
  image, coin, price, id, MGTokens, gameId, priceChange, chart, startPrice, totalVolume, marketCap,
}) {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoint();
  const [open, setOpen] = useState(false);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
      
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback(val, index) {
            const max = chart.length - 1;
            const interval = Math.floor(max / 5);
            
            if (index === 0) {
              return dayjs(chart[val][0]).format('hA').toLowerCase();
            }
            
            if (index === Math.floor(interval * 1)) {
              return dayjs(chart[Math.floor(interval * 1)][0]).format('hA').toLowerCase();
            }
            
            if (index === Math.floor(interval * 2)) {
              return dayjs(chart[Math.floor(interval * 2)][0]).format('hA').toLowerCase();
            }
            
            if (index === Math.floor(interval * 3)) {
              return dayjs(chart[Math.floor(interval * 3)][0]).format('hA').toLowerCase();
            }
            
            if (index === interval * 4) {
              return dayjs(chart[Math.floor(interval * 5)][0]).format('hA').toLowerCase();
            }
            
            if (index === max) {
              return dayjs(chart[val][0]).format('ha').toLowerCase();
            }
            
            return null;
          },
          // color: 'red',
        },
        grid: {
          color: 'rgba(229, 229, 229, 0.12)',
          display: true, 
          borderDash: [5, 5],
        },
        legend: false,
        border: { color: 'transparent' },
      },
      y: {
        display: false,
        title: {
          display: false,
        }, 
        grid: {
          color: 'rgba(229, 229, 229, 0.12)',
          display: false, 
          border: { borderDash: [5, 5] },
        },
      
      },
    },
  
  };
  
  const labels = chart.map((item) => dayjs(item[0]).format('"MMM DD, YYYY HH:mm"'));

  const datapoints = chart.map((item) => item[1]);

  const data = {
    labels,
    datasets: [
      {
        data: datapoints,
        borderColor: '#78E4FF',
        backgroundColor: '#ffffff0f',
        pointBackgroundColor: '#78E4FF',
        fill: true,
        cubicInterpolationMode: 'default',
        tension: 0,
        borderWidth: 2,
        pointRadius: 0,
        
      },
    ],
  };
  return (

    <div style={{
      position: 'relative', 
      height: '100%',
      overflow: 'hidden',
      maxHeight: '500px',
    }}
    >
      <Grid
        sx={{
          marginLeft: { xs: 0, md: '40px' },
          backgroundColor: isMobile || isTablet ? 'transparent' : '#1E1F22', 
          borderRadius: '8px',
          padding: isMobile || isTablet ? '0' : '40px',
          position: 'relative', 
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          display: 'flex',
        }}
      > 
        <Grid
          container
          size={{ xs: 12 }}
          sx={{
            alignItems: 'center', 
            gap: '10px',
            marginBottom: { xs: '20px', sm: '20px' },
            paddingBottom: '15px',
            display: 'flex',
            borderBottom: '1px solid rgba(229, 229, 229, 0.12)',
          }}
        >
          <Typography
            fontSize={isMobile || isTablet ? '16px' : '26px'}
            fontWeight={isMobile || isTablet ? 700 : 500}
            marginBottom="0px"
            marginRight={{ xs: 'auto' }}
         
          >
            {coin}
          </Typography>
          <Button
            type="button"
            onClick={() => {
              setOpen(true);
            }}
            size="sm"
            bordered
          >
            Simulate
          </Button>
        </Grid>
        <Grid
          container
          sx={{
            display: { xs: 'block', md: 'flex' }, flexWrap: 'nowrap', flex: 1, alignItems: 'center', justifyContent: 'center',
          }}
        >
          <Grid
            item
            size={{ xs: 4 }}
            sx={{
              display: { xs: 'flex', md: 'block' }, alignItems: 'center', justifyContent: 'center', gap: '15px', marginBottom: '20px',
            }}
          >
            <img
              style={{ width: isMobile || isTablet ? '50px' : '94px', height: isMobile || isTablet ? '50px' : '94px', borderRadius: '90px' }}
              src={image}
              alt={coin}
            />
            <div>
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                color="#fff"
                sx={{ marginTop: '10px', opacity: '0.5', marginBottom: '5px' }}
              >
                {t('Change:')}
              </Typography>
            
              <ChangeBadges
                value={`${priceChange.toFixed(2)} %`}
                increase={priceChange > 0}
              />
            </div>
            
            <div> 
              {' '}
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '10px', opacity: '0.5' }}
              >
                {t('Current price:')}
              </Typography>
            
              <Typography
                fontSize="14px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '0px' }}
              >
                {`$${price}`}
              </Typography>
            </div>
           
          </Grid>
          <Grid
            item
            size={{ xs: 8 }}
            style={{
              alignItems: 'center', justifyContent: 'center', display: 'flex', maxWidth: '100%', flex: 1,
            }}
          >
            <Grid
              sx={{
                paddingLeft: { md: '20px' },
              }}
              style={{
                overflow: 'hidden', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
              }}
            >
              <Line options={options} data={data} />
              <div style={{
                display: 'flex', justifyContent: 'flex-end', gap: '20px', width: '100%', marginTop: '0px', 
              }}
              >
                <div> 
                  
                  <Typography
                    fontSize="12px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    sx={{ marginTop: '10px', opacity: '0.5' }}
                  >
                    {t('Market cap:')}
                  </Typography>
            
                  <Typography
                    fontSize="14px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    sx={{ marginTop: '0px' }}
                  >
                    {`$${renderNumber(marketCap)}`}
                  </Typography>
                </div>
                <div> 
                  
                  <Typography
                    fontSize="12px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    sx={{ marginTop: '10px', opacity: '0.5' }}
                  >
                    {t('Total volume:')}
                  </Typography>
            
                  <Typography
                    fontSize="14px"
                    fontWeight={500}
                    marginBottom="0px"
                    marginRight="auto"
                    sx={{ marginTop: '0px' }}
                  >
                    {`$${renderNumber(totalVolume)}`}
                  </Typography>
                </div>
              </div>
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
      {open ? (
        <SimulatorRedeemModal
          isOpen={open}
          handleClose={() => {
            setOpen(false);
          }}
          image={image}
          coin={coin}
          price={price}
          id={id}
          balance={MGTokens}
          gameId={gameId}
          startPrice={startPrice}
          change={priceChange.toFixed(2)}
        />
      ) : null}
     
    </div>
    
  );
}
