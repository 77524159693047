import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdminTransactionsContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { renderNumber } from '../../../../utils/string';

const ReverseConfirmationModal = () => {
  const {
    t,
    reverseTransactionData,
    setReverseTransactionData,
    handleReverseTransactionModalSubmit,
  } = useContext(AdminTransactionsContext);

  if (!reverseTransactionData) return ('');

  const isAmbassador = reverseTransactionData.type === 'ambassador-wallet';

  const transactionDate = isAmbassador
    ? reverseTransactionData.transaction.actionForDate
    : reverseTransactionData.transaction.createdAt;

  const transactionAmount = isAmbassador
    ? reverseTransactionData.transaction.amount
    : reverseTransactionData.transaction.mgCoins || reverseTransactionData.transaction.token;

  return (
    <Modal
      title="Are you sure to reverse this Transaction?"
      open={!!reverseTransactionData}
      handleClose={() => setReverseTransactionData(null)}
      width="600px"
    >
      <Typography fontSize="18px">
        <span className="primary-text-gradient">{t('Amount: ')}</span>
        <span>{`${isAmbassador ? '$' : ''}${renderNumber(transactionAmount)}`}</span>
      </Typography>
      {!isAmbassador && (
        <Typography fontSize="18px">
          <span className="primary-text-gradient">{t('Type: ')}</span>
          <span>{reverseTransactionData.transaction.type}</span>
        </Typography>
      )}
      <Typography fontSize="18px">
        <span className="primary-text-gradient">{t('Description: ')}</span>
        <span>{reverseTransactionData.description}</span>
      </Typography>
      <Typography fontSize="18px">
        <span className="primary-text-gradient">{t('Justification: ')}</span>
        <span>{reverseTransactionData.justification}</span>
      </Typography>
      <Typography fontSize="18px">
        <span className="primary-text-gradient">{t('Date: ')}</span>
        <span>{dayjs(transactionDate).tz('GMT').format('MMM DD, YYYY')}</span>
      </Typography>

      <Grid container spacing={1} mt={4}>
        <Grid item xs={12} md={6}>
          <Button onClick={() => setReverseTransactionData(null)} bordered>
            {t('Cancel')}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button onClick={() => handleReverseTransactionModalSubmit(reverseTransactionData.id)}>
            {t('Reverse')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ReverseConfirmationModal;
