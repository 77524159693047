import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../../../utils/store/authentication';
import useApi from '../../../utils/api';
import { defaultBoosterLeadColumnsConfig } from '../config';

const useBoosterLeadApi = () => {
  const { authGet } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [boosterLeadData, setBoosterLeadData] = useState([]);
  const [loadedBoosterLeadData, setLoadedBoosterLeadData] = useState(false);
  const [boosterLeadEditData, setBoosterLeadEditData] = useState(null);

  const [leadData, setLeadData] = useState([]);
  const [loadedLeadData, setLoadedLeadData] = useState(false);

  const fetchBoosterLeadData = useCallback(
    async () => {
      setLoadedBoosterLeadData(false);

      const response = await authGet('/booster-program/my-leads');

      setBoosterLeadData(response);

      setLoadedBoosterLeadData(true);
    },
    [authGet],
  );

  const fetchUserLeadData = useCallback(
    async () => {
      setLoadedLeadData(false);

      const response = await authGet('/booster-program/user-leads');

      setLeadData(response);

      setLoadedLeadData(true);
    },
    [authGet],
  );

  useEffect(() => {
    fetchBoosterLeadData();
    fetchUserLeadData();
  }, []);

  // Columns Configuration ===================================
  const boosterLeadDataColumnsConfiguration = useMemo(
    () => defaultBoosterLeadColumnsConfig(t, setBoosterLeadEditData),
    [t, setBoosterLeadEditData],
  );
  // Columns Configuration ===================================

  return {
    t,
    user,

    fetchBoosterLeadData,
    boosterLeadDataColumnsConfiguration,
    boosterLeadData,
    loadedBoosterLeadData,

    boosterLeadEditData,
    setBoosterLeadEditData,

    leadData,
    loadedLeadData,
    fetchUserLeadData,
  };
};

export default useBoosterLeadApi;
