import { useQuery } from '@tanstack/react-query';
import useApi from '../../api';

export const useUserPartialInfo = () => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['user-partial-info'],
    queryFn: async () => {
      const response = await authGet('users/partial/info');
      return response;
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    refetchOnWindowFocus: true,
  });
};
