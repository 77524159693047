import React, { useContext, useState } from 'react';
import {
  Tabs, Tab, Box, Typography, 
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import CustomTabPanel, { a11yProps } from '../../../../../components/Tabs';
import AmbassadorTransactionForm from './AmbassadorTransactionForm';
import GamerTransactionForm from './GamerTransactionForm';
import { AdminTransactionsContext } from '../../context';

const AddTransactionModal = () => {
  const { 
    t,
    selectedUser,
    openModal,
    setOpenModal,
  } = useContext(AdminTransactionsContext);

  const handleOpenModal = () => {
    if (!selectedUser) {
      toast.error('You must first select a user');
      return;
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Button
        size="sm"
        onClick={handleOpenModal}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        disabled={!selectedUser}
      >
        <span>{t('Add Transaction')}</span>
        <AddCircleIcon />
      </Button>
      {selectedUser && (
        <Modal
          title="Add new transaction"
          open={openModal}
          handleClose={handleCloseModal}
          width="750px"
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '18px',
              fontWeight: 600,
              width: '100%',
              marginTop: '-16px',
              marginBottom: '16px',
              textAlign: 'center',
            }}
          >
            <span className="primary-text-gradient">
              {selectedUser.label}
            </span>
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="new-transaction-tabs"
            >
              <Tab label="Ambassador Wallet" {...a11yProps(0)} />
              <Tab label="Gamer Wallet" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabIndex} index={0}>
            <AmbassadorTransactionForm />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <GamerTransactionForm />
          </CustomTabPanel>
        </Modal>
      )}
    </>
  );
};

export default AddTransactionModal;
