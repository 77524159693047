// TradingViewWidget.jsx
import React, { memo, useEffect, useState } from 'react';
import ComponentLoader from '../../../../../components/ComponentLoader';
import useApi from '../../../../../utils/api';

function TradingViewWidget({ solanaId }) {
  const [src, setSrc] = useState(null);
  const { authGet } = useApi();
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const _fetch = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/coingecko/pool-id/${solanaId}`;
        const poolId = await authGet(url, { signal });
        setSrc(`https://www.dextools.io/widget-chart/en/solana/pe-light/${poolId}?theme=dark`);
      } catch (err) {
        console.log(err);
      }
    };

    _fetch();

    return () => {
      controller.abort();
    };
  }, []);

  return src ? (
    <iframe
      style={{ border: 0 }}
      id="dextools-widget"
      title="DEXTools Trading Chart"
      width="100%"
      height="500"
      src={src}
    />
  ) : (
    <div className="flex justify-center mt-10">
      <ComponentLoader />
    </div>
  );
}

export default memo(TradingViewWidget);
