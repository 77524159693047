import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultBlogPostsColumns = (
  t,
  handleEditPost,
  handleDeletePost,
) => [
  {
    field: 'title',
    headerName: t('Post Title'),
    flex: 1,
    minWidth: 300,
    ...defaultColConfig,
  },
  {
    field: 'category',
    headerName: t('Category'),
    minWidth: 250,
    ...defaultColConfig,
  },
  {
    field: 'id',
    headerName: '',
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <>
        <EditIcon
          sx={{ cursor: 'pointer', display: 'block', mr: 4 }}
          color="primary"
          onClick={() => handleEditPost(row.slug)}
        />
        <DeleteIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => handleDeletePost(row)}
        />
      </>
    ),
  },
];

export const blogPostFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Post Title',
    placeholder: 'Post Title',
    required: true,
  },
  {
    name: 'image',
    type: 'file',
    label: 'Image File',
    accept: 'image/*',
    required: false,
    preview: true,
  },
  {
    name: 'categoryId',
    label: 'Category',
    type: 'ajaxSelect',
    endPoint: '/blog/categories',
    mapper: (val) => ({ label: val.title, value: val.id }),
  },
  {
    name: 'content',
    type: 'editor',
    formGroupType: 'editor-group',
    label: 'Post Content',
    placeholder: 'Body',
    required: true,
    rows: 30,
  },
  {
    name: 'published',
    type: 'muiCheckbox',
    label: 'Published',
  },
];

export const blogEditFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Post Title',
    placeholder: 'Post Title',
    required: true,
  },
  {
    name: 'image',
    type: 'file',
    label: 'Image File',
    accept: 'image/*',
    required: false,
    preview: true,
  },
  {
    name: 'restore_image',
    type: 'muiCheckbox',
    label: 'Restore default image',
    required: false,
  },
  {
    name: 'categoryId',
    label: 'Category',
    type: 'ajaxSelect',
    endPoint: '/blog/categories',
    mapper: (val) => ({ label: val.title, value: val.id }),
  },
  {
    name: 'content',
    type: 'editor',
    formGroupType: 'editor-group',
    label: 'Post Content',
    placeholder: 'Body',
    required: true,
    rows: 30,
  },
  {
    name: 'published',
    type: 'muiCheckbox',
    label: 'Published',
  },
];

export const blogPostFormDefaultValues = {
  title: '',
  content: '',
  published: true,
};

export const blogPostFormValidationConfig = {
  title: { isEmpty: false, type: 'String', message: 'Title is required' },
  content: { isEmpty: false, type: 'String', message: 'Message is required' },
  categoryId: { isEmpty: false, type: 'String', message: 'Category is required' },
};
