import { useContext, useState } from 'react';
import {
  Box,
  Container, Grid, Tab, Tabs, Typography, 
} from '@mui/material';
import { AdminTaxContext, AdminTaxProvider } from './context';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import Table from '../../../components/Table';
import TaxFormDeleteModal from './components/TaxFormDeleteModal';
import TaxFormReviewModal from './components/TaxFormReviewModal';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';
import ResultsBox from '../../AdminReports/components/ResultsBox';
import Button from '../../../components/Button';
import TaxFormCompleteAllUsersModal from './components/TaxFormCompleteAllUsersModal';

const AdminTaxPage = () => {
  const {
    t,
    taxFormsData,
    taxFormsProcessingData,
    taxFormsCompletedData,
    taxFormsDataLoaded,
    taxFormsColumnsConfig,
    processingTaxFormsColumnsConfiguration,
    completedTaxFormsColumnsConfiguration,
    canWithdrawCount,
    handleCompleteAllUsersModalOpen,
  } = useContext(AdminTaxContext);
  const [listTabsValue, setListTabsValue] = useState(0);
  const handleListTabChange = (_, newValue) => {
    setListTabsValue(newValue);
  };

  return (
    <Container className="admin-tax-forms">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('Submitted Forms')}</span>
        </Typography>

        {taxFormsData?.length > 1 && (
          <Grid container display="flex" justifyContent="center" mt={4}>
            <Button onClick={handleCompleteAllUsersModalOpen} size="sm">
              {`Complete all (${taxFormsData.length}) users`}
            </Button>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} md={3}>
            <ResultsBox title="Can Withdraw" result={canWithdrawCount} />
          </Grid>

          <Grid item mt={4} xs={12}>
            <div className="divider" />
          </Grid>

          <Grid
            xs={12}
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Box>
              <Tabs
                value={listTabsValue}
                onChange={handleListTabChange}
                aria-label="History Section"
                className="ambassador-tabs-header"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Submitted" {...a11yProps(0)} />
                <Tab label="Processing Next Friday" {...a11yProps(1)} />
                <Tab label="Processed" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={listTabsValue}
              index={0}
              className="tab-panel"
            >
              {taxFormsDataLoaded ? (
                <Table
                  formLoading={!taxFormsDataLoaded}
                  columns={taxFormsColumnsConfig}
                  rows={taxFormsData}
                  className="table mb-4"
                />
              ) : (
                <div className="flex justify-center">
                  <ComponentLoader />
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel
              value={listTabsValue}
              index={1}
              className="tab-panel"
            >
              {taxFormsDataLoaded ? (
                <Table
                  formLoading={!taxFormsDataLoaded}
                  columns={processingTaxFormsColumnsConfiguration}
                  rows={taxFormsProcessingData}
                  className="table mb-4"
                />
              ) : (
                <div className="flex justify-center">
                  <ComponentLoader />
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel
              value={listTabsValue}
              index={2}
              className="tab-panel"
            >
              {taxFormsDataLoaded ? (
                <Table
                  formLoading={!taxFormsDataLoaded}
                  columns={completedTaxFormsColumnsConfiguration}
                  rows={taxFormsCompletedData}
                  className="table mb-4"
                />
              ) : (
                <div className="flex justify-center">
                  <ComponentLoader />
                </div>
              )}
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Layout>

      <TaxFormReviewModal />
      <TaxFormDeleteModal />
      <TaxFormCompleteAllUsersModal />
    </Container>
  );
};

export default () => (
  <AdminTaxProvider>
    <AdminTaxPage />
  </AdminTaxProvider>
);
