import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/AuthLayout';
import NewPassword from './newPassword';
import { useNonAuthApi } from '../../../utils/api';
import Url from '../../../utils/data/getParams';
import { validateAll } from '../../../components/Form/config';
import AppLoader from '../../../components/AppLoader';
import validatePassword from '../../../utils/data/validatePassword';

const formValidationConfig = {
  password: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  rewritePassword: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  code: { isEmpty: false, type: 'String', message: 'Please enter the 6 digit code!' },
};

export default function ResetPassword() {
  const [email, setEmail] = useState(false);
  const { nonAuthPut } = useNonAuthApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  const { t } = useTranslation();

  const onSubmitPassword = async (_data) => {
    setLoading(true);
    const check = validateAll(_data, formValidationConfig);
    if (check) {
      setErrors(check);
      setLoading(false);
    } else if (
      !validatePassword(_data.password)
    ) {
      setErrors({ rewritePassword: t('Password must be at least 8 characters long. Must have at least 1 Capital letter, 1 number, and 1 special character') });
      setLoading(false);
    } else if (
      _data.password !== _data.rewritePassword
    ) {
      setErrors({ rewritePassword: t('Passwords Do Not Match!') });
      setLoading(false);
    } else {
      setErrors();
      try {
        // check the email'
        setEmail(_data);
        await nonAuthPut('/auth/reset-password/confirm', { data: { plainPassword: _data.password, verificationToken: Url.get.token, code: _data.code } });
        toast.success(t('Password Changed Successfully!'));
        setLoading(false);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } catch (err) {
        console.log(err);
        toast.error(err);
        setLoading(false);
      }
    }
  };

  return (
    <AuthLayout>
      {
        loading ? <AppLoader overpage /> : ''
      }
      <Typography sx={{
        display: 'flex', justifyContent: 'center', fontSize: '19px', fontWeight: 'bold', color: 'white',
      }}
      >
        {t('RESET PASSWORD')}
      </Typography>
      <div className="divider" />

      <NewPassword
        config={{
          loading, setLoading, errors, setErrors,
        }}
        onSubmit={onSubmitPassword}
      />
    </AuthLayout>
  );
}
