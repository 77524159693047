import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function ChangeBadges({
  increase = true,
  value,

}) {
  return (
    <div className="flex">
      <div className="card-chip">
        <div className="flex flex-column mr-2">
          <span style={{ fontSize: '12px' }}>
            {value}
          </span>
        </div>
        <div className="flex flex-column">
          {increase ? (
            <div className="chip-bubble">
              <div className="flex justify-center">
                <ArrowUpwardIcon
                  style={{ width: 11, height: 11, color: 'black' }}
                />
              </div>
            </div>
          ) : (
            <div className="chip-bubble not-growing-bubble">
              <div className="flex justify-center">
                <ArrowDownwardIcon
                  style={{ width: 11, height: 11, color: 'black' }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
