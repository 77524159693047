/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress, Box, Typography, Pagination,
} from '@mui/material'; 
import { columnsConfig } from './config';
import Table from '../../../../components/Table';
import { PAGE_SIZE } from '../../api';
import Button from '../../../../components/Button';
import TransferPassesModal from './components/transfer/TransferPassesModal';
import Select from '../../../../components/Inputs/Select';
import useRenewMembership from '../RenewMemberships/hook';
import Modal from '../../../../components/Modal';
import TablePagination from '../../../../components/Table/Pagination';
import TableMobile from '../../../../components/Table/TableMobile';
import useApi from '../../../../utils/api';
import PurchasePassModal from '../PurchasePasses/components/modal';

export default function Passes({
  data = [], loading, passesTotal, fetchPasses, passesPage,
  passTypes, fetchTransfers, setPassesLoading, passesTotalAvailable, refresh,
}) {
  const { t } = useTranslation();
  const { submitRenew } = useRenewMembership(refresh);
  const [activateClicked, setActivateClicked] = useState();

  const onActivateClick = (_data) => {
    setActivateClicked(_data);
  };
  
  const [transferData, setTransferData] = useState();
  const [errors, setErrors] = useState();
  const [filters, setFilters] = useState({ page: passesPage });
  const [selectedFilters, setSelectedFilters] = useState(1);
  const { authPost } = useApi();

  const [purchaseData, setPurchaseData] = useState(null);

  const onTablePassClick = async (pass) => {
    try {
      const res = await authPost('/payments/second-half/start', { data: { token: pass.Token } });
      setPurchaseData({ ...res, passType: pass.passType, secondHalfPayment: true });
    } catch (err) {
      console.log(err);
    }
  };

  const onTransferPasses = useCallback((val) => {
    setTransferData({
      ...val,
      passTypes,
      passesTotal,
    });
  }, [passTypes, passesTotal]);

  const activePassTypes = useMemo(() => passTypes.filter((p) => p.active), [passTypes]);

  const columns = useMemo(() => (
    columnsConfig(t, onActivateClick, onTransferPasses, onTablePassClick)
  ), [t, onActivateClick, onTransferPasses, onTablePassClick]);

  const handleFilterChange = (selectedFilter) => {
    setPassesLoading(true);
    if (selectedFilter?.used === 'default' || selectedFilter?.used === 'all') {
      selectedFilter.used = undefined;
    }
    
    if (selectedFilter?.used === 'ready' || selectedFilter?.used === '50/50') {
      selectedFilter.paid = selectedFilter.used === 'ready';
      selectedFilter.used = undefined;
    } else {
      selectedFilter.paid = undefined;
    }
    
    if (selectedFilter?.passTypeId === 'default' || selectedFilter?.passTypeId === 'all') {
      selectedFilter.passTypeId = undefined;
    }
    if (selectedFilter?.perPage === 'default') {
      selectedFilter.perPage = PAGE_SIZE;
    }

    setFilters({ ...filters, ...selectedFilter });
    fetchPasses({ page: 1, ...filters, ...selectedFilter });

    if (!Object.keys(filters).includes('used')) {
      setSelectedFilters(selectedFilters + 1);
    }

    setPassesLoading(false);
  };

  const handleModalClose = async () => {
    try {
      setTransferData(null);
      setErrors(null);
    } catch (err) {
      console.log(err);
    }
  };
  
  const [usedPaid, setUsedPaid] = useState(undefined);
  
  useEffect(() => {
    if (filters?.used) {
      setUsedPaid(filters?.used);
    } else if (filters.paid === false || filters.paid === true) {
      setUsedPaid(filters.paid === true ? 'ready' : '50/50');
    }
  }, [filters]);

  return (
    <div className="coin-packs-card">
      <div className="packs-head" style={{ marginBottom: 20 }}>
        <h1>{t('Coin Packs')}</h1>
        <span>{`${passesTotalAvailable} Available`}</span>
      </div>

      <div className="flex packs-filters">
        <div className="flex flex-1">
          <Select
            className="transfers-filter-select"
            placeholder="Status"
            variant="outlined"
            size="sm"
            value={usedPaid || 'default'}
            onChange={(v) => {
              handleFilterChange({ used: v });
            }}
            options={[
              { value: 'all', label: 'All' },
              { value: 'ready', label: 'Ready' },
              { value: '50/50', label: '50/50' },
              { value: 'true', label: 'Used' },
              { value: 'false', label: 'Not Used' },
              
            ]}
          />

          <Select
            className="transfers-filter-select"
            placeholder="Type"
            size="sm"
            value={filters?.passTypeId || 'default'}
            onChange={(v) => handleFilterChange({ passTypeId: v })}
            options={activePassTypes.map((p) => ({ value: p.id, label: p.name }))}
          />
          <Select
            className="transfers-filter-select"
            placeholder="Per page"
            size="sm"
            value={filters?.perPage || 'default'}
            onChange={(v) => handleFilterChange({ perPage: v })}
            options={[
              { value: '5', label: '5 per page' },
              { value: '10', label: '10 per page' }, 
              { value: '20', label: '20 per page' }, 
              { value: '50', label: '50 per page' },
              { value: '100', label: '100 per page' },
            ]}
          />
        </div>
   
      </div>

      <div className="table-cont">
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="desktop">
              <Table loading={loading} columns={columns} rows={data} className="table mb-4" />
             
            </div>
            <div className="mobile">
              <TableMobile columns={columns} data={data} className="mb-6" />
          
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}> 
              <Pagination
                count={Math.ceil(passesTotal / (filters.perPage || PAGE_SIZE))}
                page={passesPage}
                onChange={(val, _page) => {
                  fetchPasses({ ...filters, page: _page });
                }}
              />
            </div>
          </>
        )}
      </div>

      {purchaseData && (
        <PurchasePassModal
          data={purchaseData}
          handleModalClose={() => { setPurchaseData(null); fetchPasses(); }}
          returnHook="/payments/second-half/ipn/"
          renderName={(_data) => `${_data.passType.name}`}
        />
      )}

      {transferData && (
        <TransferPassesModal
          errors={errors}
          submit=""
          data={transferData}
          handleModalClose={handleModalClose}
          fetchPasses={fetchPasses}
          fetchTransfers={fetchTransfers}
        />
      )}

      {activateClicked ? (
        <Modal handleClose={() => setActivateClicked()} title="Confirmation Modal" width={500} open={!!activateClicked}>
          <Box>
            <Typography sx={{ width: '100%', textAlign: 'center' }}>
              Are you sure you want to activate
            </Typography>
            <Typography sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
              {activateClicked.passName}
              {' '}
              <span className="bold primary-color">
                {`${activateClicked.type} ${activateClicked.type.includes('Pack') ? '' : 'Pack'}?`}
              </span>
            </Typography>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Button style={{ width: '100%' }} bordered onClick={() => setActivateClicked()}>
                No
              </Button>
              <Button style={{ width: '100%' }} onClick={() => { submitRenew(activateClicked); setActivateClicked(); }}>
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : ''}
    </div>
  );
}
