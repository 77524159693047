import { Grid, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Icon from '../../components/Icon';

export default {};

export const colConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const affiliateColumnsConfig = (t) => [
  {
    field: 'description',
    minWidth: 930,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `$${value}`,
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
];

export const eventsDummy = [
  {
    id: 0,
    date: '27 Feb 2024',
    title: 'Grand Opening at Affiliate World Conference Dubai!',
    description: 'Join us on center stage for our grand opening event taking place February 28 and 29 in Dubai!',
  },

];

export const rankRowsDummy = [
  {
    id: 0,
    rank: '',
    name: '',
  },
  {
    id: 1,
    rank: '',
    name: '',
  },
];

export const salesColumnsConfig = (t) => [
  {
    field: 'displayName',
    headerName: 'Name',
    flex: 0.3,
    minWidth: 380,
    ...colConfig,
    renderCell: ({ row }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography xs={12}>
            {row.displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ color: '#919191' }}>
            {row.username}
            {' '}
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'isAffiliate',
    headerName: t('Ambassador'),
    flex: 0.1,
    minWidth: 100,
    ...colConfig,
    renderCell: ({ value }) => (
      <Grid item xs={12}>
        {value ? (
          <div>
            <Icon height={20} width={20} name="Checkmark" />
          </div>
        ) : (
          <div>
            <Icon height={20} width={20} name="UserX" />
          </div>
        )}
      </Grid>
    ),
  },
  {
    field: 'isProduct',
    headerName: t('Gamer'),
    flex: 0.1,
    minWidth: 100,
    ...colConfig,
    renderCell: ({ value }) => (
      <Grid item xs={12}>
        {value ? (
          <div>
            <Icon height={20} width={20} name="Checkmark" />
          </div>
        ) : (
          <div>
            <Icon height={20} width={20} name="UserX" />
          </div>
        )}
      </Grid>
    ),
  },
  {
    field: 'dateJoined',
    headerName: t('Signup Date'),
    flex: 0.15,
    minWidth: 170,
    ...colConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'dateExpiring',
    headerName: t('Expiration Date'),
    flex: 0.15,
    minWidth: 170,
    ...colConfig,
    renderCell: ({ value, row }) => {
      const daysDiff = dayjs(dayjs(value).tz('GMT')).tz('GMT').diff(dayjs().tz('GMT').toDate(), 'days');

      let expirationColor = 'var(--text-color)';

      expirationColor = daysDiff < 4 ? 'var(--warning)' : expirationColor;
      expirationColor = daysDiff < 1 ? 'var(--toastify-icon-color-error)' : expirationColor;

      if (row.isAffiliate && !row.isProduct) {
        return (
          <div>
            <Typography> -</Typography>
          </div>
        );
      }

      return (
        <span style={{ color: expirationColor }} data-days={daysDiff}>
          {`${dayjs(value).tz('GMT').format('MMM DD, YYYY')}`}
        </span>
      );
    },
  },
  {
    field: 'icons',
    flex: 0.15,
    headerName: '',
    align: 'right',
    columnAlign: 'right',
    type: 'actions',
    headerAlign: 'right',
    minWidth: 180,
    renderCell: (_data) => {
      const active = dayjs(_data.row.dateExpiring).tz('GMT').diff(dayjs().tz('GMT').toDate(), 'days') > 0;
      return (
        <div className="flex justify-between items-center ">
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              _data.row.training ? (
                <Tooltip title={t('Training: A User that is one of the first 2 users brought in by their sponsor')}>
                  <div>
                    <Icon height={20} width={20} name="UserConfig" />
                  </div>
                </Tooltip>
              ) : ''
            }
          </div>
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              !active ? (
                <Tooltip id="tooltup" title={t('Inactive: A Gamer that has not paid their monthly or yearly gamer Pack fee')}>
                  <div>
                    <Icon style={{ marginBottom: '3px' }} height={17} width={17} name="UserX" />
                  </div>
                </Tooltip>
              ) : ''
            }
          </div>
          <div style={{ width: '30px', maxWidth: '30px' }}>
            {
              _data.row.qualified ? '' : (
                <Tooltip title={t('Inactive: A User that has not yet signed 3 Active Qualified Ambassadors')}>
                  <div>
                    <Icon height={25} width={25} name="UserLupa" />
                  </div>
                </Tooltip>
              )
            }
          </div>
        </div>
      );
    },
  },
];

export const salesRowsDummy = [

];

export const rankColumnsConfig = (t) => [
  {
    field: 'name',
    headerName: t('NAME'),
    flex: 0.5,
    ...defaultConfig,

  },
  {
    field: 'rank',
    headerName: '',
    headerAlign: 'right',
    flex: 0.5,
    columnAlign: 'right',
    renderCell: ({ value }) => (
      <Typography sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        {value}
      </Typography>
    ),
  },

];

export const rankProgressDummy = [
  {
    id: 0,
    type: 'PV',
    value: '0',
    valueNext: '450',
    valuePrev: '0',

  },
  {
    id: 1,
    type: 'TV',
    value: '0',
    valueNext: '0',
    valuePrev: '0',

  },
];

export const newsTabs = [

  {
    id: 0,
    name: 'Crypto News',
  },
  {
    id: 1,
    name: 'Tech News',
  },
  {
    id: 2,
    name: 'Real Estate News',
  },

];

export const cryptoSpanOptions = [
  {
    label: '24H', value: '24H',
  },
  {
    label: '1W', value: '1W',
  },
  {
    label: '1M', value: '1M',
  },
  {
    label: '1Y', value: '1Y',
  },
];

export const dummyNews = {
  0: [
    {
      id: 0,
      title: 'Crypto News 1',
      description: 'Crypto Description',
      url: '',
    },
    {
      id: 1,
      title: 'Crypto News 2',
      description: 'Crypto Description',
      url: '',

    },
    {
      id: 2,
      title: 'Crypto News 3',
      description: 'Crypto Description',
      url: '',

    },
  ],
  1: [
    {
      id: 0,
      title: 'Tech News 1',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 1,
      title: 'Tech News 2',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 2,
      title: 'Tech News 3',
      description: 'Tech Description',
      url: '',

    },
  ],
  2: [
    {
      id: 0,
      title: 'Real Estate News 1',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 1,
      title: 'Real Estate News 2',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 2,
      title: 'Real Estate News 3',
      description: 'Real Estate Description',
      url: '',

    },
  ],
};

export const aiExperts = [

  {
    id: 0,
    name: 'Matt the Tech Guy',
    descriptiom: 'Matt has been carefully trained by the TREC Global team to answer questions about foundational and emerging strategies for investing in Technology opportunities.',
    placeholder: 'What is momentum trading?',
  },
  {
    id: 1,
    name: 'Ms. Jones',
    descriptiom: 'Ms. Jones was trained by Real Estate Millionaires to answer questions on strategy for residential, commercial and industrial trends and insights.',
    placeholder: 'What is wholesaling?',
  },
  {
    id: 2,
    name: 'BTCharles',
    descriptiom: 'BTCharles (chuck for short) is trained as an expert on terminology, current trends, technology behind each coin, and current pricing.',
    placeholder: 'What is swing trading?',
  },

];

export const challengePacksPrecedence = [
  { id: '16bdef15-69c4-4e97-acfa-138e7353b90c', name: 'Starter Pack Reserve', precedence: 1 },
  { id: '772e3a95-9819-47fd-a51f-5bdf564287a6', name: '1 Star Founder Director Upgrade', precedence: 2 },
  { id: '3bef2d92-551c-44f6-9091-36be2a4a9b3e', name: '2 Star Founder Director Upgrade', precedence: 3 },
  { id: '13767c4a-a304-4ec9-a831-3601595f1069', name: '3 Star Founder Director Upgrade', precedence: 4 },
  { id: 'ddaaf7d8-c233-4a4d-9ede-f238904465d1', name: '4 Star Founder Director Upgrade', precedence: 5 },
  { id: 'f5d5a1f9-e407-4e14-b534-2d000ae408b1', name: '5 Star Founder Director Upgrade', precedence: 6 },
  { id: '60476f2c-6245-43a4-843c-da932e3ad64a', name: '1 Star Founder Challenge', precedence: 7 },
  { id: '3076c45c-f289-4c66-8a01-8de8b81c3248', name: '2 Star Founder Challenge', precedence: 8 },
  { id: '8cf133df-20dc-444b-8f81-7cc1ce0ecd94', name: '3 Star Founder Challenge', precedence: 9 },
  { id: '0c74070c-9d5d-4276-b85c-364d9eb58691', name: '4 Star Founder Challenge', precedence: 10 },
  { id: 'd8ed2885-e390-4f02-b826-e9e8d2623231', name: '5 Star Founder Challenge', precedence: 11 },
  { id: 'cff4529a-e63e-46b0-b2ef-5a9b65d1a6ec', name: 'MemeGamer Challenge 2 Level I', precedence: 12 },
  { id: 'a31e8e91-67ef-47e8-a570-5f47e94b81d9', name: 'MemeGamer Challenge 2 Level II', precedence: 13 },
  { id: '86623ec0-be17-4cb1-8330-2e0a2a6a3d0e', name: 'MemeGamer Challenge 2 Level III', precedence: 14 },
  { id: 'ecb22ae6-c276-41cb-8534-95acfb14d5f1', name: 'MemeGamer Challenge 2 Level IV', precedence: 15 },
  { id: 'f6973b15-3f51-4695-b04c-6a7750446565', name: 'MemeGamer Challenge 2 Level IX', precedence: 16 },
  { id: '8fb63673-36a0-47e6-b801-f9af41a3d63f', name: 'MemeGamer Challenge 2 Level V', precedence: 17 },
  { id: '6a6c5dc0-5f88-4e2f-9e31-6a24fe15b5b7', name: 'MemeGamer Challenge 2 Level VII', precedence: 18 },
  { id: '8abae7f7-8e52-4147-91ca-fea73e036c2c', name: 'MemeGamer Challenge 2 Level VIII', precedence: 19 },
  { id: '86b92bb6-6e15-4f9e-99c4-3ef7f1606da3', name: 'MemeGamer Challenge 2 Level X', precedence: 20 },
  { id: '8a47340e-9657-405a-b850-ce2a52afc6e1', name: 'MemeGamer Challenge Level 2 VI', precedence: 21 },
  { id: '963be2f3-bf58-44b9-8036-abdc58a405b9', name: 'Rank Up Challenge Five Star Founder', precedence: 22 },
  { id: 'fa3fb460-a31d-4831-83ec-cb7ee326431e', name: 'Rank Up Challenge Four Star Founder', precedence: 23 },
  { id: 'f440ea3a-69e7-4a9f-af70-e833e29968e8', name: 'Rank Up Challenge One Star Founder', precedence: 24 },
  { id: 'b53dbaa3-c150-4ac4-b9f7-a19e6a6aee20', name: 'Rank Up Challenge Three Star Founder', precedence: 25 },
  { id: '511701bf-c365-4290-90b3-182ecf0e6a1d', name: 'Rank Up Challenge Two Star Founder', precedence: 26 },
  { id: '4b035038-ecdb-49ba-b228-28959e4a1fb8', name: 'Founder Challenge', precedence: 27 },
  { id: 'a8677291-fd82-4a1b-944b-642f348d8a41', name: '1 Star Founder Challenge Reserve', precedence: 28 },
  { id: 'cf12650a-c230-434d-b48e-c0e7ca4daab9', name: '2 Star Founder Challenge Reserve', precedence: 29 },
  { id: 'b0fe850b-59f1-46b7-b88d-6907f9d9134e', name: '3 Star Founder Challenge Reserve', precedence: 30 },
  { id: '99d6c62f-26b0-429e-aba7-86c9f6231c84', name: '4 Star Founder Challenge Reserve', precedence: 31 },
  { id: 'dafa3d7b-63a2-4557-ac01-9e6d515eb0cf', name: '5 Star Founder Challenge Reserve', precedence: 32 },
  { id: '325175c4-6fc6-42fc-9d00-4b5d06d537d5', name: 'Founder Challenge Reserve', precedence: 33 },
  { id: 'cc650aa0-5131-4185-b158-c054ae22eaad', name: 'Director Challenge', precedence: 34 },
  { id: '51378bc7-afc9-4159-968b-9e624368b08d', name: 'Director Challenge Reserve', precedence: 35 },
  { id: 'c8b0c3ab-c104-412c-a242-9e2a1f5f9a72', name: 'Gold Director Challenge', precedence: 36 },
  { id: 'a1f09fb1-c8f8-49a5-b09f-b8d22dae8267', name: 'Gold Director Challenge Reserve', precedence: 37 },
  { id: 'f1bfb5c0-1289-4648-a857-5578213b58d1', name: 'Platinum Director Challenge', precedence: 38 },
  { id: '963d4c95-288f-475c-b4df-2f505a4e44b1', name: 'Platinum Director Challenge Reserve', precedence: 39 },
];
