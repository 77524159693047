/* eslint-disable indent */
import {
  Grid, Pagination, Typography,
} from '@mui/material';
import { useContext, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentLoader from '../../../../components/ComponentLoader';
import GamesGroup from './GamesGroup';
import Select from '../../../../components/Inputs/Select';
import { useGameHistory, useGamerTokens, groupGamesByDate } from '../context/hooks';
import { AuthenticationContext } from '../../../../utils/store/authentication';
 
 const GameHistory = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthenticationContext);
  const { userId } = useParams();
  const [gameResultFilter, setGameResultFilter] = useState('default');
  const [gamePriceFilter, setGamePriceFilter] = useState('default');
  const [gameDaysFilter, setGameDaysFilter] = useState('default');
  const [currentPage, setCurrentPage] = useState(1);
  
  const gameHistory = useGameHistory({
    page: currentPage,
    perPage: 10,
    game: gameResultFilter,
    price: gamePriceFilter,
    days: gameDaysFilter,
    userId,
  });
  
  const tokens = useGamerTokens(userId);
  
  const groupGames = useMemo(() => groupGamesByDate(gameHistory.data ? gameHistory.data.data : []), [gameHistory.data]);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Grid id="game-history-page">

      <Grid
        maxWidth="100%"
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 2,
        }}
        className="game-history-panel"
      >
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          className="game-history-filters"
        >
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Won/Lost:
            </b>
            <Select
              name="transfers-filter-select"
              className="transfers-filter-select"
              placeholder="All games"
              size="sm"
              value={gameResultFilter}
              onChange={(v) => {
                setGameResultFilter(v);
              }}
              options={[
                { value: 'true', label: 'Won' },
                { value: 'false', label: 'Lost' },
              ]}
            />
          </div>
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Price:
            </b>
            <Select
              className="transfers-filter-select"
              placeholder="Any price"
              size="sm"
              value={gamePriceFilter}
              onChange={(v) => {
                setGamePriceFilter(v);
              }}
              options={[
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                { value: '100', label: '100' },
              ]}
            />
          </div>
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Date/Range:
            </b>
            <Select
              className="transfers-filter-select"
              placeholder="Show All"
              size="sm"
              value={gameDaysFilter}
              onChange={(v) => {
                setGameDaysFilter(v);
              }}
              options={[
                { value: '3', label: 'Last 3 days' },
                { value: '7', label: 'Last 7 days' },
                { value: '15', label: 'Last 15 days' },
                { value: '30', label: 'Last 30 days' },
                { value: '90', label: 'Last 90 days' },
              ]}
            />
          </div>
        </Grid>

        <Grid
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sm={12}
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
            gap: 4,
          }}
          className="game-history-list"
        >
          {gameHistory.data ? (
            <>
              {Object.entries(groupGames).length === 0
                && gameResultFilter === 'default'
                && gamePriceFilter === 'default' && (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '26px',
                      fontWeight: 600,
                      py: 4,
                      width: '100%',
                    }}
                  >
                    You haven’t joined any games yet!
                  </Typography>
                )}
              {Object.entries(groupGames).length === 0
                && (gameResultFilter !== 'default'
                  || gamePriceFilter !== 'default') && (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '26px',
                      fontWeight: 600,
                      py: 4,
                      width: '100%',
                    }}
                  >
                    No games under this conditions.
                  </Typography>
                )}
              {Object.entries(groupGames).map((games, index) => (
                <GamesGroup
                  date={games[0]}
                  games={games[1]}
                  key={index}
                  userTokens={tokens.data ? tokens.data.token : 0}
                  support
                />
              ))}

              {!!gameHistory.data && gameHistory.data.total > 10 && (
                <div className="flex justify-end pagination-container">
                  <Typography sx={{ mt: 0.5 }}>
                    {`Total of: ${gameHistory.data.total} Games`}
                  </Typography>
                  <Pagination
                    count={Math.ceil(gameHistory.data.total / 10)}
                    page={currentPage}
                    onChange={(val, pageNumber) => {
                      setCurrentPage(pageNumber);
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <ComponentLoader />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GameHistory;
