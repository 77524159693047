/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const renderAdminInfo = (user, currentUser) => (
  <>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`ID: ${user.userId || currentUser?.SID}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`EPID: ${user.enrolledParentId || currentUser?.EnrolledParentID}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`T1: ${user.team1Id || currentUser?.team1Id}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`T2: ${user.team2Id || currentUser?.team2Id}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`T3: ${user.team3Id || currentUser?.team3Id}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'white' }}>
      {`Active Team: ${user.activeTeam || currentUser?.activeTeam}`}
    </Typography>
  </>

);

export default function UserNode({ user, currentUser }) {
  const ambassadorDisplayName = `${user.firstName} ${user.lastName?.substring(1, 0)}.`;
  const isDeleted = user?.deleted;
  return (
    <div style={{ position: 'relative' }}>
      {!isDeleted && (
        <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>
          {user.username}
        </Typography>
      )}

      <Typography sx={{ fontSize: '12px', color: 'gray', ...(isDeleted && { textDecoration: 'line-through' }) }}>
        {ambassadorDisplayName}
      </Typography>
      {currentUser?.SID === user.enrolledParentId && (
        <Typography
          variant="span"
          sx={{
            width: '5px',
            height: '5px',
            position: 'absolute',
            right: '-15px',
            top: '0',
            backgroundColor: 'var(--primary-color)',
            borderRadius: '50%',
          }}
        />
      )}
      {(user?.isProduct && !isDeleted) && (
        <Typography sx={{ fontSize: '12px', color: 'gray' }}>
          {`${dayjs(user?.dateExpiring).tz('UTC').format('MMM DD, YYYY')}`}
        </Typography>
      )}
      {/* {currentUser?.Username === 'glek' && ( */}
      {/* {renderAdminInfo(user, currentUser)} */}
      {/* )} */}
    </div>
  );
}
