import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useApi from '../../../utils/api';
import { renderNumber } from '../../../utils/string';

export const useSimulator = () => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator'],
    queryFn: async () => {
      const response = await authGet('simulator/user');
      return response;
    },
    staleTime: 1000 * 60 * 10,
  });
};

export const useSimulatorGameCoins = () => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator-game-coins'],
    queryFn: async () => {
      const response = await authGet('simulator');
      return response.data || [];
    },
  });
};

export const useSimulatorMyCoins = (userId) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator-my-coins', userId],
    queryFn: async () => {
      const response = await authGet(userId ? `simulator/holder/${userId}` : 'simulator/holder');
      return response;
    },
    staleTime: 1000 * 60 * 0,
  });
};

export const useSimulatorMyCoinsHistory = ({ userId, page = 1, perPage = 20 }) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator-my-coin-history', userId, page, perPage],
    queryFn: async () => {
      const response = await authGet(userId ? `simulator/holder/history/${userId}?page=${page}&perPage=${perPage}` : `simulator/holder/history?page=${page}&perPage=${perPage}`);
      return response;
    },
    staleTime: 1000 * 60 * 0,
  });
};

export const useSimulatorRedeemCoins = ({ onSuccess }) => {
  const { authPost } = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      console.log('data', data);  
      const response = await authPost('simulator/redeem', { data });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['simulator'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coins'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coin-history'] });
      if (onSuccess) {
        onSuccess(data);
      }
      toast.success('Swap In successful');
    },
    onError: (error) => {
      toast.error(typeof error === 'string' ? error : error?.response?.data?.message);
    },
  });
};

export const useSimulatorRedeemMGTokens = ({ onSuccess }) => {
  const { authPost } = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      console.log('data', data);  
      const response = await authPost('simulator/buy', { data });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['simulator'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coins'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coin-history'] });
      if (onSuccess) {
        onSuccess(data);
      }
      toast.success('Swap In successful');
    },
    onError: (error) => {
      toast.error(typeof error === 'string' ? error : error?.response?.data?.message);
    },
  });
};

export const useSimulatorWithdrawCoins = ({ onSuccess }) => {
  const { authPost } = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await authPost('simulator/withdraw', { data });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['simulator'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coins'] });
      queryClient.invalidateQueries({ queryKey: ['simulator-my-coin-history'] });
      if (onSuccess) {
        onSuccess(data);
      }
      toast.success('Swap Out successful');
    },
    onError: (error) => {
      toast.error(typeof error === 'string' ? error : error?.response?.data?.message);
    },
  });
};

export const useSimulatorSettings = () => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['simulator-settings'],
    queryFn: async () => {
      const response = await authGet('/simulator/settings');
      return response.data;
    },
    staleTime: 1000 * 60 * 0,
  });
};

export const increment = 1;
export const minAmount = 1;
// const MGTokens = 10000;
// export const mgToken = 1;

export function getMarks({ max, min }) {
  return [
    {
      value: min,
      label: `${renderNumber(min)}`,
    },
    {
      value: Math.ceil(max / 2),
      label: `${renderNumber(Math.ceil(max / 2))}`,
    },
    {
      value: max,
      label: `${renderNumber(max)}`,
    },
  ]; 
}

export function convertUSDToMgTokens({ mgTokensAmount, mgToken }) {
  const unitConst = 1 / mgToken;
  const total = unitConst * mgTokensAmount;
  return total;
}

export function convertMgTokensToCoins({ mgTokensAmount, coinPrice, mgToken }) {
  const unitConst = mgToken / coinPrice;
  const total = unitConst * mgTokensAmount;
  return total;
}

export function convertCoinsToMgTokens({ coinAmount, coinPrice, mgToken }) {
  const unitConst = coinPrice / mgToken;
  const total = unitConst * coinAmount;
  return total;
}

export function calcMinCoinsToBeMgToken({ coinPrice, mgToken }) {
  const unitConst = mgToken / coinPrice;
  if (unitConst < 1) {
    const unit = coinPrice / mgToken;
    return unit;
  } 
  
  return unitConst;
}
