import { Typography, Grid, Item } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PassTypeCard({ data, onPassTypeClick = () => { } }) {
  return (
    <div style={{
      position: 'relative',
      background: 'linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%)',
      borderRadius: '20px',
      padding: '20px',
      margin: '10px',
    }}
    >
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'white',
        }}
      >
        <span style={{
          backgroundColor: data.active ? 'green' : 'red', padding: '2px 10px', borderRadius: '20px', fontWeight: 'bold', fontSize: '12px',
        }}
        >
          {`${data.active ? 'Active' : 'Inactive'}`}
        </span>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onPassTypeClick(data);
          }}
        />
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          marginBottom: '8px',
          marginTop: '10px',
         
        }}
      >
        <span className="primary-text-gradient">{data.name}</span>
        
      </Typography>
      <Typography sx={{ opacity: 0.5, marginBottom: '10px' }}>{data.description}</Typography>
      
      <Grid
        container
        spacing={0}
      >
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Price :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>
              $
              {Number(data.price || 0).toLocaleString('en-us')}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Available Days :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{data.days}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            MG Coins :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{`${data.points ? data.points : 0}`}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Show on signup :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{`${data.useOnSignUp ? 'Yes' : 'No'}`}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Calculate as PV :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{`${data.calculateOnPv ? 'Yes' : 'No'}`}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Transferable :
            <span className="pl-2 " style={{ fontWeight: 'bold' }}>{`${data.transferable ? 'Yes' : 'No'}`}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Activate Immediately :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{`${data.activateImmediately ? 'Yes' : 'No'}`}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Purchase with :
            <span className="pl-2" style={{ fontWeight: 'bold' }}>{`${data.purchaseWith}`}</span>
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          {data.addTeamBonus && (
          <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            Team Bonus Amount :
            <span className="pl-2">
              $
              {Number(data.teamBonusAmount || 0).toLocaleString('en-us')}
            </span>
          </Typography>
          )}
        </Grid>
        
        <Grid item xs={12}>
          <Typography style={{ fontSize: '14px' }}>
            ID :
            <span className="pl-2" style={{ fontWeight: 'bold', fontSize: '12px' }}>{`${data.id}`}</span>
          </Typography>
        </Grid>
       
      </Grid>

    </div>
  );
}
