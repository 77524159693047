import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultTaxFormsColumnsConfig = (
  t,
  setTaxFormReviewData,
  setTaxFormDeleteData,
  totalCount,
) => [
  {
    field: 'user',
    headerName: t(`User (Total: ${totalCount})`),
    flex: 1,
    minWidth: 250,
    ...defaultColConfig,
    renderCell: ({ row }) => `${row.username} - (${row.userId}) - ${row.email}`,
  },
  {
    field: 'createdAt',
    headerName: t('Submit Date'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).format('YYYY-MM-DD'),
  },
  {
    field: 'fileUrl',
    headerName: t('Download Form'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <Link
        to={row.fileUrl}
        target="_blank"
        className="btn-link primary-text-gradient"
      >
        <DownloadIcon />
        <span>{row.formType}</span>
      </Link>
    ),
  },
  {
    field: 'id',
    headerName: t('Review'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <div className="flex justify-center" style={{ gap: '10px' }}>
        <Tooltip placement="top" title="Review Form">
          <AssignmentTurnedInIcon
            cursor="pointer"
            color="primary"
            onClick={() => setTaxFormReviewData(row)}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete Tax Form">
          <DeleteIcon
            cursor="pointer"
            color="primary"
            onClick={() => setTaxFormDeleteData(row)}
          />
        </Tooltip>
      </div>
    ),
  },
];

export const processingTaxFormsColumnsConfig = (
  t,
  totalCount,
) => [
  {
    field: 'user',
    headerName: t(`User (Total: ${totalCount})`),
    flex: 1,
    minWidth: 250,
    ...defaultColConfig,
    renderCell: ({ row }) => `${row.username} - (${row.userId}) - ${row.email}`,
  },
  {
    field: 'createdAt',
    headerName: t('Submit Date'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).format('YYYY-MM-DD'),
  },
  {
    field: 'fileUrl',
    headerName: t('Download Form'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <Link
        to={row.fileUrl}
        target="_blank"
        className="btn-link primary-text-gradient"
      >
        <DownloadIcon />
        <span>{row.formType}</span>
      </Link>
    ),
  },
];

export const completedTaxFormsColumnsConfig = (
  t,
  totalCount,
) => [
  {
    field: 'user',
    headerName: t(`User (Total: ${totalCount})`),
    flex: 1,
    minWidth: 250,
    ...defaultColConfig,
    renderCell: ({ row }) => `${row.username} - (${row.userId}) - ${row.email}`,
  },
  {
    field: 'createdAt',
    headerName: t('Submit Date'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).format('YYYY-MM-DD'),
  },
  {
    field: 'fileUrl',
    headerName: t('Download Form'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <Link
        to={row.fileUrl}
        target="_blank"
        className="btn-link primary-text-gradient"
      >
        <DownloadIcon />
        <span>{row.formType}</span>
      </Link>
    ),
  },
];

export const defaultFormValues = {};
