import {
  Box, Checkbox, FormControlLabel, Grid, Typography, 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { useContext, useEffect } from 'react';
import Button from '../../../../components/Button';
import { AdminTransactionsContext } from '../context';
import CustomAutocomplete from '../../../../components/Inputs/CustomAutocomplete';
import Select from '../../../../components/Inputs/Select';
import ResultsBox from '../../../AdminReports/components/ResultsBox';
import { renderNumber } from '../../../../utils/string';
import AddTransactionModal from './AddTransactionModal';

const TransactionsFilters = () => {
  const {
    t,
    selectedUser,
    transactionsDataLoading,
    setSelectedUser,
    filterTransactions,
    setTransactionsData,
    walletTypeFilter,
    setWalletTypeFilter,
    directionFilter,
    setDirectionFilter,
    latestFirstFilter,
    setLatestFirstFilter,
    walletsData,
    setWalletsData,
  } = useContext(AdminTransactionsContext);

  useEffect(() => {
    if (!selectedUser || transactionsDataLoading) {
      setTransactionsData([]);
      setWalletsData(null);
      return;
    }

    filterTransactions();
  }, [selectedUser, latestFirstFilter, walletTypeFilter, directionFilter]);

  return (
    <Grid
      container
      className="transactions-filters"
      display="flex"
      my={4}
    >
      <Grid container spacing={4} display="flex" alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <CustomAutocomplete
            endPoint="/users"
            name="users"
            mapper={(item) => ({ value: item.SID, label: `${item.Username} - (${item.SID})` })}
            value={selectedUser}
            onChange={(v) => setSelectedUser(v)}
            disabled={false}
            error={false}
            size="sm"
            placeholder="By User"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AddTransactionModal />
        </Grid>
      </Grid>
      {!!selectedUser && walletsData && (
        <Grid container>
          <Grid container spacing={4} sx={{ display: 'flex', paddingY: '1.5rem', justifyContent: 'center' }}>
            <Grid item xs={12} md={6} xl={4}>
              <ResultsBox
                title="Default Wallet"
                result={`$${renderNumber(walletsData?.default?.balance || 0)}`}
                text
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <ResultsBox
                title="Gamer Wallet"
                result={`${renderNumber(walletsData?.gamer?.coins || 0)} MG Coins | ${renderNumber(walletsData?.gamer?.token || 0)} MG Tokens`}
                text
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} xl={2}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="SortDate"
                    value={latestFirstFilter}
                    checked={latestFirstFilter}
                    onChange={() => setLatestFirstFilter((prev) => !prev)}
                    sx={{ color: 'white', fontSize: '12px !important' }}
                  />
                )}
                label={(
                  <div className="flex">
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        letterSpacing: '1.2px',
                      }}
                    >
                      {t('Latests First')}
                    </Typography>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Select
                className="wallet-type-filter"
                size="sm"
                value={walletTypeFilter}
                onChange={(e) => setWalletTypeFilter(e)}
                options={[
                  { value: 'ambassador', label: 'Ambassador Wallet' },
                  { value: 'gamer', label: 'Gamer Wallet' },
                ]}
                sx={{ paddingRight: '1rem' }}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <Select
                className="transaction-type-filter"
                placeholder="Transaction Type"
                size="sm"
                value={directionFilter}
                onChange={(e) => setDirectionFilter(e)}
                options={[
                  { value: 'receive', label: 'Credits' },
                  { value: 'deducted', label: 'Debits' },
                ]}
                sx={{ paddingRight: '1rem' }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionsFilters;
