/* eslint-disable no-unused-vars */
import { useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../../utils/api';

export default function useRenewMembership(refresh = () => { }) {
  const [errors, setErrors] = useState(null);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { authPost } = useApi();
  const [activeTab, setActiveTab] = useState(0);
  const [purchaseModalVisible, setPurchaseModalVisible] = useState(false);

  const tabsConfig = useMemo(() => [
    { label: 'Pack', isActive: activeTab === 0, onClick: () => setActiveTab(0) },
    { label: 'Bundle', isActive: activeTab === 1, onClick: () => setActiveTab(1) },
  ], [activeTab]);

  const submitRenew = useCallback(async (data) => {
    setIsLoading(true);
    try {
      const _token = data?.pass || token;
      if (!_token) {
        toast.error('Pack Key is required');
      } else {
        const result = await authPost('/passes/consume', { data: { token: _token } });
        toast.success(result.message);
        setErrors(null);
        refresh();
        toast.success('Pack Activated Successfully!');
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      if (err === 'Invalid pass token') {
        setErrors({ pass: err });
        toast.error('Invalid Pack Token!');
      } else {
        setErrors({ pass: 'An error occurred. Please try again later!' });
        toast.error(err);
      }
      setIsLoading(false);
    }
  }, [token]);

  return {
    token,
    activeTab,
    tabsConfig,
    setToken,
    submitRenew,
    isLoading,
    setIsLoading,
    errors,
    purchaseModalVisible,
    setPurchaseModalVisible,
  };
}
