import {
  Grid, Typography, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Modal from '../../../../components/Modal';
import ChangeBadges from '../../../../components/ChangeBadges';
import { useBreakpoint } from '../../../../utils/store'; 
import HistoryTable from './HistoryTable';
import { renderNumber } from '../../../../utils/number';

export default function SimulatorWithdrawModal({ currentCoin, isOpen, handleClose }) {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useBreakpoint();
  return (
    <Modal
      width="900px"
      title={`${currentCoin.coinName} Coin History`}
      open={isOpen}
      handleClose={handleClose}
    >
    
      <Grid
        container
        size={12}
        gap={3}
        style={{
          maxWidth: '500px', margin: '0 auto', marginBottom: '30px', marginTop: '-10px', alignItems: 'center',
        }}
      >
        <Grid
          item
        >
          <img
            style={{ width: isMobile || isTablet ? '80px' : '94px', height: isMobile || isTablet ? '80px' : '94px', borderRadius: '90px' }}
            src={currentCoin.coinImage}
            alt={currentCoin.coinName}
          />
         
        </Grid>
        <Grid
          item
          sx={{ flex: 1 }}
        >
          <Typography
            fontSize="14px"
            fontWeight={500}
            marginBottom="0px"
            marginRight="auto"
            sx={{ marginTop: '0px' }}
          >
            {currentCoin.currentPrice.toFixed(8)}
          </Typography>
      
          <Typography
            fontSize="12px"
            fontWeight={500}
            marginBottom="0px"
            marginRight="auto"
            sx={{ marginTop: '0px', opacity: '0.5', marginBottom: '10px' }}
          >
            {t('First price')}
          </Typography>
          
          <Tooltip style={{ zIndex: 999 }} placement="top" title={`${dayjs(currentCoin.currentPriceDate).tz('GMT').format('MMM DD, YYYY HH:mm:ss')} GMT`}>
            <>
              <Typography
                fontSize="14px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '0px' }}
              >
                {currentCoin.currentPrice.toFixed(8)}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '0px', opacity: '0.5', marginBottom: '10px' }}
              >
                {t('Current price')}
              </Typography>
            </>
          </Tooltip>
         
          <ChangeBadges
            value={`${currentCoin.change24Hours ? currentCoin.change24Hours.toFixed(2) : 0}%`}
            increase={currentCoin.change24Hours ? currentCoin.change24Hours > 0 : false}
          />
        </Grid>
        <Grid
          item
          sx={{ flex: 1 }}
        >
         
          <div style={{
            border: '1px solid rgb(255 255 255 / 14%)',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center', 
          }}
          >
    
            <div style={{ flex: 1 }}>
              <Typography
                fontSize="20px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
              >
               
                <span className="primary-text-gradient">{ renderNumber(currentCoin.balance.toFixed(2)) }</span>
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={500}
                marginBottom="0px"
                marginRight="auto"
                sx={{ marginTop: '-5px', opacity: '0.5' }}
              >
                {t(`My ${currentCoin.coinName} Coins`)}
              </Typography>
            </div>
           
          </div>
        </Grid>
      </Grid>
      <HistoryTable rows={currentCoin.history} />
    </Modal>
  );
}
