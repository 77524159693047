import { useContext } from 'react';
import { AdminTransactionsContext } from '../../context';
import Form from '../../../../../components/Form';
import { ambassadorTransactionFormConfig, ambassadorTransactionFormDefaultValues } from '../../config';

const AmbassadorTransactionForm = () => {
  const {
    t,
    handleAmbassadorTransactionFormSubmit,
    ambassadorTransactionFormLoading,
    ambassadorTransactionFormErrors,
    setAmbassadorTransactionFormErrors,
  } = useContext(AdminTransactionsContext);

  return (
    <Form
      size="sm"
      defaultFormValue={ambassadorTransactionFormDefaultValues}
      config={ambassadorTransactionFormConfig}
      onSubmit={handleAmbassadorTransactionFormSubmit}
      errors={ambassadorTransactionFormErrors}
      onErrors={setAmbassadorTransactionFormErrors}
      buttonType="button"
      buttonStyle={{ width: '100%', marginTop: 0 }}
      submitLabel={t('Submit')}
      formLoading={ambassadorTransactionFormLoading}
    />
  );
};

export default AmbassadorTransactionForm;
