import { Grid, Typography, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import Price from '../../../../components/Price';
import Button from '../../../../components/Button';
import ChangeBadges from '../../../../components/ChangeBadges';
import { renderNumber } from '../../../../utils/number';

export const columns = ({
  setOpenRedeem, setOpenWithdraw, setCurrentCoin, setOpenHistoy, 
}) => [
  {
    field: 'coinName',
    headerName: 'Coin',
    flex: 1,
    renderCell: ({ row, value }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Tooltip style={{ zIndex: 999 }} placement="top" title="View the history">
          <Grid item xs={12}>
            <button
              type="button"
              onClick={() => {
                setCurrentCoin(row);
                setOpenHistoy(true);
              }}
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
              }}
              onMouseEnter={(e) => {
                e.target.style.color = '#78e4ff';
              }}
              onMouseLeave={(e) => {
                e.target.style.color = '#fff';
              }}
            >
              <span className="coin-image" style={{ float: 'left' }}>
                <img src={row?.coinImage} alt={value.coinName} />
              </span>
              <Typography className="coin-name">
              
                {renderNumber(row.balance.toFixed(2))} 
                {' '}
                {row.coinName}
                {' '}
                coins
              
              </Typography>
            </button>
          </Grid>
        </Tooltip>
        
      </Grid>
    ),
  },
  {
    field: 'currentPrice',
    headerName: 'current price',
    width: 160,
    renderCell: ({ value }) => (
      <Tooltip style={{ zIndex: 999 }} placement="top" title={`${dayjs(value).tz('GMT').format('MMM DD, YYYY HH:mm:ss')} GMT`}>
        <div><Price className="coin-price" value={value.toFixed(8)} dollarSign /></div>
      </Tooltip>
      
    ),
  },

  {
    field: 'change24Hours',
    headerName: 'Change last 24h',
    width: 160,
    renderCell: ({ value }) => (
      <ChangeBadges
        value={`${value?.toFixed(2) || 0}%`}
        increase={value > 0}
      />
    ),
  },
  {
    field: 'id',
    headerName: '',
    width: 220,
    renderCell: ({ row }) => (
      <div style={{ display: 'flex', gap: '10px' }}> 
       
        <Button
          type="button"
          size="sm"
          text
          onClick={() => {
            setCurrentCoin(row);
            setOpenRedeem(true);
          }}
        >
          Swap In
        </Button>
        <Button
          type="button"
          size="sm"
          bordered
          onClick={() => {
            setCurrentCoin(row);
            setOpenWithdraw(true);
          }}
        >
          Swap Out
        </Button>
      </div>
     
    ),
  },
];

export default {};
