import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { renderNumber } from '../../../utils/string';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultWithdrawColumnsConfig = (
  t,
  setWithdrawDestinationDataModal,
  setWithdrawCancelDataModal,
  withdrawAmountTotal,
) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => `${value.Name} ${value.Surname} (${value.SID}) - ${value.Username}`,
  },
  {
    field: 'amount',
    headerName: t(`Withdraw Amount${withdrawAmountTotal > 0 ? ` (Total: $${renderNumber(withdrawAmountTotal)})` : ''}`),
    minWidth: 300,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? `$${renderNumber(value)}` : '0'),
  },
  {
    field: 'createdAt',
    headerName: t('Withdraw Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'destination',
    headerName: t('Actions'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <div className="flex" style={{ gap: 10 }}>
        <Tooltip placement="top" title="Perform Payment">
          <PriceCheckIcon
            sx={{ fontSize: '32px', color: 'var(--primary-color)', cursor: 'pointer' }}
            onClick={() => setWithdrawDestinationDataModal({
              withdrawId: row.id,
              amount: row.amount,
              destination: row.destination,
              user: `${row.User.Name} ${row.User.Surname} (${row.User.SID}) - ${row.User.Username}`,
              history: row.History,
            })}
          />
        </Tooltip>
        <Tooltip placement="top" title="Cancel">
          <DeleteForeverIcon
            sx={{ fontSize: '32px', color: '#e83c3c', cursor: 'pointer' }}
            onClick={() => setWithdrawCancelDataModal({
              withdrawId: row.id,
              amount: row.amount,
              destination: row.destination,
              user: `${row.User.Name} ${row.User.Surname} (${row.User.SID}) - ${row.User.Username}`,
            })}
          />
        </Tooltip>
      </div>
    ),
  },
];
