/* eslint-disable no-unused-expressions */
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../../utils/api';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import { validateAll } from '../../../../components/Form/config';
import { defaultBlogPostsColumns, blogPostFormValidationConfig } from '../config';

const useAdminBlogApi = () => {
  const navigate = useNavigate();
  const {
    authGet, authPost, authPut, authDelete,
  } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);

  const [postsData, setPostsData] = useState([]);
  const [postsDataLoaded, setPostsDataLoaded] = useState(false);
  const [postDelete, setPostDelete] = useState(null);
  const [postFormSubmitting, setPostFormSubmitting] = useState(false);
  const [postFormErrors, setPostFormErrors] = useState(null);
  const [postSingle, setPostSingle] = useState(null);
  const [postSingleLoaded, setPostSingleLoaded] = useState(null);

  // POST CATEGORIES
  const [openPostCategoriesModal, setOpenPostCategoriesModal] = useState(false);
  const [postCategories, setPostCategories] = useState([]);
  const [postCategoriesLoaded, setPostCategoriesLoaded] = useState(false);
  const [postCategory, setPostCategory] = useState({ id: '', title: '' });
  const [postCategoryDelete, setPostCategoryDelete] = useState(null);

  // POST TAGS
  const [openPostTagsModal, setOpenPostTagsModal] = useState(false);
  const [postTags, setPostTags] = useState([]);
  const [postTagsLoaded, setPostTagsLoaded] = useState(false);
  const [postTag, setPostTag] = useState({ id: '', title: '' });
  const [postTagDelete, setPostTagDelete] = useState(null);
  const [postTagsChecks, setPostTagsChecks] = useState([]);
  const [postTagsChecksLoaded, setPostTagsChecksLoaded] = useState(false);

  const createPostTagsChecks = useCallback(
    async (selectedTags) => {
      try {
        setPostTagsChecksLoaded(false);
        const response = await authGet('/blog/tags');

        const tagChecks = response?.data?.map((tag) => ({
          label: tag.title,
          value: tag.id,
          checked: selectedTags?.includes(tag.id),
        }));

        setPostTagsChecks(tagChecks);

        setPostTagsChecksLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Tags');
      }
    },
    [authGet],
  );

  // POSTS
  const fetchPostsData = useCallback(
    async () => {
      try {
        setPostsDataLoaded(false);
        const response = await authGet('/blog/posts');
        setPostsData(response.data || []);
        setPostsDataLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching posts');
      }
    },
    [authGet, setPostsData, setPostsDataLoaded],
  );

  const fetchPostSingleData = useCallback(
    async (slug) => {
      try {
        setPostSingleLoaded(false);
        const response = await authGet(`/blog/posts/${slug}`);
        setPostSingle(response.data || []);
        createPostTagsChecks(response.data?.tagIds || []);
        setPostSingleLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error(`Error fetchng post ${slug}`);
      }
    },
    [authGet],
  );

  const handleAddEditPostFormSubmit = useCallback(
    async (data) => {
      setPostFormSubmitting(true);

      // Format data
      const _data = {
        id: data.id,
        title: data.title,
        content: data.content_content || data.content,
        image: data.image,
        published: data.published,
        categoryId: data.categoryId,
        tagIds: postTagsChecks.filter((check) => check.checked)?.map((tag) => tag.value) ?? [],
      };

      const checkData = validateAll(_data, blogPostFormValidationConfig);

      if (data?.id?.length > 0) {
        delete checkData.image;
      }

      if (Object.keys(checkData).length > 0) {
        setPostFormErrors(checkData);
        setPostFormSubmitting(false);
        return;
      }

      try {
        const isEditing = _data.id?.length > 0;

        const formData = new FormData();

        Object.keys(_data).forEach((key) => {
          switch (key) {
            case 'image':
              if (_data.image) {
                formData.append('image', _data.image[0]);
              }
              break;
            case 'tagIds':
              _data.tagIds.map((id) => formData.append('tagIds', id));
              break;
            default:
              formData.append(key, _data[key]);
              break;
          }
        });

        const requestData = { headers: { 'Content-Type': 'multipart/form-data' }, data: formData };

        let response;

        if (isEditing) {
          response = await authPut(`blog/posts/${_data.id}`, requestData);
        } else {
          response = await authPost('blog/posts', requestData);
        }

        const message = isEditing ? 'Post edited successfully' : 'Post added successfully';

        toast.success(message);

        if (response.slug) {
          navigate(`/admin/b/post/${response.slug}`);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setPostFormErrors(null);
        setPostFormSubmitting(false);
      }
    },
    [authPost, authPut, postTagsChecks],
  );

  const handleEditPostButtonClick = useCallback(
    async (slug) => {
      navigate(`/admin/b/post/${slug}`);
    },
    [navigate],
  );

  const handleCancelEditPostButtonClick = useCallback(
    async () => {
      setPostSingle(null);
      navigate('/admin/b/posts');
    },
    [setPostSingle],
  );

  // Handle Delete Post
  const handleOpenDeletePostModal = useCallback(
    async (data) => {
      setPostDelete(data);
    },
    [setPostDelete],
  );

  const handleCloseDeletePostModal = useCallback(
    async () => {
      setPostDelete(null);
    },
    [setPostDelete],
  );

  const handleDeletePostModalSubmit = useCallback(
    async () => {
      console.log(postDelete);
      try {
        await authDelete(`blog/posts/${postDelete.id}`);

        toast.success('Deleted Successfully');
        fetchPostsData();
      } catch (err) {
        console.log(err);
      }

      handleCloseDeletePostModal();
    },
    [authDelete, postDelete],
  );

  // POST CATEGORIES
  const fetchPostCategoriesData = useCallback(
    async () => {
      try {
        setPostCategoriesLoaded(false);
        const response = await authGet('/blog/categories');
        setPostCategories(response.data || []);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Categories');
      }
      setPostCategoriesLoaded(true);
    },
    [authGet, setPostCategories, setPostCategoriesLoaded],
  );

  const handleAddPostCategorySubmit = useCallback(
    async (data) => {
      try {
        if (postCategories?.length > 0) {
          const category = postCategories.find((cat) => cat.title.toLowerCase() === data.title.toLowerCase());

          if (category) {
            toast.error('Category already exists');
            return;
          }
        }

        const isEditing = data?.id?.length > 0;

        if (isEditing) {
          await authPut(`blog/categories/${data?.id}`, { data });
        } else {
          await authPost('blog/categories', { data });
        }

        const successMessage = isEditing ? 'Category Updated Successfully' : 'Category Added Successfully';
        toast.success(successMessage);
        setPostCategory({ id: '', title: '' });
        fetchPostCategoriesData();
      } catch (err) {
        console.log(err);
        toast.error('Error adding Category');
      }
    },
    [authPost],
  );

  const handleOpenPostCategoryDeleteModal = useCallback(
    async (category) => {
      setPostCategoryDelete(category);
    },
    [setPostCategoryDelete],
  );

  const handleClosePostCategoryDeleteModal = useCallback(
    async () => {
      setPostCategoryDelete(null);
    },
    [setPostCategoryDelete],
  );

  const handleSubmitPostCategoryDeleteModal = useCallback(
    async () => {
      try {
        await authDelete(`blog/categories/${postCategoryDelete.id}`);

        toast.success('Category Deleted Successfully');

        fetchPostCategoriesData();
      } catch (error) {
        console.log(error);
        toast.error('Error deleting Category');
      }
      handleClosePostCategoryDeleteModal();
    },
    [authDelete, postCategoryDelete],
  );

  // POST TAGS
  const fetchPostTagsData = useCallback(
    async () => {
      try {
        setPostTagsLoaded(false);
        const response = await authGet('/blog/tags');
        setPostTags(response.data || []);
        setPostTagsLoaded(true);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Tags');
      }
    },
    [authGet, setPostTagsLoaded, setPostTags],
  );

  const handleAddPostTagSubmit = useCallback(
    async (data) => {
      try {
        if (postTags?.length > 0) {
          const existingTag = postTags.find((tag) => tag.title.toLowerCase() === data.title.toLowerCase());

          if (existingTag) {
            toast.error('Tag already exists');
            return;
          }
        }

        const isEditing = data?.id?.length > 0;

        if (isEditing) {
          await authPut(`blog/tags/${data?.id}`, { data });
        } else {
          await authPost('blog/tags', { data });
        }

        const successMessage = isEditing ? 'Tag Updated Successfully' : 'Tag Added Successfully';
        toast.success(successMessage);
        setPostTag({ id: '', title: '' });
        fetchPostTagsData();
      } catch (err) {
        console.log(err);
        toast.error('Error adding Tag');
      }
    },
    [authPost],
  );

  const handleOpenPostTagDeleteModal = useCallback(
    async (tag) => {
      setPostTagDelete(tag);
    },
    [setPostTagDelete],
  );

  const handleClosePostTagDeleteModal = useCallback(
    async () => {
      setPostTagDelete(null);
    },
    [setPostTagDelete],
  );

  const handleSubmitPostTagDeleteModal = useCallback(
    async () => {
      try {
        await authDelete(`blog/tags/${postTagDelete.id}`);

        toast.success('Tag Deleted Successfully');

        fetchPostTagsData();
      } catch (error) {
        console.log(error);
        toast.error('Error deleting Tag');
      }
      handleClosePostTagDeleteModal();
    },
    [authDelete, postTagDelete],
  );

  // COLUMNS CONFIGURATION ============================================================
  const blogPostColumnsConfiguration = useMemo(
    () => defaultBlogPostsColumns(t, handleEditPostButtonClick, handleOpenDeletePostModal),
    [t],
  );
  // COLUMNS CONFIGURATION ============================================================

  return {
    t,
    user,

    postsData,
    postsDataLoaded,
    postDelete,
    postFormSubmitting,
    postFormErrors,
    setPostFormErrors,

    fetchPostsData,
    handleAddEditPostFormSubmit,
    handleDeletePostModalSubmit,
    handleOpenDeletePostModal,
    handleCloseDeletePostModal,
    blogPostColumnsConfiguration,
    fetchPostSingleData,
    postSingle,
    postSingleLoaded,
    handleCancelEditPostButtonClick,

    // Categories
    fetchPostCategoriesData,
    postCategories,
    postCategoriesLoaded,
    openPostCategoriesModal,
    setOpenPostCategoriesModal,

    postCategory,
    setPostCategory,
    handleAddPostCategorySubmit,

    postCategoryDelete,
    handleOpenPostCategoryDeleteModal,
    handleClosePostCategoryDeleteModal,
    handleSubmitPostCategoryDeleteModal,

    // Tags
    fetchPostTagsData,
    postTags,
    postTagsLoaded,
    openPostTagsModal,
    setOpenPostTagsModal,

    // Tags checkboxes
    postTagsChecks,
    postTagsChecksLoaded,
    setPostTagsChecks,
    createPostTagsChecks,

    postTag,
    setPostTag,
    handleAddPostTagSubmit,

    postTagDelete,
    handleOpenPostTagDeleteModal,
    handleClosePostTagDeleteModal,
    handleSubmitPostTagDeleteModal,
  };
};

export default useAdminBlogApi;
