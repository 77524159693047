import { Container, Grid, Typography } from '@mui/material';
import SimulatorSettingsHook from './context/hook';
import Layout from '../../../components/CardLayout';
import Form from '../../../components/Form';
import { simulatorSettingsFormConfig } from './config';
import ComponentLoader from '../../../components/ComponentLoader';
import { useSimulatorSettings } from '../../Simulator/context/hook';

const SimulatorSettingsPage = () => {
  const {
    t,
    updateSimulatorSettings,
    handleSettingsFormSubmit,
  } = SimulatorSettingsHook();

  const simulatorSettings = useSimulatorSettings();

  return (
    <Container className="admin-simulator-settings">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 2,
          }}
        >
          <span className="primary-text-gradient">
            {t('Simulator Settings')}
          </span>
        </Typography>

        <Grid container>
          {simulatorSettings.data ? (
            <Grid item xs={12} md={6}>
              <Form
                size="md"
                defaultFormValue={simulatorSettings.data}
                onSubmit={handleSettingsFormSubmit}
                config={simulatorSettingsFormConfig}
                buttonType="button"
                buttonStyle={{ width: '100%' }}
                submitLabel={t('Save')}
                formLoading={updateSimulatorSettings.isPending}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ComponentLoader />
            </Grid>
          )}
        </Grid>
      </Layout>
    </Container>
  );
};

export default SimulatorSettingsPage;
