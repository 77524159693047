import { Grid } from '@mui/material';
import { renderNumber } from '../../../../utils/string';

const ResultsBox = ({
  title, result, money = false, text = false, isHTML = false,
}) => (
  <Grid item className="results-box">
    <div className="title" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <span>{title}</span>
    </div>
    <div className="result" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      {isHTML ? result : <span>{`${text ? result : `${money ? '$' : ''}${renderNumber(Math.ceil(result))}`}` }</span>}
    </div>
  </Grid>
);

export default ResultsBox;
