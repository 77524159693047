import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { Context } from '../context';
import Layout from '../../../components/CardLayout';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';

export default function LegacyWallet() {
  const {
    t,
  } = useContext(Context);

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 4, padding: { xs: 0, lg: '0 24px' } }}>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: { xs: '21px', lg: '26px' },
          fontWeight: 600,
          mb: 1,
          textAlign: 'center',
        }}
      >
        {t('Legacy Wallet')}
      </Typography>

      <Typography sx={{
        fontSize: '16px', textAlign: { xs: 'center' },
      }}
      >
        <span className="primary-text-gradient">{t('Coming June 2025')}</span>
      </Typography>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginY: 4 }}>
          <TextInput
            className="wallet-input"
            value="0"
            onChange={(e) => {
              // 
            }}
            readOnly
            startAdornment={(
              <span className="flex items-center">
                <Typography sx={{
                  pl: 2, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                }}
                >
                  USD
                </Typography>
              </span>
            )}
            // endAdornment={(
            //  <div className="flex items-center">
            //    <Button
            //    //  onClick={() => setPopupIsOpen(true)}
            //      className="py-auto my-auto mr-3"
            //    //  bordered={(defaultWallet?.balance || 0) < 135}
            //      size="sm"
            //    >
            //      {/* <span className="no-wrap">{withdrawConditons?.canWithdrawThisWeek ? t('Available Withdrawal') : t('Redeem')}</span> */}
            //    </Button>
            //  </div>
            // )}
          />
        
        </Grid>
      </Grid>
    </Layout>
  );
}
