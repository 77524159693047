import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { columns } from './config';

export default function HistoryTable({ rows }) {
  return (
    <Grid container padding={0}>

      <Grid container>
        <Table
          columns={columns()}
          rows={rows}
          className="table"
          hideFooterPagination
          hideFooter
          getRowId={(row) => row.id}
        />
        {/* {paginationData && (
          <div className="flex justify-end mt-4">
            <Typography sx={{ mt: 0.5 }}>
              {`Total of: ${paginationData.totalItems} votes`}
            </Typography>
            <Pagination
              count={paginationData?.total}
              page={paginationData?.page}
              onChange={(val, page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )} */}
      </Grid>
    </Grid>
       
  );
}
