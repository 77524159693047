import dayjs from 'dayjs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Chip, Grid } from '@mui/material';
import Button from '../../../../components/Button';

const PostSingleCard = ({ post }) => {
  const navigate = useNavigate();
  const placeholder = '/assets/MemeGames-logo-sol.png';
  const hasImage = post.imageUrl;
  if (!hasImage) {
    post.imageUrl = placeholder;
  }
  return (
    <Card
      sx={{
        width: '100%', display: 'flex', flexDirection: 'column', position: 'relative',
      }}
    >
      <CardMedia
        sx={{ height: { xs: 150, md: 200 }, backgroundSize: hasImage ? 'cover' : '40% auto' }}
        image={post.imageUrl}
        title={post.title}

      >
        <Grid sx={{ pl: 2, pt: 2 }}>
          <Chip
            label={post.category}
            variant="outlined"
            onClick={() => { console.log(post.category); }}
          />
        </Grid>
      </CardMedia>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="body2" sx={{ color: 'primary' }}>
          {dayjs(post.createdAt).tz('GMT').format('MMM DD, YYYY')}
        </Typography>
        <Typography variant="h5">
          {post.title.length > 35 ? `${post.title.substring(0, 35)}...` : post.title}
        </Typography>
      </CardContent>
      <CardActions sx={{ pl: 2 }}>
        <Button onClick={() => navigate(`/blog/post/${post.slug}`)} size="sm" bordered>
          <span style={{ paddingLeft: 5, paddingRight: 5 }}>
            Learn More
          </span>
        </Button>
      </CardActions>
      <Grid sx={{ p: 2, pb: 3 }} display="flex" gap={1}>
        {post.tags.map((tag) => <Chip label={tag.title} key={tag.title} variant="outlined" size="small" />)}
      </Grid>
    </Card>
  );
};

export default PostSingleCard;
