import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import useApi from '../../../../utils/api';

export const PAGE_SIZE = 10;
export const useSupportUser = (id) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['support-user-data', id],
    queryFn: async () => {
      const response = await authGet(`users/support/${id}`);
      return response;
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 0,
  });
};

export const useHistory = (isGamer, {
  userId, perPage = PAGE_SIZE, page = 1, direction = null, type = null,
}) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['transactions-history', page, userId, isGamer, direction, type, perPage],
    queryFn: async () => {
      const response = authGet(isGamer ? '/gamer-wallet/history' : '/wallets/history', {
        params: {
          page,
          perPage,
          type,
          direction,
          userId,
        },
      });
      return response;
    },
    enabled: !!userId && page !== undefined,
    staleTime: 1000 * 60 * 5,
  });
};

export const useMyCoinPicks = ({
  userId, perPage = PAGE_SIZE, page = 1, startDate = null, order = 'desc',
}) => {
  const { authGet } = useApi();
  const sortModel = [
    {
      field: 'lastVotedAt',
      sort: order,
    },
  ];
  const sortField = `${sortModel ? sortModel[0]?.field : 'lastVotedAt'}`;
  const dir = sortModel[0]?.sort === 'asc' ? '1' : '-1';
  return useQuery({
    queryKey: ['transactions-history', page, userId, sortField, dir, startDate],
    queryFn: async () => {
      const response = await authGet('/schedule-game/my-picks', {
        params: {
          page,
          perPage,
          userId,
          orderBy: { [sortField]: dir },
          ...(startDate && { startDate: dayjs(startDate).tz('GMT').startOf('day').toISOString(), endDate: dayjs(startDate).tz('GMT').endOf('day').toISOString() }),
        },
      });
      return response;
    },
    enabled: !!userId && page !== undefined,
    staleTime: 1000 * 60 * 5,
  });
};

export const useTransfers = ({
  page, userId, perPage, ...filters
}) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['transfers-history', page, userId, filters, perPage],
    queryFn: async () => {
      const response = await authGet('/passes/transfer-history', {
        params: {
          perPage,
          page: page || 1,
          ...filters,
          userId,
        },
      });
      return response;
    },
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
  });
};

export const usePurchases = ({
  page, userId, perPage, status,
}) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['purchases-history', page, userId, status, perPage],
    queryFn: async () => {
      const response = await authGet('/payments', {
        params: {
          perPage,
          page: page || 1,
          ...(status !== 'all' && { status }),
          userId,
        },
      });
      return response;
    },
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
  });
};

export const groupGamesByDate = (games) => {
  const groupedData = games.reduce((acc, game) => {
    const date = dayjs(game.endDate).tz('GMT').format('MMM DD, YYYY').toString();
    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(game);

    return acc;
  }, []);

  return groupedData;
};

export const useGameHistory = ({
  userId, page = 1, game = undefined, price = 0, days = 0,
}) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['game-history', page, PAGE_SIZE, game, price, days, userId],
    queryFn: async () => {
      const response = await authGet('/schedule-game/history', {
        params: {
          page, perPage: PAGE_SIZE, ...(game !== 'default' && { game }), ...(price !== 'default' && { price }), ...(days !== 'default' && { days }), userId,
        },
      });
      return response;
    },
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
  });
};

export const useGamerTokens = (id) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['gamer-tokens,', id],
    queryFn: async () => {
      const response = await authGet('/gamer-wallet', { userId: id });
      return response;
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  });
};

export const useGetPassess = ({
  id, page, passTypes, orderBy, used, passTypeId, perPage, paid,
}) => {
  const { authGet } = useApi();
  return useQuery({
    queryKey: ['get-pasess,', id, page, passTypes, orderBy, used, passTypeId, perPage, paid],
    queryFn: async () => {
      const response = await authGet('/passes', {
        params: {
          perPage: perPage || PAGE_SIZE,
          userId: id,
          page,
          passTypes,
          orderBy,
          used,
          paid,
          passTypeId,
        },
      });
      return response;
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  });
};
